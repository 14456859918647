define('dashboard/routes/application', ['exports', 'ember', 'dashboard/auth/error'], function (exports, Ember, UnauthorizedError) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend({
		auth: Ember['default'].inject.service(),
		beforeModel: function beforeModel() {
			var auth = this.get('auth');

			if (!auth.get('isAuthenticated')) {
				return auth.authenticate()['catch'](function (error) {
					Ember['default'].Logger.warn('Could not authenticate User', error.stack);
				});
			}
		},
		actions: {
			error: function error(_error) {
				var title = arguments.length <= 1 || arguments[1] === undefined ? '' : arguments[1];

				Ember['default'].Logger.warn(_error);

				if (_error instanceof UnauthorizedError['default']) {
					return this.transitionTo('login');
				}

				if (Ember['default'].typeOf(_error) === 'string' && !Ember['default'].isBlank(_error)) {
					return this.send('notify', 'error', {
						html: '<h3>' + title + '</h3><p>' + _error + '</p>'
					});
				}

				return true;
			},

			notify: function notify(type, options) {
				return this.notify.show(type, options);
			},

			logout: function logout() {
				var auth = this.get('auth');

				auth.logout();
				this.transitionTo('login');
			},

			openModal: function openModal(resource, options) {
				var opts = Ember['default'].merge({
					into: 'application',
					outlet: 'modal'
				}, options);

				Ember['default'].Logger.debug('Opening Modal for resource `%s`.', resource, opts);

				this.render(resource, opts);
			},

			closeModal: function closeModal() {
				Ember['default'].Logger.debug('Disconnecting Modal outlet.');

				this.disconnectOutlet({
					outlet: 'modal',
					parentView: 'application'
				});
			}
		}
	});

});
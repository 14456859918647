define('dashboard/serializers/application', ['exports', 'ember', 'ember-data'], function (exports, Ember, DS) {

	'use strict';

	exports['default'] = DS['default'].JSONSerializer.extend(DS['default'].EmbeddedRecordsMixin, {
		attrs: {
			client: {
				embedded: 'always'
			},
			carrier: {
				embedded: 'always'
			},
			template: {
				embedded: 'always'
			},
			query: {
				embedded: 'always'
			},
			job: {
				embedded: 'always'
			},
			parameters: {
				embedded: 'always'
			}
		},

		/**
	  * Extract and update the Metadata for the Model
	  *
	  * @param  {DS.Store} store
	  * @param  {Model} modelClass
	  * @param  {Object} payload
	  * @return {Object}
	  */
		extractMeta: function extractMeta(store, modelClass, payload) {
			if (payload && payload.hasOwnProperty('meta')) {
				var meta = payload.meta;
				delete payload.meta;

				Ember['default'].run(this, '_updateMetadata', store, modelClass, meta);

				return meta;
			}
		},

		/**
	  * Update the Metadata for the Model
	  *
	  * @param  {DS.Store} store
	  * @param  {Model} modelClass
	  * @param  {Object} metadata
	  * @private
	  */
		_updateMetadata: function _updateMetadata(store, modelClass, metadata) {
			store._setMetadataFor(modelClass.modelName, metadata);
		},

		_normalizeResponse: function _normalizeResponse(store, primaryModelClass, payload, id, requestType, isSingle) {
			// Extract meta manually
			Ember['default'].run(this, 'extractMeta', store, primaryModelClass, payload);

			return this._super(store, primaryModelClass, payload.content, id, requestType, isSingle);
		},

		serializeIntoHash: function serializeIntoHash(hash, typeClass, snapshot, options) {
			Ember['default'].merge(hash, this.serialize(snapshot, options));
		},

		pushPayload: function pushPayload(store, payload) {
			var modelName = this.modelNameFromPayloadKey(payload.type),
			    typeClass = store.modelFor(modelName);

			return this.normalizeArrayResponse(store, typeClass, payload, null, null, false);
		},

		extractAttributes: function extractAttributes(modelClass, resourceHash) {
			var attributeKey;
			var attributes = {};

			modelClass.eachAttribute(function (key, meta) {
				attributeKey = this.keyForAttribute(key, 'deserialize', meta);
				if (resourceHash.hasOwnProperty(attributeKey)) {
					attributes[key] = resourceHash[attributeKey];
				}
			}, this);

			return attributes;
		},

		extractErrors: function extractErrors(store, typeClass, payload) {
			var _this = this;

			var errors = {};

			if (payload && Ember['default'].isArray(payload.errors)) {

				payload.errors.forEach(function (error) {
					typeClass.eachAttribute(function (name) {
						var key = _this.keyForAttribute(name, 'deserialize');

						if (error.hasOwnProperty(key)) {
							errors[name] = error[key];
						}
					});

					typeClass.eachRelationship(function (name) {
						var key = _this.keyForRelationship(name, 'deserialize');

						if (error.hasOwnProperty(key)) {
							errors[name] = error[key];
						}
					});
				});
			}

			return errors;
		},

		serializeAttribute: function serializeAttribute(snapshot, json, key, attribute) {
			var type = attribute.type;

			if (this._canSerialize(key)) {
				var value = snapshot.attr(key);
				if (type) {
					var transform = this.transformFor(type);
					value = transform.serialize(value);
				}

				var payloadKey = this._getMappedKey(key);

				if (payloadKey === key && this.keyForAttribute) {
					payloadKey = this.keyForAttribute(key, 'serialize', attribute);
				}

				json[payloadKey] = value;
			}
		},

		keyForAttribute: function keyForAttribute(key, method, meta) {
			return meta && meta.options && meta.options.key || key;
		}
	});

});
define('dashboard/controllers/invoices/index', ['exports', 'ember', 'dashboard/config/environment', 'dashboard/utils'], function (exports, Ember, ENV, utils) {

  'use strict';

  function formatUrl(action, id) {
    return ENV['default'].HOST + '/api/invoicing/' + action + '/' + id;
  }

  exports['default'] = Ember['default'].Controller.extend({
    auth: Ember['default'].inject.service(),
    token: Ember['default'].computed.oneWay('auth.sessionToken'),
    queryParams: ['status'],
    status: 'PENDING',

    generate: function generate(model) {
      var _this = this;

      var seed = Ember['default'].get(this, 'seed');

      this.set('generating', true);

      utils.ajax({
        url: ENV['default'].HOST + '/api/invoices/serial-number',
        type: 'POST',
        data: {
          seed: seed
        },
        headers: {
          'Authorization': 'Bearer ' + this.get('token')
        }
      }).then(function (response) {
        var number = Ember['default'].get(response, 'content.serialNumber');
        if (Ember['default'].isBlank(number)) {
          throw new Ember['default'].Error('There was an error while generating your serial number, please try again or contact support.');
        }
        Ember['default'].set(model, 'nextInvoiceId', number);
      })['catch'](function (error) {
        _this.send('error', error.message, 'Could not generate a serial number');
      })['finally'](function () {
        return _this.set('generating', false);
      });
    },

    actions: {
      preview: function preview(model) {
        var id = Ember['default'].get(model, 'id');
        window.open(formatUrl('preview', id));
      },

      send: function send(model) {
        this.send('openModal', 'modal/invoice', {
          model: model
        });
      },

      saveInvoice: function saveInvoice(model) {
        if (Ember['default'].get(model, 'hasDirtyAttributes') && Ember['default'].get(model, 'isValid')) {
          this.send('save', model);
        }
      },

      skip: function skip(model) {
        if (!Ember['default'].get(model, 'isPending')) {
          return this.send('error', 'You can only skip pending invoices.', 'Can not skip Invoice');
        }

        Ember['default'].set(model, 'status', 'SKIPPED');
        this.send('save', model, 'Invoice has been skipped');
      },

      unskip: function unskip(model) {
        if (!Ember['default'].get(model, 'isSkipped')) {
          return this.send('error', 'You can only revert skipped invoices.', 'Can not revert Invoice');
        }

        Ember['default'].set(model, 'status', 'PENDING');
        this.send('save', model, 'Invoice has been revereted');
      },

      update: function update(model, key, value) {
        Ember['default'].set(model, key, value);
      },

      getNextInvoiceNumber: function getNextInvoiceNumber(model) {
        //Ember.set(model, 'nextInvoiceId', '12123');
        this.generate(model);
        this.send('openModal', 'modal/invoice-number-generate', {
          model: model
        });
      }
    }
  });

});
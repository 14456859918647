define('dashboard/tests/routes/job.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/job.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(true, 'routes/job.js should pass jshint.');
  });

});
define('dashboard/transforms/date', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	exports['default'] = DS['default'].DateTransform.reopen({
		serialize: function serialize(deserialized) {
			if (typeof deserialized === 'string') {
				deserialized = new Date(deserialized);
			}

			if (typeof deserialized === 'number') {
				deserialized = new Date(deserialized);
			}

			return this._super(deserialized);
		}
	});

});
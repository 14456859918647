define('dashboard/routes/job/edit', ['exports', 'ember', 'dashboard/mixins/jobs/form-route'], function (exports, Ember, JobFormRoute) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(JobFormRoute['default'], {
		model: function model(params) {
			return this.store.findRecord('job', params.id);
		}
	});

});
define('dashboard/initializers/codemirror', ['exports', 'codemirror', 'ivy-codemirror/components/ivy-codemirror'], function (exports, CodeMirror, CodeMirrorComponent) {

	'use strict';

	exports.initialize = initialize;

	var keywords = ("break case catch continue do else false finally for Severity logger emitter " + "if in instanceof new null return switch throw true try typeof while with").split(" ");

	var methods = {
		emitter: ["emit"],
		logger: ["trace", "debug", "info", "warn", "error"],
		Severity: ["INFO", "WARNING", "DANGER", "CRITICAL"]
	};

	function getCompletions(token, context) {
		var found = [];

		var base = undefined;

		function add(name) {
			if (name.lastIndexOf(token.string, 0) === 0 && found.indexOf(name) < 0) {
				found.push(name);
			}
		}

		if (context.length) {
			var obj = context.pop();

			if (obj.type && obj.type.indexOf("variable") === 0) {
				base = methods[obj.string];
			} else if (obj.type === "string") {
				base = "";
			} else if (obj.type === "atom") {
				base = 1;
			}

			while (base != null && context.length) {
				base = base[context.pop().string];
			}
		} else {
			base = keywords;
		}

		if (base instanceof Array) {
			base.forEach(add);
		} else if (base != null) {
			for (var name in base) {
				add(name);
			}
		}

		return found;
	}

	CodeMirror['default'].registerHelper('hint', 'javascript', function (editor, options) {
		var cursor = editor.getCursor();
		var token = editor.getTokenAt(cursor);

		if (/\b(?:string|comment)\b/.test(token.type)) {
			return;
		}

		if (!/^[\w$_]*$/.test(token.string)) {
			token = {
				start: cursor.ch,
				end: cursor.ch,
				string: "",
				state: token.state,
				type: token.string === "." ? "property" : null
			};
		} else {
			token.end = cursor.ch;
			token.string = token.string.slice(0, cursor.ch - token.start);
		}

		var context = [];

		var property = token;

		while (property.type === "property") {
			property = editor.getTokenAt(CodeMirror['default'].Pos(cursor.line, property.start));

			if (property.string !== ".") {
				return;
			}

			property = editor.getTokenAt(CodeMirror['default'].Pos(cursor.line, property.start));

			context.push(property);
		}

		return {
			list: getCompletions(token, context, options),
			from: CodeMirror['default'].Pos(cursor.line, token.start),
			to: CodeMirror['default'].Pos(cursor.line, token.end)
		};
	});

	function initialize() {
		CodeMirrorComponent['default'].reopen({
			mode: 'javascript',
			theme: 'eclipse',
			lineNumbers: true,
			extraKeys: {
				"Ctrl-Space": "autocomplete"
			}
		});
	}

	exports['default'] = {
		name: 'codemirror',
		initialize: initialize
	};

});
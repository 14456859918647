define('dashboard/controllers/index', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	/* global moment:true */
	exports['default'] = Ember['default'].Controller.extend({
		client: null,
		carrier: null,
		dialcode: null,
		destination: null,
		from: moment().subtract(1, 'days').toDate().getTime(),
		to: moment().toDate().getTime(),

		carrierDidChange: Ember['default'].observer('carrier', function () {
			this.set('carrierOut', this.get('carrier.id') || null);
		}),

		clientDidChange: Ember['default'].observer('client', function () {
			this.set('carrierIn', this.get('client.id') || null);
		}),
		auth: Ember['default'].inject.service()

	});

});
define('dashboard/routes/index', ['exports', 'ember', 'ember-data', 'moment', 'dashboard/mixins/authenticated-route', 'dashboard/utils'], function (exports, Ember, DS, moment, AuthenticatedRoute, utils) {

	'use strict';

	function find(params, token, path) {
		var promise = utils.ajax({
			url: 'api/cdrs/graph/' + path,
			headers: {
				'Authorization': 'Bearer ' + token
			},
			data: params
		});

		return DS['default'].PromiseArray.create({
			promise: promise
		});
	}

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRoute['default'], {
		queryParams: {
			carrierIn: {
				refreshModel: false,
				replace: false
			},
			carrierOut: {
				refreshModel: false,
				replace: false
			},
			destination: {
				refreshModel: false,
				replace: false
			},
			dialcode: {
				refreshModel: false,
				replace: false
			},
			from: {
				refreshModel: false,
				replace: false
			},
			to: {
				refreshModel: false,
				replace: false
			}
		},

		/**
	  * Authenitcation Service
	  *
	  * @type {AuthService}
	  */
		auth: Ember['default'].inject.service(),

		model: function model(params) {
			var token = this.get('auth.sessionToken');

			return Ember['default'].Object.create({
				calls: find(params, token, 'calls'),
				acd: find(params, token, 'acd'),
				asr: find(params, token, 'asr'),
				pdd: find(params, token, 'pdd') //,
				//client: find(params, token, 'clients')
			});
		},

		parseQuery: function parseQuery(params) {
			var queries = this.get('controller.queries') || [];

			queries.forEach(function (query) {
				query.serialize(params);
			});

			// Spring pagination is Zero based
			if (params.page > 0) {
				params.page = params.page - 1;
			}

			// Save the lastQuery
			this.lastQuery = params;
		},

		updateDashboard: function updateDashboard() {
			var params = this.paramsFor('index');
			var from = moment['default'](params.from);
			var to = moment['default'](params.to);
			var duration = moment['default'].duration(to.diff(from));

			if (Math.floor(duration.asMinutes()) === 30) {
				this.set('controller.from', moment['default']().subtract(30, 'm'));
				this.set('controller.to', moment['default']());
				this.refresh();
			}

			Ember['default'].run(this, 'setUpdateTimeout');
		},

		setUpdateTimeout: function setUpdateTimeout() {
			Ember['default'].run.cancel(this._timeout);

			var duration = moment['default'].duration(5, 'minutes').asMilliseconds();
			this._timeout = Ember['default'].run.later(this, 'updateDashboard', duration);
		},

		actions: {
			search: function search() {
				this.refresh();
			},

			didTransition: function didTransition() {
				Ember['default'].run(this, 'setUpdateTimeout');
			},

			willTransition: function willTransition() {
				Ember['default'].run.cancel(this._timeout);
			}
		}
	});

});
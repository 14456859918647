define('dashboard/components/file-input', ['exports', 'ember', 'dashboard/utils/uploadable'], function (exports, Ember, Uploadable) {

	'use strict';

	exports['default'] = Ember['default'].Component.extend({
		classNames: ['file-input'],
		multiple: false,

		label: 'Select file',

		didInsertElement: function didInsertElement() {
			var input = this.$('input[type="file"]');

			this.$('button').on('click', function () {
				input.click();

				return false;
			});

			input.on('change', (function (e) {
				var input = e.target;
				var uploadables = Uploadable['default'].fromFileList(input.files);

				if (Ember['default'].isEmpty(uploadables)) {
					return;
				}

				if (this.get('multiple')) {
					this.sendAction('files', uploadables);
				} else {
					this.sendAction('file', uploadables[0]);
				}
			}).bind(this));
		}
	});

});
define('dashboard/components/report-summary', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Component.extend({
		reports: null,
		meta: null,
		auth: Ember['default'].inject.service(),

		/**
	  * Report Summary extracted from the Metadata
	  * 
	  * @type {Object}
	  */
		summary: Ember['default'].computed.alias('meta.summary'),

		carrierCosts: Ember['default'].computed.alias('meta.summary.carrierCosts'),
		minutes: Ember['default'].computed.alias('meta.summary.minutes'),
		/**
	  * Is there a Margin loss in the Summary
	  * 
	  * @type {Boolean}
	  */
		marginLoss: Ember['default'].computed.lt('summary.margin', 0),

		/**
	  * Get the total Number of all unrated CDR's
	  *
	  * @type {Number}
	  */
		unrated: Ember['default'].computed.alias('meta.unratedCount'),

		costPerMin: (function () {
			return parseFloat(parseFloat(this.get('carrierCosts')) / parseFloat(this.get('minutes'))).toFixed(4);
		}).property('carrierCosts', 'minutes'),

		/**
	  * Check if there are any unrated CDR records
	  *
	  * @type {Boolean}
	  * @readOnly
	  */
		hasUnratedRecords: Ember['default'].computed('unrated', {
			get: function get() {
				var unrated = this.get('unrated');
				return Ember['default'].isPresent(unrated) && unrated > 0;
			}
		}),

		actions: {
			'export': function _export() {
				this.sendAction('export');
			},

			details: function details(destination) {
				this.sendAction('details', destination);
			},
			ner_details: function ner_details(model, carrier) {
				this.sendAction('ner_details', model, carrier);
			}
		}
	});

});
define('dashboard/tests/routes/reports/carrier.jshint', function () {

  'use strict';

  QUnit.module('JSHint - routes/reports/carrier.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(true, 'routes/reports/carrier.js should pass jshint.');
  });

});
define('dashboard/models/query-parameter', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	exports['default'] = DS['default'].Model.extend({
		key: DS['default'].attr('string', {
			validation: {
				required: true
			}
		}),

		value: DS['default'].attr('string', {
			validation: {
				required: true
			}
		}),

		type: DS['default'].attr('enum', {
			validation: {
				required: true
			}
		}),

		createdAt: DS['default'].attr('date'),

		updatedAt: DS['default'].attr('date')

	});
	// job: DS.belongsTo('job')

});
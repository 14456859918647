define('dashboard/tests/initializers/codemirror.jshint', function () {

  'use strict';

  QUnit.module('JSHint - initializers/codemirror.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(true, 'initializers/codemirror.js should pass jshint.');
  });

});
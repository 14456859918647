define('dashboard/routes/invoices/index', ['exports', 'ember', 'dashboard/mixins/form-route', 'dashboard/mixins/authenticated-route'], function (exports, Ember, FormRouteMixin, AuthenticatedRoute) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(FormRouteMixin['default'], AuthenticatedRoute['default'], {
    queryParams: {
      status: {
        refreshModel: true
      }
    },
    model: function model(params) {
      return this.store.query('invoice', params);
    }
  });

});
define('dashboard/mixins/jobs/form-route', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	function destroyIfNew(model) {
		if (model && Ember['default'].get(model, 'isNew')) {
			model.destroyRecord();
		}
	}

	/**
	 * Mixins used on the `edit` and `create` Routes for
	 * `Job` resource.
	 *
	 * @mixin
	 * @class JobFormRouteMixin
	 */
	exports['default'] = Ember['default'].Mixin.create({
		/**
	  * Set the controller name
	  */
		controllerName: 'job/form',
		/**
	  * Set the template name
	  */
		templateName: 'job/form',

		actions: {
			'add-parameter': function addParameter() {
				var model = this.modelFor(this.routeName);
				var parameter = this.store.createRecord('query-parameter', {
					job: model
				});

				model.get('parameters').addObject(parameter);
			},

			'remove-parameter': function removeParameter(parameter) {
				var model = this.modelFor(this.routeName);
				model.get('parameters').removeObject(parameter);
			},

			/**
	   * Destroy the Record if it is new, when the user
	   * is about the leave the route.
	   */
			willTransition: function willTransition() {
				var model = this.get('controller.model');

				destroyIfNew(model);

				// Clear the new parameters as those records would be
				// included in the Job record when they are saved.
				model.get('parameters').forEach(function (parameter) {
					destroyIfNew(parameter);
				});
			}
		}
	});

});
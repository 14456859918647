define('dashboard/routes/reports/cbc-latest', ['exports', 'ember', 'ember-data', 'dashboard/mixins/reports/route', 'dashboard/utils'], function (exports, Ember, DS, ReportsRoute, utils) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(ReportsRoute['default'], {
		auth: Ember['default'].inject.service(),

		modelKey: 'cbc-report',

		model: function model(params) {
			var modelKey = this.get('modelKey');

			Ember['default'].assert('You must set the `modelKey` property on this route.', Ember['default'].typeOf(modelKey) === 'string');

			if (Ember['default'].isPresent(params.client)) {
				this.lastQuery = params;
				return this.fetchLatestReports(params.client);
			} else {
				return Ember['default'].A();
			}
		},

		fetchLatestReports: function fetchLatestReports(id) {
			var token = this.get('auth.sessionToken');
			var url = utils.buildUrl('api', 'reports', 'cbc', 'latest');

			var promise = utils.ajax({
				url: url, data: {
					client: id
				},
				beforeSend: function beforeSend(request) {
					request.setRequestHeader("Authorization", 'Bearer ' + token);
				}
			});

			return DS['default'].PromiseArray.create({
				promise: promise
			});
		}
	});

});
define('dashboard/components/cdr-table', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	var Query = Ember['default'].Object.extend({
		key: null,
		operator: '',
		value: null,

		serialize: function serialize(query) {
			var key = this.get('key'),
			    operator = this.get('operator'),
			    value = this.get('value');

			if (Ember['default'].isEmpty(key) || Ember['default'].isEmpty(value)) {
				return;
			}

			query[key] = operator + value;
		}
	});

	exports['default'] = Ember['default'].Component.extend({
		classNames: ['ui column'],
		store: Ember['default'].inject.service(),

		queries: Ember['default'].A(),
		selectedField: Ember['default'].A(),

		queryOperators: Ember['default'].A([{
			label: 'Equals',
			value: ''
		}, {
			label: 'Not equals',
			value: '!='
		}, {
			label: 'Like',
			value: '%'
		}, {
			label: 'Greater than eq',
			value: '>='
		}, {
			label: 'Lesser than eq',
			value: '<='
		}]),

		selected: Ember['default'].A(['date', 'callerid', 'calledid', 'carrierIn', 'carrierOut', 'sipCode', 'duration', 'pdd', 'ringing', 'masterDestination', 'purchasePrice', 'sellingPrice']),

		didInsertElement: function didInsertElement() {
			Ember['default'].run.scheduleOnce('afterRender', this, 'selectedDidChange');
		},

		selectedDidChange: Ember['default'].observer('selected.[]', function () {
			var _this = this;

			this.$(' th[data-name], td[data-name] ').css('display', 'none');

			this.get('selected').forEach(function (selected) {
				_this.$('th[data-name="' + selected + '"] , td[data-name="' + selected + '"]').css('display', 'table-cell');
			});

			if (Ember['default'].$.fn.chromatable) {
				this.$('.freezeheader').chromatable('resize');
			}
		}),

		actions: {
			add: function add() {
				this.get('queries').addObject(Query.create());
			},

			clear: function clear() {
				this.get('queries').clear();
			},

			remove: function remove(query) {
				this.get('queries').removeObject(query);
			}
		}
	});

});
define('dashboard/controllers/templates/form', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend({
		// /**
		//  * Get the List of avaliable Currencies
		//  */
		// currencies: Ember.A(['EUR', 'USD']),

		/**
	  * Title of the Form modal
	  */
		title: Ember['default'].computed('model.isNew', {
			get: function get() {
				var model = this.get('model'),
				    isNew = this.get('model.isNew'),
				    message = '';

				if (isNew) {
					message = 'Create new ';
				} else {
					message = 'Edit ';
				}

				return message + model.get('className');
			}
		})
	});

});
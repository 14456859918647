define('dashboard/templates/application', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@2.0.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 9,
                  "column": 6
                },
                "end": {
                  "line": 9,
                  "column": 71
                }
              },
              "moduleName": "dashboard/templates/application.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("Turkcell Client reports");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@2.0.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 14,
                  "column": 5
                },
                "end": {
                  "line": 19,
                  "column": 5
                }
              },
              "moduleName": "dashboard/templates/application.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("						");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"href","#");
              dom.setAttribute(el1,"class","ui item");
              var el2 = dom.createTextNode("\n							");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("i");
              dom.setAttribute(el2,"class","power icon");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n								");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n						");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element9 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element9);
              morphs[1] = dom.createMorphAt(element9,3,3);
              return morphs;
            },
            statements: [
              ["element","action",["logout"],[],["loc",[null,[15,34],[15,53]]]],
              ["content","auth.session.username",["loc",[null,[17,8],[17,33]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@2.0.2",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 1
              },
              "end": {
                "line": 23,
                "column": 1
              }
            },
            "moduleName": "dashboard/templates/application.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("		");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("nav");
            dom.setAttribute(el1,"class","ui stackable secondary pointing menu");
            dom.setAttribute(el1,"role","navigation");
            var el2 = dom.createTextNode("\n			");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","ui container");
            var el3 = dom.createTextNode("\n				\n				");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","ui simple dropdown item");
            var el4 = dom.createTextNode("\n					Reports ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("i");
            dom.setAttribute(el4,"class","dropdown icon");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n					");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","menu");
            var el5 = dom.createTextNode("\n						");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n					");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n				");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n				");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","right menu labeled icon");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("				");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n			");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n		");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element10 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(element10, [1, 3]),1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(element10, [3]),1,1);
            return morphs;
          },
          statements: [
            ["block","link-to",["reports.client"],["class","item"],0,null,["loc",[null,[9,6],[9,83]]]],
            ["block","ui-popup",[],["content","Logout","transition","fly down","position","right center"],1,null,["loc",[null,[14,5],[19,18]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 30,
                    "column": 6
                  },
                  "end": {
                    "line": 30,
                    "column": 70
                  }
                },
                "moduleName": "dashboard/templates/application.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Latest CBC reports");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 31,
                    "column": 6
                  },
                  "end": {
                    "line": 31,
                    "column": 56
                  }
                },
                "moduleName": "dashboard/templates/application.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("CBC reports");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 36,
                    "column": 5
                  },
                  "end": {
                    "line": 41,
                    "column": 5
                  }
                },
                "moduleName": "dashboard/templates/application.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("						");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"href","#");
                dom.setAttribute(el1,"class","ui item");
                var el2 = dom.createTextNode("\n							");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("i");
                dom.setAttribute(el2,"class","power icon");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n								");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n						");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element6 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element6);
                morphs[1] = dom.createMorphAt(element6,3,3);
                return morphs;
              },
              statements: [
                ["element","action",["logout"],[],["loc",[null,[37,34],[37,53]]]],
                ["content","auth.session.username",["loc",[null,[39,8],[39,33]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@2.0.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 23,
                  "column": 1
                },
                "end": {
                  "line": 45,
                  "column": 1
                }
              },
              "moduleName": "dashboard/templates/application.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("		");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("nav");
              dom.setAttribute(el1,"class","ui stackable secondary pointing menu");
              dom.setAttribute(el1,"role","navigation");
              var el2 = dom.createTextNode("\n			");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","ui container");
              var el3 = dom.createTextNode("\n				\n				");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","ui simple dropdown item");
              var el4 = dom.createTextNode("\n					Reports ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("i");
              dom.setAttribute(el4,"class","dropdown icon");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n					");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","menu");
              var el5 = dom.createTextNode("\n						");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n						");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n					");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n				");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n\n				");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","right menu labeled icon");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("				");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n			");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n		");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element7 = dom.childAt(fragment, [1, 1]);
              var element8 = dom.childAt(element7, [1, 3]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(element8,1,1);
              morphs[1] = dom.createMorphAt(element8,3,3);
              morphs[2] = dom.createMorphAt(dom.childAt(element7, [3]),1,1);
              return morphs;
            },
            statements: [
              ["block","link-to",["reports.cbc-latest"],["class","item"],0,null,["loc",[null,[30,6],[30,82]]]],
              ["block","link-to",["reports.cbc"],["class","item"],1,null,["loc",[null,[31,6],[31,68]]]],
              ["block","ui-popup",[],["content","Logout","transition","fly down","position","right center"],2,null,["loc",[null,[36,5],[41,18]]]]
            ],
            locals: [],
            templates: [child0, child1, child2]
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 51,
                    "column": 6
                  },
                  "end": {
                    "line": 51,
                    "column": 55
                  }
                },
                "moduleName": "dashboard/templates/application.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Dashboard");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 52,
                    "column": 6
                  },
                  "end": {
                    "line": 52,
                    "column": 59
                  }
                },
                "moduleName": "dashboard/templates/application.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Clients Graph");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 56,
                    "column": 4
                  },
                  "end": {
                    "line": 56,
                    "column": 46
                  }
                },
                "moduleName": "dashboard/templates/application.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Clients");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child3 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 57,
                    "column": 4
                  },
                  "end": {
                    "line": 57,
                    "column": 48
                  }
                },
                "moduleName": "dashboard/templates/application.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Carriers");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child4 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 62,
                    "column": 6
                  },
                  "end": {
                    "line": 62,
                    "column": 64
                  }
                },
                "moduleName": "dashboard/templates/application.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Purchase prices");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child5 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 63,
                    "column": 6
                  },
                  "end": {
                    "line": 63,
                    "column": 62
                  }
                },
                "moduleName": "dashboard/templates/application.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Selling prices");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child6 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 64,
                    "column": 6
                  },
                  "end": {
                    "line": 64,
                    "column": 40
                  }
                },
                "moduleName": "dashboard/templates/application.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("CBC");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child7 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 65,
                    "column": 6
                  },
                  "end": {
                    "line": 65,
                    "column": 78
                  }
                },
                "moduleName": "dashboard/templates/application.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("CBC Price Overview");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child8 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 69,
                    "column": 4
                  },
                  "end": {
                    "line": 69,
                    "column": 38
                  }
                },
                "moduleName": "dashboard/templates/application.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("CDR");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child9 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 74,
                    "column": 6
                  },
                  "end": {
                    "line": 74,
                    "column": 62
                  }
                },
                "moduleName": "dashboard/templates/application.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Client Reports");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child10 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 75,
                    "column": 6
                  },
                  "end": {
                    "line": 75,
                    "column": 81
                  }
                },
                "moduleName": "dashboard/templates/application.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Clients Overview Report");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child11 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 76,
                    "column": 6
                  },
                  "end": {
                    "line": 76,
                    "column": 64
                  }
                },
                "moduleName": "dashboard/templates/application.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Carrier Reports");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child12 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 77,
                    "column": 6
                  },
                  "end": {
                    "line": 77,
                    "column": 81
                  }
                },
                "moduleName": "dashboard/templates/application.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Carrier Overview Report");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child13 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 78,
                    "column": 6
                  },
                  "end": {
                    "line": 78,
                    "column": 56
                  }
                },
                "moduleName": "dashboard/templates/application.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("CBC reports");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child14 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 79,
                    "column": 6
                  },
                  "end": {
                    "line": 79,
                    "column": 70
                  }
                },
                "moduleName": "dashboard/templates/application.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Latest CBC reports");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child15 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 80,
                    "column": 6
                  },
                  "end": {
                    "line": 80,
                    "column": 69
                  }
                },
                "moduleName": "dashboard/templates/application.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("CBC overview");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child16 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 84,
                    "column": 4
                  },
                  "end": {
                    "line": 84,
                    "column": 49
                  }
                },
                "moduleName": "dashboard/templates/application.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Invoicing");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child17 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 86,
                    "column": 4
                  },
                  "end": {
                    "line": 86,
                    "column": 46
                  }
                },
                "moduleName": "dashboard/templates/application.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Trading");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child18 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 91,
                    "column": 6
                  },
                  "end": {
                    "line": 91,
                    "column": 41
                  }
                },
                "moduleName": "dashboard/templates/application.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Jobs");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child19 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 92,
                    "column": 6
                  },
                  "end": {
                    "line": 92,
                    "column": 54
                  }
                },
                "moduleName": "dashboard/templates/application.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Alerts");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child20 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 93,
                    "column": 6
                  },
                  "end": {
                    "line": 93,
                    "column": 55
                  }
                },
                "moduleName": "dashboard/templates/application.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Queries");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child21 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 98,
                    "column": 5
                  },
                  "end": {
                    "line": 103,
                    "column": 5
                  }
                },
                "moduleName": "dashboard/templates/application.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("						");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"href","#");
                dom.setAttribute(el1,"class","ui item");
                var el2 = dom.createTextNode("\n							");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("i");
                dom.setAttribute(el2,"class","power icon");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n								");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n						");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element0);
                morphs[1] = dom.createMorphAt(element0,3,3);
                return morphs;
              },
              statements: [
                ["element","action",["logout"],[],["loc",[null,[99,34],[99,53]]]],
                ["content","auth.session.username",["loc",[null,[101,8],[101,33]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@2.0.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 45,
                  "column": 1
                },
                "end": {
                  "line": 107,
                  "column": 1
                }
              },
              "moduleName": "dashboard/templates/application.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("		");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("nav");
              dom.setAttribute(el1,"class","ui stackable secondary pointing menu");
              dom.setAttribute(el1,"role","navigation");
              var el2 = dom.createTextNode("\n			");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","ui container");
              var el3 = dom.createTextNode("\n				");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","ui simple dropdown item");
              var el4 = dom.createTextNode("\n					Home");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("i");
              dom.setAttribute(el4,"class","dropdown icon");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n					");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","menu");
              var el5 = dom.createTextNode("\n						");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n						");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n					");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n				");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n\n				");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n				");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n\n				");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","ui simple dropdown item");
              var el4 = dom.createTextNode("\n					Prices ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("i");
              dom.setAttribute(el4,"class","dropdown icon");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n					");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","menu");
              var el5 = dom.createTextNode("\n						");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n						");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n						");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n						");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n					");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n				");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n\n				");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n\n				");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","ui simple dropdown item");
              var el4 = dom.createTextNode("\n					Reports ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("i");
              dom.setAttribute(el4,"class","dropdown icon");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n					");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","menu");
              var el5 = dom.createTextNode("\n						");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n						");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n						");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n						");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("	\n						");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n						");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n						");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n					");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n				");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n\n				");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n\n				");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n\n				");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","ui simple dropdown item");
              var el4 = dom.createTextNode("\n					Monitoring ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("i");
              dom.setAttribute(el4,"class","dropdown icon");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n					");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4,"class","menu");
              var el5 = dom.createTextNode("\n						");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n						");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n						");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n					");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n				");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n\n				");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","right menu labeled icon");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("				");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n			");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n		");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n	");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1, 1]);
              var element2 = dom.childAt(element1, [1, 3]);
              var element3 = dom.childAt(element1, [7, 3]);
              var element4 = dom.childAt(element1, [11, 3]);
              var element5 = dom.childAt(element1, [17, 3]);
              var morphs = new Array(22);
              morphs[0] = dom.createMorphAt(element2,1,1);
              morphs[1] = dom.createMorphAt(element2,3,3);
              morphs[2] = dom.createMorphAt(element1,3,3);
              morphs[3] = dom.createMorphAt(element1,5,5);
              morphs[4] = dom.createMorphAt(element3,1,1);
              morphs[5] = dom.createMorphAt(element3,3,3);
              morphs[6] = dom.createMorphAt(element3,5,5);
              morphs[7] = dom.createMorphAt(element3,7,7);
              morphs[8] = dom.createMorphAt(element1,9,9);
              morphs[9] = dom.createMorphAt(element4,1,1);
              morphs[10] = dom.createMorphAt(element4,3,3);
              morphs[11] = dom.createMorphAt(element4,5,5);
              morphs[12] = dom.createMorphAt(element4,7,7);
              morphs[13] = dom.createMorphAt(element4,9,9);
              morphs[14] = dom.createMorphAt(element4,11,11);
              morphs[15] = dom.createMorphAt(element4,13,13);
              morphs[16] = dom.createMorphAt(element1,13,13);
              morphs[17] = dom.createMorphAt(element1,15,15);
              morphs[18] = dom.createMorphAt(element5,1,1);
              morphs[19] = dom.createMorphAt(element5,3,3);
              morphs[20] = dom.createMorphAt(element5,5,5);
              morphs[21] = dom.createMorphAt(dom.childAt(element1, [19]),1,1);
              return morphs;
            },
            statements: [
              ["block","link-to",["index"],["class","item header"],0,null,["loc",[null,[51,6],[51,67]]]],
              ["block","link-to",["clientsgraph"],["class","item"],1,null,["loc",[null,[52,6],[52,71]]]],
              ["block","link-to",["clients"],["class","item"],2,null,["loc",[null,[56,4],[56,58]]]],
              ["block","link-to",["carriers"],["class","item"],3,null,["loc",[null,[57,4],[57,60]]]],
              ["block","link-to",["purchase-prices"],["class","item"],4,null,["loc",[null,[62,6],[62,76]]]],
              ["block","link-to",["selling-prices"],["class","item"],5,null,["loc",[null,[63,6],[63,74]]]],
              ["block","link-to",["cbc"],["class","item"],6,null,["loc",[null,[64,6],[64,52]]]],
              ["block","link-to",["reports.cbc-price-overview"],["class","item"],7,null,["loc",[null,[65,6],[65,90]]]],
              ["block","link-to",["cdr"],["class","item"],8,null,["loc",[null,[69,4],[69,50]]]],
              ["block","link-to",["reports.client"],["class","item"],9,null,["loc",[null,[74,6],[74,74]]]],
              ["block","link-to",["reports.clients-overview"],["class","item"],10,null,["loc",[null,[75,6],[75,93]]]],
              ["block","link-to",["reports.carrier"],["class","item"],11,null,["loc",[null,[76,6],[76,76]]]],
              ["block","link-to",["reports.carrier-overview"],["class","item"],12,null,["loc",[null,[77,6],[77,93]]]],
              ["block","link-to",["reports.cbc"],["class","item"],13,null,["loc",[null,[78,6],[78,68]]]],
              ["block","link-to",["reports.cbc-latest"],["class","item"],14,null,["loc",[null,[79,6],[79,82]]]],
              ["block","link-to",["reports.cbc-performance"],["class","item"],15,null,["loc",[null,[80,6],[80,81]]]],
              ["block","link-to",["invoices"],["class","item"],16,null,["loc",[null,[84,4],[84,61]]]],
              ["block","link-to",["trading"],["class","item"],17,null,["loc",[null,[86,4],[86,58]]]],
              ["block","link-to",["job"],["class","item"],18,null,["loc",[null,[91,6],[91,53]]]],
              ["block","link-to",["alert-template"],["class","item"],19,null,["loc",[null,[92,6],[92,66]]]],
              ["block","link-to",["query-template"],["class","item"],20,null,["loc",[null,[93,6],[93,67]]]],
              ["block","ui-popup",[],["content","Logout","transition","fly down","position","right center"],21,null,["loc",[null,[98,5],[103,18]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17, child18, child19, child20, child21]
          };
        }());
        return {
          meta: {
            "revision": "Ember@2.0.2",
            "loc": {
              "source": null,
              "start": {
                "line": 23,
                "column": 1
              },
              "end": {
                "line": 107,
                "column": 1
              }
            },
            "moduleName": "dashboard/templates/application.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","auth.isExternalUser",["loc",[null,[23,11],[23,30]]]]],[],0,1,["loc",[null,[23,1],[107,1]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@2.0.2",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 108,
              "column": 0
            }
          },
          "moduleName": "dashboard/templates/application.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","auth.isTurkcellUser",["loc",[null,[2,7],[2,26]]]]],[],0,1,["loc",[null,[2,1],[107,8]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    return {
      meta: {
        "revision": "Ember@2.0.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 117,
            "column": 0
          }
        },
        "moduleName": "dashboard/templates/application.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"class","ui main container");
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]),1,1);
        morphs[2] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[3] = dom.createMorphAt(fragment,6,6,contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [
        ["block","if",[["get","auth.isAuthenticated",["loc",[null,[1,6],[1,26]]]]],[],0,null,["loc",[null,[1,0],[108,7]]]],
        ["content","outlet",["loc",[null,[111,1],[111,11]]]],
        ["content","ember-notify",["loc",[null,[114,0],[114,16]]]],
        ["inline","outlet",["modal"],[],["loc",[null,[116,0],[116,18]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});
define('dashboard/controllers/cdr', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend({
		store: Ember['default'].inject.service(),
		queryParams: ['carrierIn', 'carrierOut', 'from', 'to', 'page', 'size', 'dialcode'],
		carrierIn: null,
		carrierOut: null,
		from: new Date().getTime(),
		to: null,
		sort: 'id,desc',
		page: 1,
		size: 500,
		dialcode: null,

		direction: 'ASC',
		sortProperty: 'id',

		queries: Ember['default'].A(),
		directions: Ember['default'].A(['ASC', 'DESC']),

		columns: Ember['default'].computed(function () {
			var Cdr = this.get('store').modelFor('cdr'),
			    columns = Ember['default'].A();

			Cdr.eachAttribute(function (name, meta) {
				columns.addObject(meta);
			});

			return columns;
		}),

		updateSort: Ember['default'].observer('direction', 'sortProperty', function () {
			var sort = this.get('sortProperty') + ',' + this.get('direction');
			this.set('sort', sort);
		}),

		carrierDidChange: Ember['default'].observer('carrier', function () {
			this.set('carrierOut', this.get('carrier.key') || null);
		}),

		clientDidChange: Ember['default'].observer('client', function () {
			this.set('carrierIn', this.get('client.key') || null);
		}),

		meta: Ember['default'].computed(function () {
			return this.store._metadataFor('cdr');
		}),

		actions: {
			search: function search() {
				this.set('page', 1);
				this.send('reload');
			}
		}
	});

});
define('dashboard/controllers/cbc/index', ['exports', 'ember', 'ember-data', 'moment', 'dashboard/utils'], function (exports, Ember, DS, moment, utils) {

	'use strict';

	function format(date) {
		if (Ember['default'].isPresent(date)) {
			return moment['default'](parseInt(date)).format('YYYY-MM-DD HH:mm:SS');
		}

		return null;
	}

	/**
	 * Controller that handles the CBC template
	 *
	 * @class CBCController
	 * @extends {Ember.Controller}
	 */
	exports['default'] = Ember['default'].Controller.extend({
		auth: Ember['default'].inject.service(),

		notify: Ember['default'].inject.service(),

		/**
	  * Selected date for the Prices
	  * 
	  * @type {Number}
	  */
		date: null,

		/**
	  * Client operator record
	  * 
	  * @type {Client}
	  */
		client: Ember['default'].computed('model.[]', 'operator', {
			get: function get() {
				var client = this.get('model.firstObject.client');

				if (client) {
					return client;
				}

				var id = this.get('operator');

				if (id) {
					return this.store.recordForId('client', id);
				}
			}
		}),

		/**
	  * Fetch the CBC import dates
	  *
	  * @type {Promise}
	  */
		dates: Ember['default'].computed('client.id', {
			get: function get() {
				var _this = this;

				var id = this.get('client.id');
				var token = this.get('auth.sessionToken');

				if (Ember['default'].isEmpty(id)) {
					return Ember['default'].A();
				}

				var url = utils.buildUrl('api', 'cbc', 'dates', id);

				var promise = utils.ajax({
					url: url,
					beforeSend: function beforeSend(request) {
						request.setRequestHeader("Authorization", 'Bearer ' + token);
					}
				}).then(function (dates) {
					return dates.map(function (d) {
						return {
							value: d,
							label: format(d)
						};
					});
				})['catch'](function (error) {
					_this.send('warning', 'Could not fetch Import dates', error);

					return [];
				});

				return DS['default'].PromiseArray.create({
					promise: promise
				});
			}
		}),

		/**
	  * Formats the selected date.
	  *
	  * Needed to be manually because of the String conversion.
	  * 
	  * @property formatedDate
	  * @type {String}
	  * @default null
	  */
		formatedDate: Ember['default'].computed('date', {
			get: function get() {
				return format(this.get('date'));
			}
		}),

		clientDidChange: Ember['default'].observer('client', function () {
			this.set('operator', this.get('client.id') || null);
		})

	});

});
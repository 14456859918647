define('dashboard/auth/session', ['exports', 'ember', 'dashboard/auth/user', 'dashboard/auth/store', 'dashboard/auth/error', 'dashboard/utils'], function (exports, Ember, User, Store, UnauthorizedError, utils) {

	'use strict';

	function storeUserSession(service, userData) {
		var user = User['default'].create(userData);
		service.set('__user__', user);

		return user;
	}

	function wrapError(error) {
		if (error.message && error.status === 401) {
			return new UnauthorizedError['default'](error.message, error.status);
		} else {
			return new Ember['default'].Error(error.message || 'Could not Authenticate session.');
		}
	}

	exports['default'] = Ember['default'].Service.extend({

		init: function init() {
			this._super();

			this.set('__user__', null);
			this.set('sessionStore', Store['default'].create());
		},

		session: Ember['default'].computed.oneWay('__user__'),

		/**
	  * Contains the transition that lead to the
	  * login page.
	  *
	  * @property attemptedTransition
	  * @type {Transition}
	  */
		attemptedTransition: null,

		/**
	  * User Session token value obtained from the
	  * Token Store
	  *
	  * @property sessionToken
	  * @type {String}
	  */
		sessionToken: Ember['default'].computed.alias('sessionStore.data.token').readOnly(),

		/**
	  * Auth state of the Session
	  *
	  * @property isAuthenticated
	  * @type {Boolean}
	  */
		isAuthenticated: Ember['default'].computed('session', 'sessionToken', function () {
			return Ember['default'].isPresent('sessionToken') && this.get('session.status') === 'active';
		}),

		isExternalUser: Ember['default'].computed('session', 'sessionToken', function () {
			return Ember['default'].isPresent('sessionToken') && this.get('session.status') === 'active' && (this.get('session.username') === 'belco' || this.get('session.name') === 'belco');
		}),

		isTurkcellUser: Ember['default'].computed('session', 'sessionToken', function () {
			return Ember['default'].isPresent('sessionToken') && this.get('session.status') === 'active' && (this.get('session.username') === 'turkcell' || this.get('session.name') === 'turkcell');
		}),
		/**
	  * Verifies the User if the Session Token could be found in
	  * the Token Store.
	  *
	  * This returns a Promise which resolved with a User if the
	  * session is valid, otherwise it is rejected with a JSON response.
	  *
	  * @method authenticate
	  * @return {Ember.RSVP.Promise}
	  */
		authenticate: function authenticate() {
			var session = this,
			    token = this.get('sessionToken'),
			    headers = {};

			if (Ember['default'].isPresent(token)) {
				headers['Authorization'] = 'Bearer ' + token;
			}

			return utils.ajax({
				url: 'auth/me',
				headers: headers
			}).then(function (response) {
				Ember['default'].Logger.info('Obtained User data for current sessionToken', response);

				return storeUserSession(session, response);
			}, function (error) {
				error = wrapError(error);

				if (error.status === 401) {
					// clear the invalid token
					session.sessionStore.clear();
				}

				throw error;
			});
		},

		/**
	  * Authenticate the User credentials. The promise when
	  * resolved returns a User Model instance.
	  *
	  * @method login
	  * @param {Credentials} credentials
	  * @return {Ember.RSVP.Promise}
	  */
		login: function login(credentials) {
			var data = credentials.serialize();

			Ember['default'].Logger.info('Logging in User with credentials', data);

			var auth = this;
			data.type = 'JWT';

			credentials.set('isSaving', true);
			credentials.set('error', null);

			return utils.ajax({
				url: 'auth/login',
				type: 'post',
				data: data
			}).then(function (user) {
				var token = user.token;
				delete user.token;

				auth.sessionStore.persist({
					token: token
				});

				return storeUserSession(auth, user);
			}, function (error) {
				credentials.set('error', error);

				throw error;
			})['finally'](function () {
				credentials.set('isSaving', false);
			});
		},

		/**
	  * Logout the current user.
	  * Reset the user model and clear tokens from the store.
	  *
	  * @method logout
	  */
		logout: function logout() {
			this.set('__user__', null);
			this.sessionStore.clear();
		}
	});

});
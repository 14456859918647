define('dashboard/models/client', ['exports', 'ember-data', 'dashboard/mixins/operator/model'], function (exports, DS, OperatorMixinModel) {

	'use strict';

	exports['default'] = DS['default'].Model.extend(OperatorMixinModel['default'], {

		cbc: DS['default'].attr('boolean', {
			validate: {
				requred: true,
				presence: true
			}
		}),
		verivoxUploadSupported: DS['default'].attr('boolean', {
			validate: {
				requred: true,
				presence: true
			}
		}),
		productType: DS['default'].attr('string'),
		address: DS['default'].attr('string'),
		language: DS['default'].attr('string'),
		tax: DS['default'].attr('number'),
		email: DS['default'].attr('string'),
		cycle: DS['default'].attr('string'),
		invoicingEnabled: DS['default'].attr('boolean'),
		paymentDuePeriod: DS['default'].attr('number'),
		customerId: DS['default'].attr('string'),
		biller: DS['default'].attr('string')
	});

});
define('dashboard/initializers/date-picker', ['exports', 'ember', 'moment', 'ember-cli-daterangepicker/components/date-range-picker'], function (exports, Ember, moment, DateRangePickerComponent) {

	'use strict';

	exports.initialize = initialize;

	function initialize() {
		DateRangePickerComponent['default'].reopen({
			format: 'DD/MM/YY HH:mm',
			serverFormat: 'x',

			singleDatePicker: undefined,

			/**
	   * Redefine custom time ranges that are never cached.
	   *
	   * @type {Object}
	   */
			ranges: Ember['default'].computed(function () {
				return {
					Today: [moment['default']().startOf('day'), moment['default']().endOf('day')],
					Yesterday: [moment['default']().subtract(1, 'd').startOf('day'), moment['default']().subtract(1, 'd').endOf('day')],
					'Last 15 mins': [moment['default']().subtract(15, 'm'), moment['default']()],
					'Last 30 mins': [moment['default']().subtract(30, 'm'), moment['default']()],
					'Last hour': [moment['default']().subtract(1, 'h'), moment['default']()],
					'Last 2 hours': [moment['default']().subtract(2, 'h'), moment['default']()],
					'Last 3 hours': [moment['default']().subtract(3, 'h'), moment['default']()],
					'Last 4 hours': [moment['default']().subtract(4, 'h'), moment['default']()],
					'Last 6 hours': [moment['default']().subtract(6, 'h'), moment['default']()],
					'Last 12 hours': [moment['default']().subtract(12, 'h'), moment['default']()],
					'Last 2 days': [moment['default']().subtract(2, 'd'), moment['default']()],
					'Last 3 days': [moment['default']().subtract(3, 'd'), moment['default']()],
					'Last 7 Days': [moment['default']().subtract(1, 'w').endOf('day'), moment['default']().endOf('day')],
					'Last 30 Days': [moment['default']().subtract(29, 'd').startOf('day'), moment['default']().endOf('day')],
					'This Month': [moment['default']().startOf('month'), moment['default']().endOf('month')],
					'Last Month': [moment['default']().subtract(1, 'month').startOf('month'), moment['default']().subtract(1, 'month').endOf('month')]
				};
			}).volatile(),

			/**
	   * Setup a timer that would be run each minute to update the
	   * calendar ranges.
	   */
			scheduleUpdate: Ember['default'].on('didInsertElement', function () {
				if (this.get('singleDatePicker') === undefined) {
					this._timeout = Ember['default'].run.later(this, 'updateCalendar', 60000);
				}
			}),

			/**
	   * Updates the DateRangePicker instance with new Date Time ranges and
	   * triggers the DOM update.
	   */
			updateCalendar: function updateCalendar() {
				if (this.isDestroyed || this.isDestroying) {
					return;
				}

				try {
					var daterangepicker = this.$('.daterangepicker-input').data('daterangepicker');

					if (Ember['default'].isPresent(daterangepicker)) {
						daterangepicker.ranges = this.get('ranges');
						daterangepicker.updateCalendars();
					}
				} catch (e) {
					Ember['default'].Logger.warn('Could not update calendar', e.stack);
				}

				// Schedule the update for the next minute
				Ember['default'].run(this, 'scheduleUpdate');
			},

			/**
	   * Remove the scheduled function when the element is destroyed.
	   */
			unscheduleUpdate: Ember['default'].on('didDestroyElement', function () {
				Ember['default'].run.cancel(this._timeout);
			})
		});
	}

	exports['default'] = {
		name: 'date-picker',
		initialize: initialize
	};

});
define('dashboard/components/cbc-report-summary', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Component.extend({
		reports: null,
		meta: null,

		/**
	  * Report Summary extracted from the Metadata
	  * 
	  * @type {Object}
	  */
		summary: Ember['default'].computed.alias('meta.summary'),

		actions: {
			'export': function _export() {
				this.sendAction('export');
			}
		}
	});

});
define('dashboard/serializers/report', ['exports', 'dashboard/serializers/application'], function (exports, Serializer) {

	'use strict';

	exports['default'] = Serializer['default'].extend({
		_updateMetadata: function _updateMetadata(store, modelClass, metadata) {
			var typeMap = store.typeMapFor(modelClass);
			typeMap.metadata = metadata;
		}
	});

});
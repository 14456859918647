define('dashboard/templates/components/ui-pagination', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@2.0.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 6,
                  "column": 4
                },
                "end": {
                  "line": 10,
                  "column": 4
                }
              },
              "moduleName": "dashboard/templates/components/ui-pagination.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1,"class","ui button blue");
              var el2 = dom.createTextNode("\n       ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element3);
              morphs[1] = dom.createMorphAt(element3,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["pageChanged",["get","page",["loc",[null,[7,60],[7,64]]]],["get","current",["loc",[null,[7,65],[7,72]]]]],[],["loc",[null,[7,37],[7,74]]]],
              ["content","page",["loc",[null,[8,7],[8,15]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@2.0.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 10,
                  "column": 4
                },
                "end": {
                  "line": 14,
                  "column": 4
                }
              },
              "moduleName": "dashboard/templates/components/ui-pagination.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1,"class","ui button");
              var el2 = dom.createTextNode("\n       ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element2);
              morphs[1] = dom.createMorphAt(element2,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["pageChanged",["get","page",["loc",[null,[11,55],[11,59]]]],["get","current",["loc",[null,[11,60],[11,67]]]]],[],["loc",[null,[11,32],[11,69]]]],
              ["content","page",["loc",[null,[12,7],[12,15]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@2.0.2",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 2
              },
              "end": {
                "line": 15,
                "column": 2
              }
            },
            "moduleName": "dashboard/templates/components/ui-pagination.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","eq",[["get","page",["loc",[null,[6,14],[6,18]]]],["get","current",["loc",[null,[6,19],[6,26]]]]],[],["loc",[null,[6,10],[6,27]]]]],[],0,1,["loc",[null,[6,4],[14,11]]]]
          ],
          locals: ["page"],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@2.0.2",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 19,
              "column": 0
            }
          },
          "moduleName": "dashboard/templates/components/ui-pagination.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2,"class","left chevron icon");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2,"class","right chevron icon");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var element5 = dom.childAt(fragment, [5]);
          var morphs = new Array(5);
          morphs[0] = dom.createAttrMorph(element4, 'class');
          morphs[1] = dom.createElementMorph(element4);
          morphs[2] = dom.createMorphAt(fragment,3,3,contextualElement);
          morphs[3] = dom.createAttrMorph(element5, 'class');
          morphs[4] = dom.createElementMorph(element5);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["ui button icon ",["subexpr","if",[["get","isFirstDisabled",["loc",[null,[2,37],[2,52]]]],"disabled"],[],["loc",[null,[2,32],[2,65]]]]," previous"]]],
          ["element","action",["previous"],[],["loc",[null,[2,76],[2,97]]]],
          ["block","each",[["get","pages",["loc",[null,[5,10],[5,15]]]]],[],0,null,["loc",[null,[5,2],[15,11]]]],
          ["attribute","class",["concat",["ui button icon ",["subexpr","if",[["get","isLastDisabled",["loc",[null,[16,37],[16,51]]]],"disabled"],[],["loc",[null,[16,32],[16,64]]]]," next"]]],
          ["element","action",["next"],[],["loc",[null,[16,71],[16,88]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.2",
          "loc": {
            "source": null,
            "start": {
              "line": 19,
              "column": 0
            },
            "end": {
              "line": 26,
              "column": 0
            }
          },
          "moduleName": "dashboard/templates/components/ui-pagination.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(fragment, [3]);
          var morphs = new Array(6);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createElementMorph(element0);
          morphs[2] = dom.createMorphAt(element0,1,1);
          morphs[3] = dom.createAttrMorph(element1, 'class');
          morphs[4] = dom.createElementMorph(element1);
          morphs[5] = dom.createMorphAt(element1,1,1);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["ui button ",["subexpr","if",[["get","pagerSpread",["loc",[null,[20,32],[20,43]]]],"previous"],[],["loc",[null,[20,27],[20,56]]]]," ",["subexpr","if",[["get","isFirstDisabled",["loc",[null,[20,62],[20,77]]]],"disabled"],[],["loc",[null,[20,57],[20,90]]]]]]],
          ["element","action",["previous"],[],["loc",[null,[20,92],[20,113]]]],
          ["content","pagerPrevious",["loc",[null,[21,4],[21,21]]]],
          ["attribute","class",["concat",["ui button ",["subexpr","if",[["get","pagerSpread",["loc",[null,[23,32],[23,43]]]],"next"],[],["loc",[null,[23,27],[23,52]]]]," ",["subexpr","if",[["get","isLastDisabled",["loc",[null,[23,58],[23,72]]]],"disabled"],[],["loc",[null,[23,53],[23,85]]]]]]],
          ["element","action",["next"],[],["loc",[null,[23,87],[23,104]]]],
          ["content","pagerNext",["loc",[null,[24,4],[24,17]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@2.0.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 27,
            "column": 0
          }
        },
        "moduleName": "dashboard/templates/components/ui-pagination.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","unless",[["get","pager",["loc",[null,[1,10],[1,15]]]]],[],0,1,["loc",[null,[1,0],[26,11]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});
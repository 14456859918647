define('dashboard/mixins/price/route', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	function resolveResourceName(route, resource) {
		var name = route.get('routeName');

		return name + '.' + resource;
	}

	/**
	 * Mixins used on the main Resource Route for
	 * `PurchasePrice` and `SellingPrice` resources.
	 *
	 * @mixin
	 * @class PriceRouteMixin
	 */
	exports['default'] = Ember['default'].Mixin.create({

		/**
	  * Creates `PriceList` list model for the Import.
	  */
		createImportModel: function createImportModel() {
			throw new Ember['default'].Error('You must implement `createImportModel` method.');
		},

		actions: {

			/**
	   * Opens an Import Modal template
	   *
	   * @param  {Operator} operator
	   */
			'import': function _import(operator) {
				var resourceName = resolveResourceName(this, 'import');

				this.send('openModal', resourceName, {
					model: this.createImportModel(operator),
					controller: resourceName
				});
			},

			/**
	   * Transitions to the `list` route when an Operator is selected.
	   *
	   * @param  {Operator} operator
	   */
			select: function select(operator) {
				if (Ember['default'].isPresent(operator)) {
					var resourceName = resolveResourceName(this, 'list');
					this.transitionTo(resourceName, Ember['default'].get(operator, 'id'));
				} else {
					this.notify.error({
						html: '<p>No option selected</p>'
					});
				}
			}

		}
	});

});
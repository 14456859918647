define('dashboard/mixins/drop-down', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Mixin.create({
		classNames: ['ui', 'dropdown', 'selection'],
		classNameBindings: ['search', 'multiple'],
		moduleName: 'dropdown',
		search: false,
		multiple: false,

		values: Ember['default'].A(),
		selected: null,
		placeholder: 'Select option',
		update: true,

		setupDropdown: function setupDropdown() {
			this._super();

			var component = this;

			this.$().dropdown({
				onChange: function onChange(value, text, selectedItem) {
					Ember['default'].run(component, '_updateSingle', value, text, selectedItem);
				},

				onAdd: function onAdd(value, text, element) {
					Ember['default'].run(component, '_addValue', value, text, element);
				},

				onRemove: function onRemove(value, text, element) {
					Ember['default'].run(component, '_removeValue', value, text, element);
				}
			});

			var selected = this.get('selected');
			this.$().dropdown('set selected', selected);
		},

		_updateSingle: function _updateSingle(value) {
			if (this.get('update') && !this.get('multiple')) {
				this.set('selected', value);
				// this.sendAction('selected', value);
			}
		},

		_addValue: function _addValue(value) {
			var selected = this.get('selected');

			if (Ember['default'].isNone(selected)) {
				selected = Ember['default'].A();
			}

			Ember['default'].assert('You must pass an Array value for `selected` when using `multiple`', Ember['default'].isArray(selected));

			selected.addObject(value);
			this.set('selected', selected);
			// this.sendAction('add', value);
		},

		_removeValue: function _removeValue(value) {
			var selected = this.get('selected');

			Ember['default'].assert('You must pass an Array value for `selected` when using `multiple`', Ember['default'].isArray(selected));

			selected.removeObject(value);
			this.set('selected', selected);
			// this.sendAction('remove', value);
		}

	});

});
define('dashboard/mixins/templates/model', ['exports', 'ember', 'ember-data'], function (exports, Ember, DS) {

	'use strict';

	exports['default'] = Ember['default'].Mixin.create({

		name: DS['default'].attr('string', {
			validation: {
				required: true
			}
		}),

		template: DS['default'].attr('string', {
			validation: {
				required: true
			}
		}),

		createdAt: DS['default'].attr('date'),

		updatedAt: DS['default'].attr('date')

	});

});
define('dashboard/routes/loggers', ['exports', 'ember', 'dashboard/mixins/authenticated-route'], function (exports, Ember, AuthenticatedRoute) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRoute['default'], {
		queryParams: {
			page: {
				refreshModel: true,
				replace: false
			},
			size: {
				refreshModel: true,
				replace: false
			}
		},
		model: function model(params) {
			// Spring pagination is Zero based
			if (params.page > 0) {
				params.page = params.page - 1;
			}

			return this.store.query('logger', params);
		},

		/**
	  * Reset the `name` filter
	  */
		resetController: function resetController(controller, isExiting) {
			if (isExiting) {
				controller.set('name', null);
			}
		}
	});

});
define('dashboard/controllers/selling-prices/list', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend({
		store: Ember['default'].inject.service(),
		queryParams: ['dialcode', 'page', 'size', 'destination', 'timestamp'],
		timestamp: null,
		dialcode: null,
		destination: null,
		page: 1,
		size: 20,

		client: Ember['default'].computed('model.[]', 'operatorId', {
			get: function get() {
				var client = this.get('model.firstObject.client');

				if (client) {
					return client;
				}

				var id = this.get('operatorId');

				if (id) {
					return this.store.recordForId('client', id);
				}
			}
		}),

		dialcodeDidChange: Ember['default'].observer('dialcode', function () {
			this.set('dialcodeValue', this.get('dialcode'));
		}),

		meta: Ember['default'].computed(function () {
			return this.store._metadataFor('selling-price');
		}),

		actions: {
			search: function search() {
				var id = Ember['default'].get(this, 'client.id');
				var destination = this.get('destination') || null;
				var dialcode = this.get('dialcodeValue') || null;

				this.transitionToRoute('selling-prices.list', id, {
					queryParams: {
						destination: destination,
						dialcode: dialcode,
						page: 1
					}
				});
			}
		}
	});

});
define('dashboard/auth/credentials', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		isSaving: false,
		error: null,
		username: null,
		password: null,

		serialize: function serialize() {
			var username = this.get('username'),
			    password = this.get('password');

			return {
				username: username,
				password: password
			};
		}
	});

});
define('dashboard/mixins/reports/model', ['exports', 'ember', 'ember-data'], function (exports, Ember, DS) {

  'use strict';

  exports['default'] = Ember['default'].Mixin.create({
  	/**
    * Reports clients.
    * 
    * @type {String}
    */
  	clients: DS['default'].attr('string'),

  	/**
    * Reports carriers.
    * 
    * @type {String}
    */
  	carriers: DS['default'].attr('string'),

  	/**
    * Reports destination.
    * 
    * @type {String}
    */
  	destination: DS['default'].attr('string'),

  	/**
    * Total number of calls
    * 
    * @type {Number}
    */
  	calls: DS['default'].attr('number'),

  	/**
    * Total number of successfull calls
    * 
    * @type {Number}
    */
  	successfullCalls: DS['default'].attr('number'),

  	/**
    * Total number of minutes of all calls
    * 
    * @type {Number}
    */
  	minutes: DS['default'].attr('number'),

  	/**
    * Price for which these calls are sold
    * 
    * @type {Number}
    */
  	sellingPrice: DS['default'].attr('number'),

  	/**
    * Revenue made for this Destination
    * 
    * @type {Number}
    */
  	revenue: DS['default'].attr('number'),

  	/**
    * Carrier costs for this Destination
    * 
    * @type {Number}
    */
  	carrierCosts: DS['default'].attr('number'),

  	/**
    * Profit margin for this Destination
    * 
    * @type {Number}
    */
  	margin: DS['default'].attr('number'),

  	/**
    * Minimum margin for this Destination
    * 
    * @type {Number}
    */
  	marginMin: DS['default'].attr('number'),

  	/**
    * Margin precentage
    * 
    * @type {String}
    */
  	marginPercentage: DS['default'].attr('string'),

  	/**
    * @type {Number}
    */
  	acd: DS['default'].attr('number'),

  	/**
    * @type {Number}
    */
  	asr: DS['default'].attr('string'),

  	/**
    * @type {Number}
    */
  	pdd: DS['default'].attr('number'),

  	/**
    * @type {Number}
    */
  	ner: DS['default'].attr('number'),

  	/**
    * Is there a margin loss for this destination.
    * 
    * @type {Boolean}
    */
  	marginLoss: Ember['default'].computed.lt('margin', 0.0),

  	costPerMin: (function () {
  		return parseFloat(parseFloat(this.get('carrierCosts')) / parseFloat(this.get('minutes'))).toFixed(4);
  	}).property('carrierCosts', 'minutes')

  });

});
define('dashboard/components/operator-list', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Component.extend({
		actions: {

			/**
	   * Send `prices` action when the Price button is clicked
	   */
			prices: function prices(model) {
				this.sendAction('prices', model);
			},

			/**
	   * Send `edit` action when the edit button is clicked
	   */
			edit: function edit(model) {
				this.sendAction('edit', model);
			},

			/**
	   * Send `delete` action when the edit button is clicked
	   */
			'delete': function _delete(model) {
				this.sendAction('delete', model);
			}
		}
	});

});
define('dashboard/mixins/price/list-route', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Mixin.create({
		queryParams: {
			dialcode: {
				refreshModel: true,
				replace: false
			},
			destination: {
				refreshModel: true,
				replace: false
			},
			timestamp: {
				refreshModel: true,
				replace: false
			},
			page: {
				refreshModel: true
			},
			size: {
				refreshModel: false
			}
		},

		/**
	  * Create Query parameters for the Model hook.
	  *
	  */
		formatQuery: function formatQuery(params, operatorKey) {
			var query = Ember['default'].merge({}, params);
			query[operatorKey] = params.id;

			// Spring pagination is Zero based
			if (query.page > 0) {
				query.page = query.page - 1;
			}

			if (Ember['default'].isPresent(query.dialcode)) {
				query.dialcode = query.dialcode + '%';
			}

			delete query.id;

			return query;
		},

		setupController: function setupController(controller) {
			var params = this.paramsFor(this.routeName);
			var operatorId = Ember['default'].get(params, 'id');

			Ember['default'].set(controller, 'operatorId', operatorId);

			return this._super.apply(this, arguments);
		},

		/**
	  * Reset the `dialcode` queryparam
	  */
		resetController: function resetController(controller, isExiting) {
			if (isExiting) {
				controller.set('dialcode', null);
				controller.set('destination', null);
				controller.set('dialcodeValue', null);
			}
		}
	});

});
define('dashboard/transforms/local-date', ['exports', 'ember-data', 'moment'], function (exports, DS, moment) {

  'use strict';

  exports['default'] = DS['default'].DateTransform.reopen({
    serialize: function serialize(deserialized) {
      return moment['default'](deserialized).format('YYYY-MM-DD');
    }
  });

});
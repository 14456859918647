define('dashboard/mixins/razorflow-dashboard', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	/* global EmbeddedDashboard:true */
	exports['default'] = Ember['default'].Mixin.create({
		/**
	  * Dashboard Element ID
	  *
	  * @type {String}
	  */
		dashboardId: null,
		/**
	  * Embedded Dashboard instance.
	  *
	  * @type {EmbeddedDashboard}
	  */
		dashboard: null,

		/**
	  * Width of the Dashboard
	  *
	  * @type {String|Number}
	  * @default 100%
	  */
		width: '100%',

		/**
	  * Height of the Dashboard
	  *
	  * @type {String|Number}
	  * @default 100%
	  */
		height: '100%',

		init: function init() {
			this._super();

			this.dashboardId = Ember['default'].generateGuid();
			this.dashboard = new EmbeddedDashboard();
			// this.dashboard.setWidth(this.get('width'));
			// this.dashboard.setHeight(this.get('height'));
		},

		/**
	  * Embed the Dashboard to the Component Element
	  *
	  * @method embed
	  */
		embed: function embed() {
			var dashboard = this.get('dashboard'),
			    elementId = this.get('dashboardId'),
			    element = Ember['default'].$('<div/>', {
				'class': 'razorflow-dashboard',
				'id': elementId
			});

			Ember['default'].assert('EmbeddedDashboard is not present in the Component', !!dashboard);
			Ember['default'].assert('Element ID is not defined for this Component', !!element);

			this.$().append(element);
			dashboard.embedTo(elementId);
		},

		/**
	  * Register a Component into the Dashboard instance
	  *
	  * @method addComponent
	  * @param {Component} component
	  */
		addComponent: function addComponent(component) {
			var dashboard = this.get('dashboard');

			Ember['default'].assert('EmbeddedDashboard is not present in the Component', !!dashboard);

			dashboard.addComponent(component);
		},

		/**
	  * Render the Error message to the Component Element
	  *
	  * @param  {String} message
	  */
		showError: function showError(message) {
			var _this = this;

			var chart = this.get(chart),
			    template = '<p class=\'ui center compact message error\'>' + message + '</p>';

			if (chart && chart.removeFromDashboard) {
				chart.removeFromDashboard();
			}

			Ember['default'].run.scheduleOnce('afterRender', this, function () {
				_this.$().html(template);
			});
		}

	});

});
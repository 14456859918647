define('dashboard/tests/unit/mixins/operator-route-test', ['ember', 'dashboard/mixins/operator-route', 'qunit'], function (Ember, OperatorRouteMixin, qunit) {

  'use strict';

  qunit.module('Unit | Mixin | operator route');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var OperatorRouteObject = Ember['default'].Object.extend(OperatorRouteMixin['default']);
    var subject = OperatorRouteObject.create();
    assert.ok(subject);
  });

});
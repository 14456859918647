define('dashboard/auth/store', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  function notifyObservers(obj, fn) {
  	Ember['default'].propertyWillChange(obj, 'data');
  	Ember['default'].run(obj, fn);
  	Ember['default'].propertyDidChange(obj, 'data');
  }

  /**
   * Stores the Authentication Token inside the `localStorage`
   *
   * @class SessionStore
   */
  exports['default'] = Ember['default'].Object.extend({
  	/**
    * The key the store stores the data in.
    * @property key
    * @type String
    * @default 'laasso_auth:token'
    */
  	key: 'auth:token',

  	/**
    * Returns the Token information.
    * This is a read only property, to change the data,
    * you must invoke the `persist` function.
    *
    * @property data
    * @type {Object}
    * @readOnly
    */
  	data: Ember['default'].computed(function () {
  		return this.restore();
  	}).readOnly(),

  	/**
    * Persists the `data` in the store. This actually replaces all currently
    * saved data.
    *
    * @method persist
    * @param  {Object} data
    */
  	persist: function persist(data) {
  		Ember['default'].assert('Data to be stored must be an Object or Array, you passed: ' + typeof data, typeof data === 'object');

  		notifyObservers(this, function () {
  			localStorage.setItem(this.key, JSON.stringify(data));
  		});
  	},

  	/**
    * Restores all data currently saved in the `localStorage` identified by the
    * `laasso_auth:token` as one plain object.
    *
    * @method restore
    * @return {Object} All data currently persisted in the `localStorage`
    */
  	restore: function restore() {
  		var data = localStorage.getItem(this.key);
  		return JSON.parse(data) || {};
  	},

  	/**
    * Clears the store by deleting all `localStorage` keys prefixed with the
    * `laasso_auth:token`.
    *
    * @method clear
    */
  	clear: function clear() {
  		notifyObservers(this, function () {
  			localStorage.removeItem(this.key);
  		});
  	}
  });

});
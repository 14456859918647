define('dashboard/helpers/currency-icon', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	var mappings = {
		EUR: 'eur',
		USD: 'dollar'
	};

	exports['default'] = Ember['default'].Helper.helper(function (params) {
		var currency = params[0];

		if (Ember['default'].isEmpty(currency)) {
			return;
		}

		var value = mappings[currency];

		Ember['default'].assert('Invalid Currency value, you passed ' + currency, Ember['default'].isPresent(value));

		var className = 'fa fa-' + value;

		return Ember['default'].String.htmlSafe('<i class="' + className + '" />');
	});

});
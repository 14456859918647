define('dashboard/templates/trading', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.2",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 5
            },
            "end": {
              "line": 9,
              "column": 5
            }
          },
          "moduleName": "dashboard/templates/trading.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("						");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","item");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element7 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element7, 'data-value');
          morphs[1] = dom.createMorphAt(element7,0,0);
          return morphs;
        },
        statements: [
          ["attribute","data-value",["concat",[["get","client.key",["loc",[null,[8,38],[8,48]]]]]]],
          ["content","client.name",["loc",[null,[8,52],[8,67]]]]
        ],
        locals: ["client"],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.2",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 0
            },
            "end": {
              "line": 31,
              "column": 0
            }
          },
          "moduleName": "dashboard/templates/trading.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","segment");
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","ui active centered large inline text loader");
          var el3 = dom.createTextNode("Loading trading prices");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.2",
            "loc": {
              "source": null,
              "start": {
                "line": 34,
                "column": 1
              },
              "end": {
                "line": 38,
                "column": 1
              }
            },
            "moduleName": "dashboard/templates/trading.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("		");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","ui center compact message warning");
            var el2 = dom.createTextNode("\n			");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            var el3 = dom.createTextNode("Could not find any Price for ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" and destination ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n		");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element4,1,1);
            morphs[1] = dom.createMorphAt(element4,3,3);
            return morphs;
          },
          statements: [
            ["content","carrier.key",["loc",[null,[36,35],[36,50]]]],
            ["content","destination",["loc",[null,[36,67],[36,82]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.2",
            "loc": {
              "source": null,
              "start": {
                "line": 49,
                "column": 5
              },
              "end": {
                "line": 51,
                "column": 5
              }
            },
            "moduleName": "dashboard/templates/trading.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("						");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("th");
            dom.setAttribute(el1,"style","border-right-width: 4px;");
            dom.setAttribute(el1,"colspan","2");
            var el2 = dom.createTextNode("Carrier Price #");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["content","index",["loc",[null,[50,70],[50,79]]]]
          ],
          locals: ["index"],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.2",
            "loc": {
              "source": null,
              "start": {
                "line": 54,
                "column": 5
              },
              "end": {
                "line": 57,
                "column": 5
              }
            },
            "moduleName": "dashboard/templates/trading.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("						");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("th");
            dom.setAttribute(el1,"style","border-left-width: 4px;");
            var el2 = dom.createTextNode("Carrier");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n						");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("th");
            dom.setAttribute(el1,"style","border-right-width: 4px;");
            var el2 = dom.createTextNode("Price");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: ["index"],
          templates: []
        };
      }());
      var child3 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@2.0.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 78,
                    "column": 8
                  },
                  "end": {
                    "line": 80,
                    "column": 8
                  }
                },
                "moduleName": "dashboard/templates/trading.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("									");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("							\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","price-indication",[["get","offer.indication",["loc",[null,[79,28],[79,44]]]]],[],["loc",[null,[79,9],[79,46]]]],
                ["inline","price-color",[["get","offer.price",["loc",[null,[79,61],[79,72]]]],["get","price.price",["loc",[null,[79,73],[79,84]]]]],[],["loc",[null,[79,47],[79,86]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@2.0.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 71,
                  "column": 6
                },
                "end": {
                  "line": 82,
                  "column": 6
                }
              },
              "moduleName": "dashboard/templates/trading.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("							");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("td");
              dom.setAttribute(el1,"style","border-left-width: 4px;");
              var el2 = dom.createTextNode("\n								");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"href","#");
              var el3 = dom.createTextNode("\n									");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n								");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n							");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n							");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("td");
              dom.setAttribute(el1,"style","border-right-width: 4px;");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("							");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(4);
              morphs[0] = dom.createElementMorph(element1);
              morphs[1] = dom.createMorphAt(element1,1,1);
              morphs[2] = dom.createMorphAt(element1,3,3);
              morphs[3] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
              return morphs;
            },
            statements: [
              ["element","action",["quality",["get","price",["loc",[null,[73,39],[73,44]]]],["get","offer",["loc",[null,[73,45],[73,50]]]]],[],["loc",[null,[73,20],[73,52]]]],
              ["inline","operator-color",[["get","offer.color",["loc",[null,[74,26],[74,37]]]]],[],["loc",[null,[74,9],[74,39]]]],
              ["content","offer.carrier",["loc",[null,[74,40],[74,57]]]],
              ["block","if",[["get","offer.carrier",["loc",[null,[78,14],[78,27]]]]],[],0,null,["loc",[null,[78,8],[80,15]]]]
            ],
            locals: ["offer"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@2.0.2",
            "loc": {
              "source": null,
              "start": {
                "line": 61,
                "column": 4
              },
              "end": {
                "line": 84,
                "column": 4
              }
            },
            "moduleName": "dashboard/templates/trading.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("					");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            var el2 = dom.createTextNode("\n						");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2,"rowspan","2");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n						");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2,"rowspan","2");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n						");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2,"rowspan","2");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n						");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2,"rowspan","2");
            dom.setAttribute(el2,"style","white-space: nowrap");
            var el3 = dom.createTextNode("\n							");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("b");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n						");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n					");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n					");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("					");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var element3 = dom.childAt(element2, [7, 1]);
            var morphs = new Array(6);
            morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element2, [5]),0,0);
            morphs[3] = dom.createMorphAt(element3,0,0);
            morphs[4] = dom.createMorphAt(element3,2,2);
            morphs[5] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
            return morphs;
          },
          statements: [
            ["content","price.masterDestination",["loc",[null,[63,22],[63,49]]]],
            ["content","price.destination",["loc",[null,[64,22],[64,43]]]],
            ["content","price.dialcode",["loc",[null,[65,22],[65,40]]]],
            ["content","price.price",["loc",[null,[67,10],[67,25]]]],
            ["inline","currency-icon",[["get","price.currency",["loc",[null,[67,42],[67,56]]]]],[],["loc",[null,[67,26],[67,58]]]],
            ["block","each",[["get","price.prices",["loc",[null,[71,14],[71,26]]]]],[],0,null,["loc",[null,[71,6],[82,15]]]]
          ],
          locals: ["price"],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@2.0.2",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 0
            },
            "end": {
              "line": 88,
              "column": 0
            }
          },
          "moduleName": "dashboard/templates/trading.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table-wrapper trading-table");
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("table");
          dom.setAttribute(el2,"class","ui celled structured table");
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("thead");
          var el4 = dom.createTextNode("\n				");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("tr");
          var el5 = dom.createTextNode("\n					");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("th");
          dom.setAttribute(el5,"rowspan","2");
          var el6 = dom.createTextNode("Master Destination");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n					");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("th");
          dom.setAttribute(el5,"rowspan","2");
          var el6 = dom.createTextNode("Destination");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n					");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("th");
          dom.setAttribute(el5,"rowspan","2");
          var el6 = dom.createTextNode("Dialcode");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n					");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("th");
          dom.setAttribute(el5,"rowspan","2");
          var el6 = dom.createTextNode("Selling Price");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("				");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n				");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("tr");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("				");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n			");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("tbody");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("			");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [2, 1]);
          var element6 = dom.childAt(element5, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createMorphAt(dom.childAt(element6, [1]),9,9);
          morphs[2] = dom.createMorphAt(dom.childAt(element6, [3]),1,1);
          morphs[3] = dom.createMorphAt(dom.childAt(element5, [3]),1,1);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [
          ["block","unless",[["get","prices",["loc",[null,[34,11],[34,17]]]]],[],0,null,["loc",[null,[34,1],[38,12]]]],
          ["block","each",[["get","priceListCount",["loc",[null,[49,13],[49,27]]]]],[],1,null,["loc",[null,[49,5],[51,14]]]],
          ["block","each",[["get","priceListCount",["loc",[null,[54,13],[54,27]]]]],[],2,null,["loc",[null,[54,5],[57,14]]]],
          ["block","each",[["get","prices",["loc",[null,[61,12],[61,18]]]]],[],3,null,["loc",[null,[61,4],[84,13]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.2",
          "loc": {
            "source": null,
            "start": {
              "line": 90,
              "column": 0
            },
            "end": {
              "line": 94,
              "column": 0
            }
          },
          "moduleName": "dashboard/templates/trading.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]),0,0);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["ui center compact message ",["subexpr","if",[["get","key",["loc",[null,[91,44],[91,47]]]],"danger","warning"],[],["loc",[null,[91,39],[91,69]]]]]]],
          ["content","prices.reason.message",["loc",[null,[92,5],[92,30]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@2.0.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 94,
            "column": 7
          }
        },
        "moduleName": "dashboard/templates/trading.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("h2");
        dom.setAttribute(el1,"class","ui dividing header");
        var el2 = dom.createTextNode("Trading");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ui column grid");
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","column");
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("form");
        dom.setAttribute(el3,"class","ui form");
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","fields");
        var el5 = dom.createTextNode("\n				");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","four wide field");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("				");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n				");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","four wide field");
        var el6 = dom.createTextNode("\n					");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n				");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n			");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n		");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ui hidden divider");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ui one column grid");
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","column");
        var el3 = dom.createTextNode("\n		\n	");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element8 = dom.childAt(fragment, [2, 1, 1]);
        var element9 = dom.childAt(element8, [1]);
        var morphs = new Array(6);
        morphs[0] = dom.createElementMorph(element8);
        morphs[1] = dom.createMorphAt(dom.childAt(element9, [1]),1,1);
        morphs[2] = dom.createMorphAt(dom.childAt(element9, [3]),1,1);
        morphs[3] = dom.createMorphAt(fragment,8,8,contextualElement);
        morphs[4] = dom.createMorphAt(fragment,10,10,contextualElement);
        morphs[5] = dom.createMorphAt(fragment,12,12,contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["element","action",["search",["get","carrier",["loc",[null,[4,42],[4,49]]]]],["on","submit"],["loc",[null,[4,24],[4,63]]]],
        ["block","select-clients",[],["client",["subexpr","@mut",[["get","selectedClient",["loc",[null,[7,30],[7,44]]]]],[],[]],"multiple",false],0,null,["loc",[null,[7,5],[9,24]]]],
        ["inline","select-destinations",[],["type","parent","selected",["subexpr","@mut",[["get","destination",["loc",[null,[12,50],[12,61]]]]],[],[]]],["loc",[null,[12,5],[12,63]]]],
        ["block","if",[["get","prices.isPending",["loc",[null,[27,6],[27,22]]]]],[],1,null,["loc",[null,[27,0],[31,7]]]],
        ["block","if",[["get","prices.isFulfilled",["loc",[null,[33,6],[33,24]]]]],[],2,null,["loc",[null,[33,0],[88,7]]]],
        ["block","if",[["get","prices.isRejected",["loc",[null,[90,6],[90,23]]]]],[],3,null,["loc",[null,[90,0],[94,7]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }()));

});
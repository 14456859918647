define('dashboard/tests/components/select-carriers.jshint', function () {

  'use strict';

  QUnit.module('JSHint - components/select-carriers.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(true, 'components/select-carriers.js should pass jshint.');
  });

});
define('dashboard/components/line-chart', ['exports', 'ember', 'dashboard/mixins/razorflow-dashboard'], function (exports, Ember, RazorflowDashboardMixin) {

	'use strict';

	/* global ChartComponent:true, moment:true */
	exports['default'] = Ember['default'].Component.extend(RazorflowDashboardMixin['default'], {
		/**
	  * Dashboard Caption label
	  *
	  * @type {String}
	  */
		caption: 'Dashboard',

		/**
	  * Label used to define the Series Values in the
	  * dashboard.
	  *
	  * @type {String}
	  */
		seriesLabel: 'Series',

		/**
	  * Define the Type of the ChartComponent
	  *
	  * @type {String}
	  */
		type: null,

		/**
	  * Prefix added to the Label Values in the Y Axis
	  *
	  * @type {String}
	  */
		prefix: null,

		/**
	  * Suffix added to the Label Values in the Y Axis
	  *
	  * @type {String}
	  */
		suffix: null,

		/**
	  * Label added to the Y Axis
	  *
	  * @type {String}
	  */
		yAxisLabel: null,

		dataDidChange: Ember['default'].observer('data.[]', function () {
			var _this = this;

			var chart = this.get('chart');

			if (chart && chart.lock) {
				chart.lock();
				chart.clearChart();

				this.get('data').then(function (raw) {
					var data = _this.formatData(raw);
					_this.renderChartData(chart, data.dates, data.values);
				}, function (error) {
					_this.showError(error.message);
				});
			}
		}),

		/**
	  * Format the Data for the Chart Component
	  *
	  * @param  {Array} data
	  * @return {Object}
	  */
		formatData: function formatData(data) {
			var _this2 = this;

			var dates = [],
			    values = [];

			data.forEach(function (value) {
				dates.unshift(_this2.formatDateEntry(value.date));
				values.unshift(value.value);
			});

			return {
				dates: dates, values: values
			};
		},

		/**
	  * Format the Date entry into a Human readable string.
	  *
	  * @param  {Number} date
	  * @return {String}
	  */
		formatDateEntry: function formatDateEntry(date) {
			var m = moment(date),
			    format = 'YYYY-MM-DD HH:mm';

			if (m.hour() === 0 && m.minutes() === 0) {
				format = 'YYYY-MM-DD';
			}

			return m.format(format);
		},

		/**
	  * Update the Chart Series
	  *
	  * @param  {ChartComponent} chart
	  * @param  {Array}          dates
	  * @param  {Array}          values
	  */
		renderChartData: function renderChartData(chart, dates, values) {
			var guid = this.getWithDefault('_seriesGuid', Ember['default'].generateGuid(null, 'series')),
			    type = this.get('type'),
			    label = this.get('seriesLabel');

			chart.setLabels(dates);
			chart.addSeries(guid, label, values, {
				seriesDisplayType: type
			});

			this.set('_seriesGuid', guid);

			if (chart.isLocked()) {
				chart.unlock();
			}
		},

		didInsertElement: function didInsertElement() {
			var chart = new ChartComponent("sales"),
			    type = this.get('type'),
			    caption = this.get('caption');

			Ember['default'].assert('You must define a Type for the ChartComponent', Ember['default'].isPresent(type));

			chart.setDimensions(12, 6);
			chart.setCaption(caption);

			chart.setYAxis(this.get('yAxisLabel'), {
				numberPrefix: this.get('prefix') || '',
				numberSuffix: this.get('suffix') || '',
				numberHumanize: false
			});

			chart.lock();

			this.addComponent(chart);
			this.embed();

			this.set('chart', chart);
		}

	});

});
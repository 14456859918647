define('dashboard/routes/selling-prices/list', ['exports', 'ember', 'dashboard/mixins/price/list-route'], function (exports, Ember, PriceListRoute) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(PriceListRoute['default'], {
		model: function model(params) {
			var query = this.formatQuery(params, 'client.id');
			return this.store.query('selling-price', query);
		}
	});

});
define('dashboard/helpers/costpermin', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Helper.helper(function (params) {
		var cost = params[0];

		var minutes = params[1];

		return parseFloat(parseFloat(cost) / parseFloat(minutes)).toFixed(4);
	});

});
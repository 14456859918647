define('dashboard/mixins/operator/form-route', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Mixin.create({
		/**
	  * Set the controller name
	  */
		controllerName: 'operators/form',
		/**
	  * Set the template name
	  */
		templateName: 'operators/form',

		actions: {

			/**
	   * Destroy the Record if it is new, when the user
	   * is about the leave the route.
	   */
			willTransition: function willTransition() {
				var model = this.get('controller.model');

				if (model && Ember['default'].get(model, 'isNew')) {
					model.destroyRecord();
				}
			}
		}
	});

});
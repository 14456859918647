define('dashboard/routes/selling-prices', ['exports', 'ember', 'dashboard/mixins/price/route', 'dashboard/mixins/authenticated-route', 'dashboard/utils/file-lists'], function (exports, Ember, PriceRoute, AuthenticatedRoute, file_lists) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRoute['default'], PriceRoute['default'], {
		createImportModel: function createImportModel(operator) {
			var priceList = file_lists.SellingPriceList.create();
			priceList.set('client', operator);

			return priceList;
		}
	});

});
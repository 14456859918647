define('dashboard/routes/alert-template/create', ['exports', 'ember', 'dashboard/mixins/templates/form-route'], function (exports, Ember, TemplateFormRoute) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(TemplateFormRoute['default'], {
		model: function model() {
			return this.store.createRecord('alert-template');
		}
	});

});
define('dashboard/tests/controllers/application.jshint', function () {

  'use strict';

  QUnit.module('JSHint - controllers/application.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(true, 'controllers/application.js should pass jshint.');
  });

});
define('dashboard/templates/modal/invoice', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.2",
            "loc": {
              "source": null,
              "start": {
                "line": 15,
                "column": 16
              },
              "end": {
                "line": 19,
                "column": 16
              }
            },
            "moduleName": "dashboard/templates/modal/invoice.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","ui basic red pointing prompt label transition visible");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["content","error.message",["loc",[null,[17,20],[17,37]]]]
          ],
          locals: ["error"],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.2",
            "loc": {
              "source": null,
              "start": {
                "line": 44,
                "column": 18
              },
              "end": {
                "line": 48,
                "column": 18
              }
            },
            "moduleName": "dashboard/templates/modal/invoice.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","ui basic red pointing prompt label transition visible");
            var el2 = dom.createTextNode("\n                      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["content","error.message",["loc",[null,[46,22],[46,39]]]]
          ],
          locals: ["error"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@2.0.2",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 64,
              "column": 0
            }
          },
          "moduleName": "dashboard/templates/modal/invoice.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","ui fullscreen modal");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","ui header");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("i");
          dom.setAttribute(el3,"class","send outline icon");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          dom.setAttribute(el3,"class","content");
          var el4 = dom.createTextNode("Send invoice to ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","content");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("form");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","ui two column grid");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","column");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("label");
          dom.setAttribute(el7,"for","serialNumber");
          var el8 = dom.createTextNode("Serial number");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          var el8 = dom.createTextNode("\n");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("              ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","ui horizontal divider");
          var el7 = dom.createTextNode("Or");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","field");
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          var el8 = dom.createTextNode("\n                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("button");
          dom.setAttribute(el8,"class","ui teal right button");
          var el9 = dom.createTextNode("Generate");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n              ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("p");
          dom.setAttribute(el7,"class","help-text");
          var el8 = dom.createTextNode("\n                Enter a seed value for the Invoice serial number, or leave it blank.");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("br");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                Eg. ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("b");
          var el9 = dom.createTextNode("1234");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode(" would generate a ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("b");
          var el9 = dom.createTextNode("2017000001235");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("br");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                If no seed value is provided, server would automatically generate a new subsequent number based on the last\n                invoice number sent.\n              ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","column");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("label");
          dom.setAttribute(el7,"for","comment");
          var el8 = dom.createTextNode("Comment");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          var el8 = dom.createTextNode("\n");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("                ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","actions");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","ui red button cancel");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("i");
          dom.setAttribute(el4,"class","remove icon");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" Cancel\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("i");
          dom.setAttribute(el4,"class","send icon");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" Send\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [3, 1]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element2, [1]);
          var element4 = dom.childAt(element3, [1]);
          var element5 = dom.childAt(element3, [5, 1]);
          var element6 = dom.childAt(element5, [3]);
          var element7 = dom.childAt(element2, [3, 1]);
          var element8 = dom.childAt(element0, [5, 3]);
          var morphs = new Array(15);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 2]),1,1);
          morphs[1] = dom.createAttrMorph(element1, 'class');
          morphs[2] = dom.createElementMorph(element1);
          morphs[3] = dom.createAttrMorph(element4, 'class');
          morphs[4] = dom.createMorphAt(element4,3,3);
          morphs[5] = dom.createMorphAt(dom.childAt(element4, [5]),1,1);
          morphs[6] = dom.createAttrMorph(element5, 'class');
          morphs[7] = dom.createMorphAt(element5,1,1);
          morphs[8] = dom.createElementMorph(element6);
          morphs[9] = dom.createAttrMorph(element7, 'class');
          morphs[10] = dom.createMorphAt(element7,3,3);
          morphs[11] = dom.createMorphAt(dom.childAt(element7, [5]),1,1);
          morphs[12] = dom.createAttrMorph(element8, 'class');
          morphs[13] = dom.createAttrMorph(element8, 'disabled');
          morphs[14] = dom.createElementMorph(element8);
          return morphs;
        },
        statements: [
          ["content","model.client.name",["loc",[null,[4,74],[4,95]]]],
          ["attribute","class",["concat",["ui form ",["subexpr","if",[["get","model.sending",["loc",[null,[7,32],[7,45]]]],"loading"],[],["loc",[null,[7,27],[7,57]]]]]]],
          ["element","action",["send"],["on","submit"],["loc",[null,[7,59],[7,88]]]],
          ["attribute","class",["concat",["field ",["subexpr","if",[["get","model.errors.serialNumber",["loc",[null,[10,35],[10,60]]]],"error"],[],["loc",[null,[10,30],[10,70]]]]]]],
          ["inline","input",[],["name","serialNumber","value",["subexpr","@mut",[["get","model.serialNumber",["loc",[null,[12,48],[12,66]]]]],[],[]],"placeholder","Enter serial number"],["loc",[null,[12,14],[12,102]]]],
          ["block","each",[["get","model.errors.serialNumber",["loc",[null,[15,24],[15,49]]]]],[],0,null,["loc",[null,[15,16],[19,25]]]],
          ["attribute","class",["concat",["ui action input ",["subexpr","if",[["get","generating",["loc",[null,[24,47],[24,57]]]],"loading"],[],["loc",[null,[24,42],[24,69]]]]]]],
          ["inline","input",[],["name","seed","value",["subexpr","@mut",[["get","seed",["loc",[null,[25,42],[25,46]]]]],[],[]],"placeholder","Seed"],["loc",[null,[25,16],[25,67]]]],
          ["element","action",["generate"],[],["loc",[null,[26,53],[26,74]]]],
          ["attribute","class",["concat",["field ",["subexpr","if",[["get","model.errors.comments",["loc",[null,[37,35],[37,56]]]],"error"],[],["loc",[null,[37,30],[37,67]]]]]]],
          ["inline","textarea",[],["name","comment","value",["subexpr","@mut",[["get","model.comments",["loc",[null,[40,24],[40,38]]]]],[],[]],"rows",10,"placeholder","Write some comment"],["loc",[null,[39,16],[42,52]]]],
          ["block","each",[["get","model.errors.comments",["loc",[null,[44,26],[44,47]]]]],[],1,null,["loc",[null,[44,18],[48,27]]]],
          ["attribute","class",["concat",["ui primary button ",["subexpr","if",[["get","model.sending",["loc",[null,[59,44],[59,57]]]],"loading"],[],["loc",[null,[59,39],[59,69]]]]]]],
          ["attribute","disabled",["get","disabled",["loc",[null,[59,101],[59,109]]]]],
          ["element","action",["send"],[],["loc",[null,[59,71],[59,89]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    return {
      meta: {
        "revision": "Ember@2.0.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 65,
            "column": 0
          }
        },
        "moduleName": "dashboard/templates/modal/invoice.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","bs-modal",[],["show",true,"closable",false,"closed","closeModal"],0,null,["loc",[null,[1,0],[64,13]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});
define('dashboard/components/duration-field', ['exports', 'ember', 'moment'], function (exports, Ember, moment) {

	'use strict';

	var intervals = [{
		label: 'Seconds',
		value: 's'
	}, {
		label: 'Minutes',
		value: 'm'
	}, {
		label: 'Hours',
		value: 'h'
	}, {
		label: 'Days',
		value: 'd'
	}, {
		label: 'Months',
		value: 'M'
	}];

	/**
	 * Resolve the current duration interval from a Moment duration
	 * instance by parsing it's data
	 * 
	 * @param  {Duration} duration
	 * @return {Object}
	 */
	function findSelectedInterval(duration) {
		if (Ember['default'].isEmpty(duration)) {
			return;
		}

		var filtered = intervals.reduce(function (intervals, interval) {
			var key = interval.label.toLowerCase();
			var count = duration._data[key];

			if (count && count > 0) {
				intervals.addObject({
					count: count,
					interval: interval.value
				});
			}

			return intervals;
		}, Ember['default'].A());

		return Ember['default'].get(filtered, 'firstObject');
	}

	/**
	 * Create a computed property for `interval` and `count`
	 * properties that are extracted from the moment Duration instance.
	 * 
	 * @return {Ember.Computed}
	 */
	function computedIntervalField() {
		return Ember['default'].computed({
			get: function get(key) {
				return this._data[key];
			},
			set: function set(key, value) {
				var oldValue = this._data[key];

				if (oldValue !== value) {
					this._data[key] = value;
				}

				this.updateDuration();
			}
		});
	}

	/**
	 * Component that displays the Moment Duration type as an
	 * Input field.
	 * 
	 * @class DurationFieldComponent
	 * @extends {Ember.Component}
	 */
	exports['default'] = Ember['default'].Component.extend({
		moduleName: 'dropdown',
		classNames: ['ui', 'right', 'labeled', 'input'],

		/**
	  * Moment Duration that should be displayed
	  * 
	  * @type {Duration}
	  * @default null
	  */
		value: null,

		/**
	  * List of available intervals
	  * 
	  * @type {Array}
	  */
		intervals: intervals,

		/**
	  * Extracts the number from the Interval
	  * 
	  * @type {Number}
	  */
		count: computedIntervalField(),

		/**
	  * Extracts the interval string from the Interval
	  * 
	  * @type {String}
	  */
		interval: computedIntervalField(),

		init: function init() {
			this._super();

			var value = this.get('value');
			this._data = findSelectedInterval(value) || {};
		},

		/**
	  * Inititalize the `dropdown` component when the Element
	  * is inserted in the DOM
	  */
		didInsertElement: function didInsertElement() {
			this.$('.ui.dropdown').dropdown({
				onChange: Ember['default'].run.bind(this, function (value) {
					this.set('interval', value);
				})
			});

			Ember['default'].run.scheduleOnce('afterRender', this, function () {
				var interval = this.get('interval');
				this.$('.ui.dropdown').dropdown('set selected', interval);
			});
		},

		/**
	  * Constructs a new Moment Duration instance and if all required
	  * arguments are present.
	  * 
	  * Sends a `duration` action with the Duration argument.
	  */
		updateDuration: function updateDuration() {
			var _Ember$getProperties = Ember['default'].getProperties(this._data, 'interval', 'count');

			var interval = _Ember$getProperties.interval;
			var count = _Ember$getProperties.count;

			if (interval && count) {
				this.sendAction('duration', moment['default'].duration(parseInt(count, 10), interval));
			}
		}
	});

});
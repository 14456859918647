define('dashboard/mixins/operator/model', ['exports', 'ember', 'ember-data'], function (exports, Ember, DS) {

	'use strict';

	exports['default'] = Ember['default'].Mixin.create({
		name: DS['default'].attr('string', {
			validation: {
				required: true,
				max: 255
			}
		}),
		key: DS['default'].attr('string', {
			validation: {
				required: true,
				max: 255
			}
		}),
		currency: DS['default'].attr('string', {
			validation: {
				required: true,
				max: 3,
				min: 2
			}
		}),
		description: DS['default'].attr('string', {
			validation: {
				max: 1024
			}
		}),
		color: DS['default'].attr('string', {
			validation: {
				required: true,
				max: 7,
				min: 7
			}
		})
	});

});
define('dashboard/components/calls-chart', ['exports', 'dashboard/components/line-chart'], function (exports, Chart) {

	'use strict';

	exports['default'] = Chart['default'].extend({
		/**
	  * Dashboard Caption label
	  *
	  * @type {String}
	  */
		caption: 'Calls',

		/**
	  * Label used to define the Series Values in the
	  * dashboard.
	  *
	  * @type {String}
	  */
		seriesLabel: 'Number of calls',

		/**
	  * Define the Type of the ChartComponent
	  *
	  * @type {String}
	  */
		type: 'line',

		/**
	  * Format the Data for the Chart Component
	  *
	  * @param  {Array} data
	  * @return {Object}
	  */
		formatData: function formatData(data) {
			var _this = this;

			var dates = [],
			    total = [],
			    successfull = [];

			data.forEach(function (value) {
				dates.unshift(_this.formatDateEntry(value.date));
				total.unshift(value.total);
				successfull.unshift(value.successfull);
			});

			return {
				dates: dates, values: {
					total: total, successfull: successfull
				}
			};
		},

		renderChartData: function renderChartData(chart, dates, values) {
			chart.setLabels(dates);
			chart.addSeries('total', 'Total calls', values.total, {
				seriesDisplayType: this.get('type')
			});
			chart.addSeries('successfull', 'Successfull calls', values.successfull, {
				seriesDisplayType: this.get('type')
			});
			if (chart.isLocked()) {
				chart.unlock();
			}
		}
	});

});
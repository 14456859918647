define('dashboard/helpers/price-color', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Helper.helper(function (params) {
		var offerPrice = params[0];

		var sellingPrice = params[1];

		if (offerPrice > sellingPrice) {
			return Ember['default'].String.htmlSafe("<span style=\"color:red;\">" + offerPrice + "</span>");
		} else {
			return Ember['default'].String.htmlSafe("<span>" + offerPrice + "</span>");
		}
	});

});
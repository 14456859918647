define('dashboard/routes/trading', ['exports', 'ember', 'dashboard/mixins/authenticated-route'], function (exports, Ember, AuthenticatedRoute) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRoute['default'], {
		queryParams: {
			key: {
				refreshModel: false
			},
			destination: {
				refreshModel: false
			}
		}
	});

});
define('dashboard/mixins/authenticated-route', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Mixin.create({
		auth: Ember['default'].inject.service(),

		/**
	  * Name of the Login route where to redirect the user when he is
	  * not authenticated.
	  *
	  * @type {String}
	  * @default login
	  */
		loginRoute: 'login',

		beforeModel: function beforeModel(transition) {
			var route = this,
			    promise = this._super(transition);

			if (promise && promise.then) {
				return promise.then(function () {
					return route.authenticate(transition);
				});
			}

			return route.authenticate(transition);
		},

		/**
	  * This method implements the enforcement of the session being authenticated.
	  *
	  * If the session is not authenticated, the current transition will be aborted
	  * and user will be redirected to the Login page.
	  *
	  * The current transition is saved, so we could redirect the user to the
	  * initial route when he is logged in.
	  *
	  * @method authenticate
	  * @param {Transition} transition The transition that lead to this route
	  */
		authenticate: function authenticate(transition) {
			var isAuthenticated = this.get('auth.isAuthenticated'),
			    path = this.get('loginRoute');

			if (isAuthenticated) {
				return Ember['default'].RSVP.resolve();
			} else {
				Ember['default'].Logger.debug('Aborting current transition, redirecting user to Route `%s`', path);

				transition.abort();
				this.set('auth.attemptedTransition', transition);
				return this.transitionTo(path);
			}
		}
	});

});
define('dashboard/transforms/duration', ['exports', 'ember', 'ember-data', 'moment'], function (exports, Ember, DS, moment) {

	'use strict';

	exports['default'] = DS['default'].Transform.extend({
		deserialize: function deserialize(serialized) {
			if (Ember['default'].isPresent(serialized)) {
				return moment['default'].duration(serialized);
			}

			return null;
		},

		serialize: function serialize(deserialized) {
			if (Ember['default'].isPresent(deserialized)) {
				return deserialized.toString();
			}

			return null;
		}
	});

});
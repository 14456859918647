define('dashboard/mixins/reports/adapter', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Mixin.create({
		namespace: 'api/reports',
		typePath: null,

		pathForType: function pathForType() {
			var typePath = this.get('typePath');

			Ember['default'].assert('You must set the `typePath` property on this Adapter.', Ember['default'].typeOf(typePath) === 'string');

			return typePath;
		}
	});

});
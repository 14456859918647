define('dashboard/templates/operators/form', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.2",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 6
              },
              "end": {
                "line": 20,
                "column": 6
              }
            },
            "moduleName": "dashboard/templates/operators/form.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("							");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","ui basic red pointing prompt label transition visible");
            var el2 = dom.createTextNode("\n								");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n							");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["content","error.message",["loc",[null,[18,8],[18,25]]]]
          ],
          locals: ["error"],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.2",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 7
              },
              "end": {
                "line": 32,
                "column": 7
              }
            },
            "moduleName": "dashboard/templates/operators/form.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("								");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","ui basic red pointing prompt label transition visible");
            var el2 = dom.createTextNode("\n									");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n								");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["content","error.message",["loc",[null,[30,9],[30,26]]]]
          ],
          locals: ["error"],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.2",
            "loc": {
              "source": null,
              "start": {
                "line": 37,
                "column": 6
              },
              "end": {
                "line": 43,
                "column": 6
              }
            },
            "moduleName": "dashboard/templates/operators/form.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("								");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","item");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element10 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element10, 'data-value');
            morphs[1] = dom.createMorphAt(element10,0,0);
            return morphs;
          },
          statements: [
            ["attribute","data-value",["concat",[["get","currency",["loc",[null,[42,40],[42,48]]]]]]],
            ["content","currency",["loc",[null,[42,52],[42,64]]]]
          ],
          locals: ["currency"],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.2",
            "loc": {
              "source": null,
              "start": {
                "line": 45,
                "column": 7
              },
              "end": {
                "line": 49,
                "column": 7
              }
            },
            "moduleName": "dashboard/templates/operators/form.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("								");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","ui basic red pointing prompt label transition visible");
            var el2 = dom.createTextNode("\n									");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n								");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["content","error.message",["loc",[null,[47,9],[47,26]]]]
          ],
          locals: ["error"],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.2",
            "loc": {
              "source": null,
              "start": {
                "line": 56,
                "column": 7
              },
              "end": {
                "line": 60,
                "column": 7
              }
            },
            "moduleName": "dashboard/templates/operators/form.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("								");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","ui basic red pointing prompt label transition visible");
            var el2 = dom.createTextNode("\n									");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n								");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["content","error.message",["loc",[null,[58,9],[58,26]]]]
          ],
          locals: ["error"],
          templates: []
        };
      }());
      var child5 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.2",
            "loc": {
              "source": null,
              "start": {
                "line": 69,
                "column": 7
              },
              "end": {
                "line": 73,
                "column": 7
              }
            },
            "moduleName": "dashboard/templates/operators/form.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("								");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","ui basic red pointing prompt label transition visible");
            var el2 = dom.createTextNode("\n									");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n								");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["content","error.message",["loc",[null,[71,9],[71,26]]]]
          ],
          locals: ["error"],
          templates: []
        };
      }());
      var child6 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.2",
            "loc": {
              "source": null,
              "start": {
                "line": 80,
                "column": 7
              },
              "end": {
                "line": 84,
                "column": 7
              }
            },
            "moduleName": "dashboard/templates/operators/form.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("								");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","ui basic red pointing prompt label transition visible");
            var el2 = dom.createTextNode("\n									");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n								");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["content","error.message",["loc",[null,[82,9],[82,26]]]]
          ],
          locals: ["error"],
          templates: []
        };
      }());
      var child7 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@2.0.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 94,
                  "column": 8
                },
                "end": {
                  "line": 98,
                  "column": 8
                }
              },
              "moduleName": "dashboard/templates/operators/form.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("									");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1,"class","ui basic red pointing prompt label transition visible");
              var el2 = dom.createTextNode("\n										");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n									");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["content","error.message",["loc",[null,[96,10],[96,27]]]]
            ],
            locals: ["error"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@2.0.2",
            "loc": {
              "source": null,
              "start": {
                "line": 89,
                "column": 5
              },
              "end": {
                "line": 101,
                "column": 5
              }
            },
            "moduleName": "dashboard/templates/operators/form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("						");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n							");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2,"for","cbc");
            var el3 = dom.createTextNode("Is CBC client");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n							");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n							");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("							");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n						");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element9 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element9, 'class');
            morphs[1] = dom.createMorphAt(element9,3,3);
            morphs[2] = dom.createMorphAt(dom.childAt(element9, [5]),1,1);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["field four wide ",["subexpr","if",[["get","model.errors.cbc",["loc",[null,[90,39],[90,55]]]],"error"],[],["loc",[null,[90,34],[90,66]]]]]]],
            ["inline","ui-checkbox",[],["isChecked",["subexpr","@mut",[["get","model.cbc",["loc",[null,[92,31],[92,40]]]]],[],[]],"class","toggle"],["loc",[null,[92,7],[92,57]]]],
            ["block","each",[["get","model.errors.cbc",["loc",[null,[94,16],[94,32]]]]],[],0,null,["loc",[null,[94,8],[98,17]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child8 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@2.0.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 107,
                  "column": 8
                },
                "end": {
                  "line": 111,
                  "column": 8
                }
              },
              "moduleName": "dashboard/templates/operators/form.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("									");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1,"class","ui basic red pointing prompt label transition visible");
              var el2 = dom.createTextNode("\n										");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n									");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["content","error.message",["loc",[null,[109,10],[109,27]]]]
            ],
            locals: ["error"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@2.0.2",
            "loc": {
              "source": null,
              "start": {
                "line": 102,
                "column": 5
              },
              "end": {
                "line": 115,
                "column": 5
              }
            },
            "moduleName": "dashboard/templates/operators/form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("						");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n							");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2,"for","enable-invoice");
            var el3 = dom.createTextNode("Is Invoicing enabled");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n							");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n							");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("							");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n							");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            dom.setAttribute(el2,"class","help-text");
            var el3 = dom.createTextNode("Enable or disable invoicing");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n						");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element8, 'class');
            morphs[1] = dom.createMorphAt(element8,3,3);
            morphs[2] = dom.createMorphAt(dom.childAt(element8, [5]),1,1);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["field four wide ",["subexpr","if",[["get","model.errors.invoicingEnabled",["loc",[null,[103,39],[103,68]]]],"error"],[],["loc",[null,[103,34],[103,79]]]]]]],
            ["inline","ui-checkbox",[],["id","enable-invoice","isChecked",["subexpr","@mut",[["get","model.invoicingEnabled",["loc",[null,[105,51],[105,73]]]]],[],[]],"class","toggle"],["loc",[null,[105,7],[105,90]]]],
            ["block","each",[["get","model.errors.invoicingEnabled",["loc",[null,[107,16],[107,45]]]]],[],0,null,["loc",[null,[107,8],[111,17]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child9 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.2",
            "loc": {
              "source": null,
              "start": {
                "line": 116,
                "column": 5
              },
              "end": {
                "line": 118,
                "column": 5
              }
            },
            "moduleName": "dashboard/templates/operators/form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("						");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","invoice-cycle-field",[],["class","eight wide","cycle",["subexpr","@mut",[["get","model.cycle",["loc",[null,[117,53],[117,64]]]]],[],[]],"errors",["subexpr","@mut",[["get","model.errors.cycle",["loc",[null,[117,72],[117,90]]]]],[],[]]],["loc",[null,[117,6],[117,92]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child10 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@2.0.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 127,
                  "column": 8
                },
                "end": {
                  "line": 131,
                  "column": 8
                }
              },
              "moduleName": "dashboard/templates/operators/form.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("									");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1,"class","ui basic red pointing prompt label transition visible");
              var el2 = dom.createTextNode("\n										");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n									");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["content","error.message",["loc",[null,[129,10],[129,27]]]]
            ],
            locals: ["error"],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@2.0.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 139,
                  "column": 7
                },
                "end": {
                  "line": 145,
                  "column": 7
                }
              },
              "moduleName": "dashboard/templates/operators/form.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("									");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","item");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element0, 'data-value');
              morphs[1] = dom.createMorphAt(element0,0,0);
              return morphs;
            },
            statements: [
              ["attribute","data-value",["concat",[["get","locale.value",["loc",[null,[144,41],[144,53]]]]]]],
              ["content","locale.label",["loc",[null,[144,57],[144,73]]]]
            ],
            locals: ["locale"],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@2.0.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 147,
                  "column": 8
                },
                "end": {
                  "line": 151,
                  "column": 8
                }
              },
              "moduleName": "dashboard/templates/operators/form.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("									");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1,"class","ui basic red pointing prompt label transition visible");
              var el2 = dom.createTextNode("\n										");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n									");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["content","error.message",["loc",[null,[149,10],[149,27]]]]
            ],
            locals: ["error"],
            templates: []
          };
        }());
        var child3 = (function() {
          return {
            meta: {
              "revision": "Ember@2.0.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 163,
                  "column": 8
                },
                "end": {
                  "line": 167,
                  "column": 8
                }
              },
              "moduleName": "dashboard/templates/operators/form.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("									");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1,"class","ui basic red pointing prompt label transition visible");
              var el2 = dom.createTextNode("\n										");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n									");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["content","error.message",["loc",[null,[165,10],[165,27]]]]
            ],
            locals: ["error"],
            templates: []
          };
        }());
        var child4 = (function() {
          return {
            meta: {
              "revision": "Ember@2.0.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 179,
                  "column": 8
                },
                "end": {
                  "line": 183,
                  "column": 8
                }
              },
              "moduleName": "dashboard/templates/operators/form.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("									");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1,"class","ui basic red pointing prompt label transition visible");
              var el2 = dom.createTextNode("\n										");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n									");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["content","error.message",["loc",[null,[181,10],[181,27]]]]
            ],
            locals: ["error"],
            templates: []
          };
        }());
        var child5 = (function() {
          return {
            meta: {
              "revision": "Ember@2.0.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 196,
                  "column": 8
                },
                "end": {
                  "line": 200,
                  "column": 8
                }
              },
              "moduleName": "dashboard/templates/operators/form.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("									");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1,"class","ui basic red pointing prompt label transition visible");
              var el2 = dom.createTextNode("\n										");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n									");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["content","error.message",["loc",[null,[198,10],[198,27]]]]
            ],
            locals: ["error"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@2.0.2",
            "loc": {
              "source": null,
              "start": {
                "line": 121,
                "column": 4
              },
              "end": {
                "line": 207,
                "column": 4
              }
            },
            "moduleName": "dashboard/templates/operators/form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("					");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","two fields");
            var el2 = dom.createTextNode("\n						");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n							");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            dom.setAttribute(el3,"for","email");
            var el4 = dom.createTextNode("E-Mail adresses (comma separated)");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n							");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n							");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("							");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n							");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("p");
            dom.setAttribute(el3,"class","help-text");
            var el4 = dom.createTextNode("\n								Email address of the client where we should send Invoices\n							");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n						");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n						");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n							");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            dom.setAttribute(el3,"for","language");
            var el4 = dom.createTextNode("Language");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("							");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("							");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n							");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("p");
            dom.setAttribute(el3,"class","help-text");
            var el4 = dom.createTextNode("\n								Language used when creating Invoices for this Client\n							");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n						");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n					");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n					");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","two fields");
            var el2 = dom.createTextNode("\n						");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n							");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            dom.setAttribute(el3,"for","");
            var el4 = dom.createTextNode("Payment Due Period");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n							");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n							");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("							");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n							");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("p");
            dom.setAttribute(el3,"class","help-text");
            var el4 = dom.createTextNode("\n								due date: payment due period + issue date \n							");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n						");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n					");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n					");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","two fields");
            var el2 = dom.createTextNode("\n						");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n							");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            dom.setAttribute(el3,"for","tax");
            var el4 = dom.createTextNode("Tax ammount");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n							");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n							");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("							");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n							");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("p");
            dom.setAttribute(el3,"class","help-text");
            var el4 = dom.createTextNode("\n								Leave this filed blank is there is no Tax to be calculated on the invoice\n							");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n						");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n						");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n							");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            dom.setAttribute(el3,"for","address");
            var el4 = dom.createTextNode("Invoice address");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n							");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n							");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("							");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n							");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("p");
            dom.setAttribute(el3,"class","help-text");
            var el4 = dom.createTextNode("\n								Address of the client that should be printed on the Invoice\n							");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n						");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n					");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var element2 = dom.childAt(element1, [1]);
            var element3 = dom.childAt(element1, [3]);
            var element4 = dom.childAt(fragment, [3, 1]);
            var element5 = dom.childAt(fragment, [5]);
            var element6 = dom.childAt(element5, [1]);
            var element7 = dom.childAt(element5, [3]);
            var morphs = new Array(15);
            morphs[0] = dom.createAttrMorph(element2, 'class');
            morphs[1] = dom.createMorphAt(element2,3,3);
            morphs[2] = dom.createMorphAt(dom.childAt(element2, [5]),1,1);
            morphs[3] = dom.createAttrMorph(element3, 'class');
            morphs[4] = dom.createMorphAt(element3,3,3);
            morphs[5] = dom.createMorphAt(dom.childAt(element3, [5]),1,1);
            morphs[6] = dom.createAttrMorph(element4, 'class');
            morphs[7] = dom.createMorphAt(element4,3,3);
            morphs[8] = dom.createMorphAt(dom.childAt(element4, [5]),1,1);
            morphs[9] = dom.createAttrMorph(element6, 'class');
            morphs[10] = dom.createMorphAt(element6,3,3);
            morphs[11] = dom.createMorphAt(dom.childAt(element6, [5]),1,1);
            morphs[12] = dom.createAttrMorph(element7, 'class');
            morphs[13] = dom.createMorphAt(element7,3,3);
            morphs[14] = dom.createMorphAt(dom.childAt(element7, [5]),1,1);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["field ",["subexpr","if",[["get","model.errors.email",["loc",[null,[123,29],[123,47]]]],"error"],[],["loc",[null,[123,24],[123,58]]]]]]],
            ["inline","input",[],["name","email","value",["subexpr","@mut",[["get","model.email",["loc",[null,[125,34],[125,45]]]]],[],[]]],["loc",[null,[125,7],[125,47]]]],
            ["block","each",[["get","model.errors.email",["loc",[null,[127,16],[127,34]]]]],[],0,null,["loc",[null,[127,8],[131,17]]]],
            ["attribute","class",["concat",["field ",["subexpr","if",[["get","model.errors.language",["loc",[null,[137,29],[137,50]]]],"error"],[],["loc",[null,[137,24],[137,61]]]]]]],
            ["block","select-box",[],["values",["subexpr","@mut",[["get","locales",["loc",[null,[140,15],[140,22]]]]],[],[]],"selected",["subexpr","@mut",[["get","model.language",["loc",[null,[141,17],[141,31]]]]],[],[]],"search",false,"multiple",false],1,null,["loc",[null,[139,7],[145,22]]]],
            ["block","each",[["get","model.errors.language",["loc",[null,[147,16],[147,37]]]]],[],2,null,["loc",[null,[147,8],[151,17]]]],
            ["attribute","class",["concat",["field ",["subexpr","if",[["get","model.errors.paymentDuePeriod",["loc",[null,[159,29],[159,58]]]],"error"],[],["loc",[null,[159,24],[159,69]]]]]]],
            ["inline","input",[],["name","paymentDuePeriod","type","text","value",["subexpr","@mut",[["get","model.paymentDuePeriod",["loc",[null,[161,57],[161,79]]]]],[],[]],"placeholder","15"],["loc",[null,[161,7],[161,98]]]],
            ["block","each",[["get","model.errors.paymentDuePeriod",["loc",[null,[163,16],[163,45]]]]],[],3,null,["loc",[null,[163,8],[167,17]]]],
            ["attribute","class",["concat",["field ",["subexpr","if",[["get","model.errors.tax",["loc",[null,[175,29],[175,45]]]],"error"],[],["loc",[null,[175,24],[175,56]]]]]]],
            ["inline","input",[],["name","tax","type","text","value",["subexpr","@mut",[["get","model.tax",["loc",[null,[177,44],[177,53]]]]],[],[]],"placeholder","Eg. 19.00%"],["loc",[null,[177,7],[177,80]]]],
            ["block","each",[["get","model.errors.tax",["loc",[null,[179,16],[179,32]]]]],[],4,null,["loc",[null,[179,8],[183,17]]]],
            ["attribute","class",["concat",["field ",["subexpr","if",[["get","model.errors.address",["loc",[null,[189,29],[189,49]]]],"error"],[],["loc",[null,[189,24],[189,60]]]]]]],
            ["inline","textarea",[],["name","address","value",["subexpr","@mut",[["get","model.address",["loc",[null,[192,14],[192,27]]]]],[],[]],"rows",10,"placeholder","Client address"],["loc",[null,[191,7],[194,38]]]],
            ["block","each",[["get","model.errors.address",["loc",[null,[196,16],[196,36]]]]],[],5,null,["loc",[null,[196,8],[200,17]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5]
        };
      }());
      var child11 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.2",
            "loc": {
              "source": null,
              "start": {
                "line": 213,
                "column": 6
              },
              "end": {
                "line": 217,
                "column": 6
              }
            },
            "moduleName": "dashboard/templates/operators/form.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("							");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","ui basic red pointing prompt label transition visible");
            var el2 = dom.createTextNode("\n								");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n							");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["content","error.message",["loc",[null,[215,8],[215,25]]]]
          ],
          locals: ["error"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@2.0.2",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 236,
              "column": 0
            }
          },
          "moduleName": "dashboard/templates/operators/form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","ui modal small");
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","ui header");
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","ui content");
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("form");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("				");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          var el5 = dom.createTextNode("\n					");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"for","name");
          var el6 = dom.createTextNode("Name");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n					");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n					");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("					");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n				");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n				");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","three fields");
          var el5 = dom.createTextNode("\n					");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n						");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6,"for","key");
          var el7 = dom.createTextNode("Key code");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n						");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n						");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("						");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n					");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n					");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n						");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6,"for","currency");
          var el7 = dom.createTextNode("Currency");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("						");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("						");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n					");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n					");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n						");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6,"for","color");
          var el7 = dom.createTextNode("Color");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n						");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n						");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("						");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n					");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n				");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n				");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","three fields");
          var el5 = dom.createTextNode("\n					");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n						");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6,"for","productType");
          var el7 = dom.createTextNode("Product Type");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n						");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n						");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("						");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n					");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n					");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n						");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6,"for","customerId");
          var el7 = dom.createTextNode("Customer ID");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n						");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n						");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("						");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n					");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n				");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n				");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","three fields");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("				");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n				");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          var el5 = dom.createTextNode("\n					");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"for","description");
          var el6 = dom.createTextNode("Description");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n					");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n					");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("					");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n				");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n				");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n				");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","actions");
          var el5 = dom.createTextNode("\n					");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("button");
          dom.setAttribute(el5,"type","submit");
          dom.setAttribute(el5,"class","ui button labeled icon blue");
          var el6 = dom.createTextNode("\n						");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("i");
          dom.setAttribute(el6,"class","save icon");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n						Save\n					");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n					");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","ui button labeled icon red deny right floated");
          var el6 = dom.createTextNode("\n						");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("i");
          dom.setAttribute(el6,"class","close icon");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n						Cancel\n					");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n				");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n			");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element11 = dom.childAt(fragment, [1]);
          var element12 = dom.childAt(element11, [3, 1]);
          var element13 = dom.childAt(element12, [2]);
          var element14 = dom.childAt(element12, [4]);
          var element15 = dom.childAt(element14, [1]);
          var element16 = dom.childAt(element14, [3]);
          var element17 = dom.childAt(element14, [5]);
          var element18 = dom.childAt(element12, [6]);
          var element19 = dom.childAt(element18, [1]);
          var element20 = dom.childAt(element18, [3]);
          var element21 = dom.childAt(element12, [8]);
          var element22 = dom.childAt(element12, [12]);
          var morphs = new Array(29);
          morphs[0] = dom.createMorphAt(dom.childAt(element11, [1]),1,1);
          morphs[1] = dom.createAttrMorph(element12, 'class');
          morphs[2] = dom.createElementMorph(element12);
          morphs[3] = dom.createAttrMorph(element13, 'class');
          morphs[4] = dom.createMorphAt(element13,3,3);
          morphs[5] = dom.createMorphAt(dom.childAt(element13, [5]),1,1);
          morphs[6] = dom.createAttrMorph(element15, 'class');
          morphs[7] = dom.createMorphAt(element15,3,3);
          morphs[8] = dom.createMorphAt(dom.childAt(element15, [5]),1,1);
          morphs[9] = dom.createAttrMorph(element16, 'class');
          morphs[10] = dom.createMorphAt(element16,3,3);
          morphs[11] = dom.createMorphAt(dom.childAt(element16, [5]),1,1);
          morphs[12] = dom.createAttrMorph(element17, 'class');
          morphs[13] = dom.createMorphAt(element17,3,3);
          morphs[14] = dom.createMorphAt(dom.childAt(element17, [5]),1,1);
          morphs[15] = dom.createAttrMorph(element19, 'class');
          morphs[16] = dom.createMorphAt(element19,3,3);
          morphs[17] = dom.createMorphAt(dom.childAt(element19, [5]),1,1);
          morphs[18] = dom.createAttrMorph(element20, 'class');
          morphs[19] = dom.createMorphAt(element20,3,3);
          morphs[20] = dom.createMorphAt(dom.childAt(element20, [5]),1,1);
          morphs[21] = dom.createMorphAt(element21,1,1);
          morphs[22] = dom.createMorphAt(element21,2,2);
          morphs[23] = dom.createMorphAt(element21,3,3);
          morphs[24] = dom.createMorphAt(element12,10,10);
          morphs[25] = dom.createAttrMorph(element22, 'class');
          morphs[26] = dom.createMorphAt(element22,3,3);
          morphs[27] = dom.createMorphAt(dom.childAt(element22, [5]),1,1);
          morphs[28] = dom.createMorphAt(element12,14,14);
          return morphs;
        },
        statements: [
          ["content","title",["loc",[null,[4,3],[4,12]]]],
          ["attribute","class",["concat",["ui form ",["subexpr","if",[["get","model.isSaving",["loc",[null,[7,29],[7,43]]]],"loading"],[],["loc",[null,[7,24],[7,55]]]]]]],
          ["element","action",["save",["get","model",["loc",[null,[7,73],[7,78]]]]],["on","submit"],["loc",[null,[7,57],[7,92]]]],
          ["attribute","class",["concat",["field ",["subexpr","if",[["get","model.errors.name",["loc",[null,[12,27],[12,44]]]],"error"],[],["loc",[null,[12,22],[12,55]]]]]]],
          ["inline","input",[],["name","name","value",["subexpr","@mut",[["get","model.name",["loc",[null,[14,31],[14,41]]]]],[],[]],"placeholder","Name"],["loc",[null,[14,5],[14,62]]]],
          ["block","each",[["get","model.errors.name",["loc",[null,[16,14],[16,31]]]]],[],0,null,["loc",[null,[16,6],[20,15]]]],
          ["attribute","class",["concat",["field five wide  ",["subexpr","if",[["get","model.errors.key",["loc",[null,[24,39],[24,55]]]],"error"],[],["loc",[null,[24,34],[24,66]]]]]]],
          ["inline","input",[],["name","key","value",["subexpr","@mut",[["get","model.key",["loc",[null,[26,31],[26,40]]]]],[],[]],"placeholder","Key code"],["loc",[null,[26,6],[26,65]]]],
          ["block","each",[["get","model.errors.key",["loc",[null,[28,15],[28,31]]]]],[],1,null,["loc",[null,[28,7],[32,16]]]],
          ["attribute","class",["concat",["field five wide  ",["subexpr","if",[["get","model.errors.currency",["loc",[null,[35,39],[35,60]]]],"error"],[],["loc",[null,[35,34],[35,71]]]]]]],
          ["block","select-box",[],["values",["subexpr","@mut",[["get","currencies",["loc",[null,[38,14],[38,24]]]]],[],[]],"selected",["subexpr","@mut",[["get","model.currency",["loc",[null,[39,16],[39,30]]]]],[],[]],"search",false,"multiple",false],2,null,["loc",[null,[37,6],[43,21]]]],
          ["block","each",[["get","model.errors.currency",["loc",[null,[45,15],[45,36]]]]],[],3,null,["loc",[null,[45,7],[49,16]]]],
          ["attribute","class",["concat",["field six wide  ",["subexpr","if",[["get","model.errors.color",["loc",[null,[52,38],[52,56]]]],"error"],[],["loc",[null,[52,33],[52,67]]]]]]],
          ["inline","input",[],["name","color","type","color","value",["subexpr","@mut",[["get","model.color",["loc",[null,[54,46],[54,57]]]]],[],[]]],["loc",[null,[54,6],[54,59]]]],
          ["block","each",[["get","model.errors.color",["loc",[null,[56,15],[56,33]]]]],[],4,null,["loc",[null,[56,7],[60,16]]]],
          ["attribute","class",["concat",["field ",["subexpr","if",[["get","model.errors.productType",["loc",[null,[65,28],[65,52]]]],"error"],[],["loc",[null,[65,23],[65,63]]]]]]],
          ["inline","input",[],["name","productType","value",["subexpr","@mut",[["get","model.productType",["loc",[null,[67,39],[67,56]]]]],[],[]],"placeholder","Product"],["loc",[null,[67,6],[67,80]]]],
          ["block","each",[["get","model.errors.productType",["loc",[null,[69,15],[69,39]]]]],[],5,null,["loc",[null,[69,7],[73,16]]]],
          ["attribute","class",["concat",["field ",["subexpr","if",[["get","model.errors.customerId",["loc",[null,[76,28],[76,51]]]],"error"],[],["loc",[null,[76,23],[76,62]]]]]]],
          ["inline","input",[],["name","customerId","value",["subexpr","@mut",[["get","model.customerId",["loc",[null,[78,38],[78,54]]]]],[],[]],"placeholder",""],["loc",[null,[78,6],[78,71]]]],
          ["block","each",[["get","model.errors.customerId",["loc",[null,[80,15],[80,38]]]]],[],6,null,["loc",[null,[80,7],[84,16]]]],
          ["block","if",[["get","hasCbcField",["loc",[null,[89,11],[89,22]]]]],[],7,null,["loc",[null,[89,5],[101,12]]]],
          ["block","if",[["get","hasInvoiceField",["loc",[null,[102,11],[102,26]]]]],[],8,null,["loc",[null,[102,5],[115,12]]]],
          ["block","if",[["get","model.invoicingEnabled",["loc",[null,[116,11],[116,33]]]]],[],9,null,["loc",[null,[116,5],[118,12]]]],
          ["block","if",[["get","model.invoicingEnabled",["loc",[null,[121,10],[121,32]]]]],[],10,null,["loc",[null,[121,4],[207,11]]]],
          ["attribute","class",["concat",["field ",["subexpr","if",[["get","model.errors.description",["loc",[null,[209,27],[209,51]]]],"error"],[],["loc",[null,[209,22],[209,62]]]]]]],
          ["inline","textarea",[],["name","description","value",["subexpr","@mut",[["get","model.description",["loc",[null,[211,41],[211,58]]]]],[],[]],"placeholder","Description"],["loc",[null,[211,5],[211,86]]]],
          ["block","each",[["get","model.errors.description",["loc",[null,[213,14],[213,38]]]]],[],11,null,["loc",[null,[213,6],[217,15]]]],
          ["inline","outlet",["error"],[],["loc",[null,[221,4],[221,22]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11]
      };
    }());
    return {
      meta: {
        "revision": "Ember@2.0.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 237,
            "column": 0
          }
        },
        "moduleName": "dashboard/templates/operators/form.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","bs-modal",[],["show",true,"closable",false,"closed","closedModal"],0,null,["loc",[null,[1,0],[236,13]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});
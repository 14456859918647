define('dashboard/tests/unit/mixins/operator-form-route-test', ['ember', 'dashboard/mixins/operator-form-route', 'qunit'], function (Ember, OperatorFormRouteMixin, qunit) {

  'use strict';

  qunit.module('Unit | Mixin | operator form route');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var OperatorFormRouteObject = Ember['default'].Object.extend(OperatorFormRouteMixin['default']);
    var subject = OperatorFormRouteObject.create();
    assert.ok(subject);
  });

});
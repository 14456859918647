define('dashboard/helpers/red-green-color', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Helper.helper(function (params) {
		var price = params[0];

		if (price < 0) {
			return Ember['default'].String.htmlSafe("<b style=\"color:red;\">" + price + "</b>");
		} else {
			return Ember['default'].String.htmlSafe("<b style=\"color:green;\">" + price + "</b>");
		}
	});

});
define('dashboard/components/select-carriers', ['exports', 'ember', 'ember-cli-semantic-ui/mixins/semantic-settings', 'dashboard/mixins/operator/selectable', 'dashboard/mixins/drop-down'], function (exports, Ember, SemanticSettings, SelectableOperatorMixin, DropdownMixin) {

	'use strict';

	exports['default'] = Ember['default'].Component.extend(SemanticSettings['default'], DropdownMixin['default'], SelectableOperatorMixin['default'], {
		placeholder: 'Select Carrier',
		_operatorType: 'carrier',
		operator: Ember['default'].computed.alias('carrier')
	});

});
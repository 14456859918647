define('dashboard/controllers/operators/form', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend({
		/**
	  * Get the List of avaliable Currencies
	  */
		currencies: Ember['default'].A(['EUR', 'USD']),

		/**
	  * Get the List of avaliable locales
	  */
		locales: Ember['default'].A([{
			value: 'de_DE',
			label: 'German'
		}, {
			value: 'us_EN',
			label: 'English'
		}]),

		/**
	  * Title of the Form modal
	  */
		title: Ember['default'].computed('model.isNew', {
			get: function get() {
				var model = this.get('model');
				var isNew = this.get('model.isNew');
				var message = '';

				if (isNew) {
					message = 'Create new ';
				} else {
					message = 'Edit ';
				}

				return message + model.get('className');
			}
		}),

		isClientModel: Ember['default'].computed('model.className', {
			get: function get() {
				var className = this.get('model.className');
				return className === 'Client';
			}
		}),

		hasCbcField: Ember['default'].computed.readOnly('isClientModel'),
		hasInvoiceField: Ember['default'].computed.readOnly('isClientModel')
	});

});
define('dashboard/templates/job/form', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.2",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 6
            },
            "end": {
              "line": 17,
              "column": 6
            }
          },
          "moduleName": "dashboard/templates/job/form.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("							");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1,"class","ui basic red pointing prompt label transition visible");
          var el2 = dom.createTextNode("\n								");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n							");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["content","error.message",["loc",[null,[15,8],[15,25]]]]
        ],
        locals: ["error"],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.2",
          "loc": {
            "source": null,
            "start": {
              "line": 26,
              "column": 6
            },
            "end": {
              "line": 30,
              "column": 6
            }
          },
          "moduleName": "dashboard/templates/job/form.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("							");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1,"class","ui basic red pointing prompt label transition visible");
          var el2 = dom.createTextNode("\n								");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n							");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["content","error.message",["loc",[null,[28,8],[28,25]]]]
        ],
        locals: ["error"],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.2",
          "loc": {
            "source": null,
            "start": {
              "line": 37,
              "column": 6
            },
            "end": {
              "line": 46,
              "column": 6
            }
          },
          "moduleName": "dashboard/templates/job/form.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("							");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","item");
          var el2 = dom.createTextNode("\n								");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n							");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element3, 'data-value');
          morphs[1] = dom.createElementMorph(element3);
          morphs[2] = dom.createMorphAt(element3,1,1);
          return morphs;
        },
        statements: [
          ["attribute","data-value",["get","option.id",["loc",[null,[43,38],[43,47]]]]],
          ["element","action",["alert",["get","option",["loc",[null,[43,67],[43,73]]]]],[],["loc",[null,[43,50],[43,75]]]],
          ["content","option.name",["loc",[null,[44,8],[44,23]]]]
        ],
        locals: ["option"],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.2",
          "loc": {
            "source": null,
            "start": {
              "line": 48,
              "column": 7
            },
            "end": {
              "line": 52,
              "column": 7
            }
          },
          "moduleName": "dashboard/templates/job/form.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("								");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1,"class","ui basic red pointing prompt label transition visible");
          var el2 = dom.createTextNode("\n									");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n								");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["content","error.message",["loc",[null,[50,9],[50,26]]]]
        ],
        locals: ["error"],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.2",
          "loc": {
            "source": null,
            "start": {
              "line": 57,
              "column": 6
            },
            "end": {
              "line": 66,
              "column": 6
            }
          },
          "moduleName": "dashboard/templates/job/form.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("							");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","item");
          var el2 = dom.createTextNode("\n								");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n							");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element2, 'data-value');
          morphs[1] = dom.createElementMorph(element2);
          morphs[2] = dom.createMorphAt(element2,1,1);
          return morphs;
        },
        statements: [
          ["attribute","data-value",["get","option.id",["loc",[null,[63,38],[63,47]]]]],
          ["element","action",["query",["get","option",["loc",[null,[63,67],[63,73]]]]],[],["loc",[null,[63,50],[63,75]]]],
          ["content","option.name",["loc",[null,[64,8],[64,23]]]]
        ],
        locals: ["option"],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.2",
          "loc": {
            "source": null,
            "start": {
              "line": 68,
              "column": 7
            },
            "end": {
              "line": 72,
              "column": 7
            }
          },
          "moduleName": "dashboard/templates/job/form.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("								");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1,"class","ui basic red pointing prompt label transition visible");
          var el2 = dom.createTextNode("\n									");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n								");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["content","error.message",["loc",[null,[70,9],[70,26]]]]
        ],
        locals: ["error"],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.2",
          "loc": {
            "source": null,
            "start": {
              "line": 79,
              "column": 7
            },
            "end": {
              "line": 83,
              "column": 7
            }
          },
          "moduleName": "dashboard/templates/job/form.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("								");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1,"class","ui basic red pointing prompt label transition visible");
          var el2 = dom.createTextNode("\n									");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n								");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["content","error.message",["loc",[null,[81,9],[81,26]]]]
        ],
        locals: ["error"],
        templates: []
      };
    }());
    var child7 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.2",
            "loc": {
              "source": null,
              "start": {
                "line": 124,
                "column": 7
              },
              "end": {
                "line": 124,
                "column": 61
              }
            },
            "moduleName": "dashboard/templates/job/form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","moment-format",[["get","model.failedAt",["loc",[null,[124,45],[124,59]]]]],[],["loc",[null,[124,29],[124,61]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@2.0.2",
          "loc": {
            "source": null,
            "start": {
              "line": 88,
              "column": 4
            },
            "end": {
              "line": 127,
              "column": 4
            }
          },
          "moduleName": "dashboard/templates/job/form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("					");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h5");
          dom.setAttribute(el1,"class","ui dividing header");
          var el2 = dom.createTextNode("Job status");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n					");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","two fields");
          var el2 = dom.createTextNode("\n						");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","field six wide");
          var el3 = dom.createTextNode("\n							");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","lbl");
          var el4 = dom.createTextNode("Inactive ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("i");
          dom.setAttribute(el4,"class","icon remove");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n							");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n							");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","lbl");
          var el4 = dom.createTextNode("Active ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("i");
          dom.setAttribute(el4,"class","icon checkmark");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n							");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          dom.setAttribute(el3,"class","help-text");
          var el4 = dom.createTextNode("\n								Inactive Jobs would not be automatically scheduled\n							");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n						");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n						");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","field six wide");
          var el3 = dom.createTextNode("\n							");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","lbl");
          var el4 = dom.createTextNode("Unlocked ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("i");
          dom.setAttribute(el4,"class","icon lock");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n							");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n							");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","lbl");
          var el4 = dom.createTextNode("Locked ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("i");
          dom.setAttribute(el4,"class","icon unlock");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n							");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          dom.setAttribute(el3,"class","help-text");
          var el4 = dom.createTextNode("\n								Locked Jobs are currently scheduled for execution\n							");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n						");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n					");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n					");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","three fields");
          var el2 = dom.createTextNode("\n						");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","field six wide");
          var el3 = dom.createTextNode("\n							");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          var el4 = dom.createTextNode("Last run occurred at");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n							");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n						");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n						");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","field six wide");
          var el3 = dom.createTextNode("\n							");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          var el4 = dom.createTextNode("Next run is scheduled at");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n							");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n						");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n						");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","field six wide");
          var el3 = dom.createTextNode("\n							");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          var el4 = dom.createTextNode("Last failed at");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n							");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n						");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n					");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [3]);
          var element1 = dom.childAt(fragment, [5]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]),3,3);
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]),3,3);
          morphs[2] = dom.createMorphAt(dom.childAt(element1, [1]),3,3);
          morphs[3] = dom.createMorphAt(dom.childAt(element1, [3]),3,3);
          morphs[4] = dom.createMorphAt(dom.childAt(element1, [5]),3,3);
          return morphs;
        },
        statements: [
          ["inline","ui-checkbox",[],["isChecked",["subexpr","@mut",[["get","model.active",["loc",[null,[94,31],[94,43]]]]],[],[]],"class","toggle"],["loc",[null,[94,7],[94,60]]]],
          ["inline","ui-checkbox",[],["isChecked",["subexpr","@mut",[["get","model.locked",["loc",[null,[103,31],[103,43]]]]],[],[]],"class","toggle"],["loc",[null,[103,7],[103,60]]]],
          ["inline","moment-format",[["get","model.lastRunAt",["loc",[null,[114,23],[114,38]]]]],[],["loc",[null,[114,7],[114,40]]]],
          ["inline","moment-format",[["get","model.nextRunAt",["loc",[null,[119,23],[119,38]]]]],[],["loc",[null,[119,7],[119,40]]]],
          ["block","if",[["get","model.failedAt",["loc",[null,[124,13],[124,27]]]]],[],0,null,["loc",[null,[124,7],[124,68]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child8 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.2",
          "loc": {
            "source": null,
            "start": {
              "line": 142,
              "column": 6
            },
            "end": {
              "line": 146,
              "column": 6
            }
          },
          "moduleName": "dashboard/templates/job/form.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("							");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1,"class","ui basic red pointing prompt label transition visible");
          var el2 = dom.createTextNode("\n								");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n							");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["content","error.message",["loc",[null,[144,8],[144,25]]]]
        ],
        locals: ["error"],
        templates: []
      };
    }());
    var child9 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.2",
          "loc": {
            "source": null,
            "start": {
              "line": 157,
              "column": 5
            },
            "end": {
              "line": 159,
              "column": 5
            }
          },
          "moduleName": "dashboard/templates/job/form.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("						");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","query-parameter",[],["parameter",["subexpr","@mut",[["get","parameter",["loc",[null,[158,34],[158,43]]]]],[],[]],"remove","remove-parameter"],["loc",[null,[158,6],[158,71]]]]
        ],
        locals: ["parameter"],
        templates: []
      };
    }());
    var child10 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.2",
          "loc": {
            "source": null,
            "start": {
              "line": 159,
              "column": 5
            },
            "end": {
              "line": 161,
              "column": 5
            }
          },
          "moduleName": "dashboard/templates/job/form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("						");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createTextNode("No query parameters assigned");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@2.0.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 183,
            "column": 8
          }
        },
        "moduleName": "dashboard/templates/job/form.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("		");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ui header");
        var el2 = dom.createTextNode("\n			");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n		");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n		");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ui content");
        var el2 = dom.createTextNode("\n			");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("form");
        var el3 = dom.createTextNode("\n\n			");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h5");
        dom.setAttribute(el3,"class","ui dividing header");
        var el4 = dom.createTextNode("Job details");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n				");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n					");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","name");
        var el5 = dom.createTextNode("Name");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n					");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n					");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("					");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n				");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n				");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n					");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","description");
        var el5 = dom.createTextNode("Description");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n					");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n					");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("					");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n				");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n				");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","three fields");
        var el4 = dom.createTextNode("\n					");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        var el5 = dom.createTextNode("\n						");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"for","name");
        var el6 = dom.createTextNode("Alert template");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("						");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("						");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n					");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n					");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        var el5 = dom.createTextNode("\n						");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"for","name");
        var el6 = dom.createTextNode("Query template");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("						");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("						");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n					");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n					");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        var el5 = dom.createTextNode("\n						");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"for","name");
        var el6 = dom.createTextNode("Job Interval");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n						");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n						");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("						");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n					");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n				");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n				");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h5");
        dom.setAttribute(el3,"class","ui dividing header");
        var el4 = dom.createTextNode("Job condition script");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n				");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        dom.setAttribute(el3,"class","help-text");
        var el4 = dom.createTextNode("\n					Write a condition script that should be executed for each row fetched from the database. ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("br");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n					Fetched row is represented as ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("b");
        var el5 = dom.createTextNode("data");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" property. ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("br");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n					Use ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("b");
        var el5 = dom.createTextNode("emitter");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" to send notifications. ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("br");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n					Use ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("b");
        var el5 = dom.createTextNode("logger");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" to log your actions.\n				");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n				");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n					");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n					");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("					");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n				");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n				");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h5");
        dom.setAttribute(el3,"class","ui dividing header");
        var el4 = dom.createTextNode("Query parameters");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n				");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        dom.setAttribute(el3,"class","help-text");
        var el4 = dom.createTextNode("\n					Assign a key value pair that would be replaced by the JPA when running your SQL template\n				");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n				");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ui segment");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("				");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n				");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ui blue button labeled icon");
        var el4 = dom.createTextNode("\n					");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("i");
        dom.setAttribute(el4,"class","plus icon");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" Add\n				");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n				");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ui hidden divider");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n				");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n				");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","actions");
        var el4 = dom.createTextNode("\n					");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4,"type","submit");
        dom.setAttribute(el4,"class","ui button labeled icon blue");
        var el5 = dom.createTextNode("\n						");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("i");
        dom.setAttribute(el5,"class","save icon");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n						Save\n					");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n					");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ui button labeled icon red deny right floated");
        var el5 = dom.createTextNode("\n						");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("i");
        dom.setAttribute(el5,"class","close icon");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n						Cancel\n					");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n				");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n			");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n		");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [3, 1]);
        var element5 = dom.childAt(element4, [3]);
        var element6 = dom.childAt(element4, [5]);
        var element7 = dom.childAt(element4, [7]);
        var element8 = dom.childAt(element7, [1]);
        var element9 = dom.childAt(element7, [3]);
        var element10 = dom.childAt(element7, [5]);
        var element11 = dom.childAt(element4, [15]);
        var element12 = dom.childAt(element4, [23]);
        var morphs = new Array(25);
        morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),1,1);
        morphs[1] = dom.createAttrMorph(element4, 'class');
        morphs[2] = dom.createElementMorph(element4);
        morphs[3] = dom.createAttrMorph(element5, 'class');
        morphs[4] = dom.createMorphAt(element5,3,3);
        morphs[5] = dom.createMorphAt(dom.childAt(element5, [5]),1,1);
        morphs[6] = dom.createAttrMorph(element6, 'class');
        morphs[7] = dom.createMorphAt(element6,3,3);
        morphs[8] = dom.createMorphAt(dom.childAt(element6, [5]),1,1);
        morphs[9] = dom.createAttrMorph(element8, 'class');
        morphs[10] = dom.createMorphAt(element8,3,3);
        morphs[11] = dom.createMorphAt(dom.childAt(element8, [5]),1,1);
        morphs[12] = dom.createAttrMorph(element9, 'class');
        morphs[13] = dom.createMorphAt(element9,3,3);
        morphs[14] = dom.createMorphAt(dom.childAt(element9, [5]),1,1);
        morphs[15] = dom.createAttrMorph(element10, 'class');
        morphs[16] = dom.createMorphAt(element10,3,3);
        morphs[17] = dom.createMorphAt(dom.childAt(element10, [5]),1,1);
        morphs[18] = dom.createMorphAt(element4,9,9);
        morphs[19] = dom.createAttrMorph(element11, 'class');
        morphs[20] = dom.createMorphAt(element11,1,1);
        morphs[21] = dom.createMorphAt(dom.childAt(element11, [3]),1,1);
        morphs[22] = dom.createMorphAt(dom.childAt(element4, [21]),1,1);
        morphs[23] = dom.createElementMorph(element12);
        morphs[24] = dom.createMorphAt(element4,27,27);
        return morphs;
      },
      statements: [
        ["content","title",["loc",[null,[2,3],[2,14]]]],
        ["attribute","class",["concat",["ui form ",["subexpr","if",[["get","model.isSaving",["loc",[null,[5,29],[5,43]]]],"loading"],[],["loc",[null,[5,24],[5,55]]]]]]],
        ["element","action",["save",["get","model",["loc",[null,[5,73],[5,78]]]]],["on","submit"],["loc",[null,[5,57],[5,92]]]],
        ["attribute","class",["concat",["field ",["subexpr","if",[["get","model.errors.name",["loc",[null,[9,27],[9,44]]]],"error"],[],["loc",[null,[9,22],[9,55]]]]]]],
        ["inline","input",[],["name","name","value",["subexpr","@mut",[["get","model.name",["loc",[null,[11,31],[11,41]]]]],[],[]],"placeholder","Job name"],["loc",[null,[11,5],[11,66]]]],
        ["block","each",[["get","model.errors.name",["loc",[null,[13,14],[13,31]]]]],[],0,null,["loc",[null,[13,6],[17,15]]]],
        ["attribute","class",["concat",["field ",["subexpr","if",[["get","model.errors.description",["loc",[null,[21,27],[21,51]]]],"error"],[],["loc",[null,[21,22],[21,62]]]]]]],
        ["inline","textarea",[],["name","description","value",["subexpr","@mut",[["get","model.description",["loc",[null,[23,41],[23,58]]]]],[],[]],"placeholder","Job description"],["loc",[null,[23,5],[24,37]]]],
        ["block","each",[["get","model.errors.description",["loc",[null,[26,14],[26,38]]]]],[],1,null,["loc",[null,[26,6],[30,15]]]],
        ["attribute","class",["concat",["field five wide ",["subexpr","if",[["get","model.errors.alert",["loc",[null,[35,38],[35,56]]]],"error"],[],["loc",[null,[35,33],[35,67]]]]]]],
        ["block","select-box",[],["placeholder","Select alert template","values",["subexpr","@mut",[["get","alerts",["loc",[null,[39,14],[39,20]]]]],[],[]],"selected",["subexpr","@mut",[["get","model.template.id",["loc",[null,[40,16],[40,33]]]]],[],[]],"update",false,"search",true],2,null,["loc",[null,[37,6],[46,21]]]],
        ["block","each",[["get","model.errors.alert",["loc",[null,[48,15],[48,33]]]]],[],3,null,["loc",[null,[48,7],[52,16]]]],
        ["attribute","class",["concat",["field five wide ",["subexpr","if",[["get","model.errors.query",["loc",[null,[55,38],[55,56]]]],"error"],[],["loc",[null,[55,33],[55,67]]]]]]],
        ["block","select-box",[],["placeholder","Select query template","values",["subexpr","@mut",[["get","queries",["loc",[null,[59,14],[59,21]]]]],[],[]],"selected",["subexpr","@mut",[["get","model.query.id",["loc",[null,[60,16],[60,30]]]]],[],[]],"update",false,"search",true],4,null,["loc",[null,[57,6],[66,21]]]],
        ["block","each",[["get","model.errors.query",["loc",[null,[68,15],[68,33]]]]],[],5,null,["loc",[null,[68,7],[72,16]]]],
        ["attribute","class",["concat",["field five wide ",["subexpr","if",[["get","model.errors.interval",["loc",[null,[75,38],[75,59]]]],"error"],[],["loc",[null,[75,33],[75,70]]]]]]],
        ["inline","duration-field",[],["value",["subexpr","@mut",[["get","model.interval",["loc",[null,[77,29],[77,43]]]]],[],[]],"duration",["subexpr","action",["duration"],[],["loc",[null,[77,53],[77,72]]]]],["loc",[null,[77,6],[77,74]]]],
        ["block","each",[["get","model.errors.interval",["loc",[null,[79,15],[79,36]]]]],[],6,null,["loc",[null,[79,7],[83,16]]]],
        ["block","unless",[["get","model.isNew",["loc",[null,[88,14],[88,25]]]]],[],7,null,["loc",[null,[88,4],[127,15]]]],
        ["attribute","class",["concat",["field ",["subexpr","if",[["get","model.errors.condition",["loc",[null,[138,27],[138,49]]]],"error"],[],["loc",[null,[138,22],[138,60]]]]]]],
        ["inline","ivy-codemirror",[],["value",["subexpr","@mut",[["get","model.condition",["loc",[null,[139,28],[139,43]]]]],[],[]]],["loc",[null,[139,5],[139,45]]]],
        ["block","each",[["get","model.errors.condition",["loc",[null,[142,14],[142,36]]]]],[],8,null,["loc",[null,[142,6],[146,15]]]],
        ["block","each",[["get","model.parameters",["loc",[null,[157,13],[157,29]]]]],[],9,10,["loc",[null,[157,5],[161,14]]]],
        ["element","action",["add-parameter"],[],["loc",[null,[164,45],[164,71]]]],
        ["inline","outlet",["error"],[],["loc",[null,[170,4],[170,22]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10]
    };
  }()));

});
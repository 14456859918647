define('dashboard/controllers/selling-prices/import', ['exports', 'ember', 'dashboard/mixins/price/import'], function (exports, Ember, OperatorImportController) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend(OperatorImportController['default'], {
		importList: function importList(importService, list) {
			return importService.importSellingPriceList(list);
		}
	});

});
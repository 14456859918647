define('dashboard/models/cbc-price', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	exports['default'] = DS['default'].Model.extend({
		destination: DS['default'].attr('string', {
			validation: {
				required: true
			}
		}),

		destinationDisplayName: DS['default'].attr('string', {
			validation: {
				required: true
			}
		}),

		takt: DS['default'].attr('string', {
			validation: {
				required: true
			}
		}),

		price: DS['default'].attr('number', {
			validation: {
				number: true,
				required: true
			}
		}),

		order: DS['default'].attr('number'),

		validFrom: DS['default'].attr('date'),

		importedAt: DS['default'].attr('date'),

		timeIntervalStart: DS['default'].attr('string'),

		timeIntervalStop: DS['default'].attr('string'),

		updated: DS['default'].attr('boolean'),

		destinations: DS['default'].attr('raw'),

		db1: DS['default'].attr('number'),

		negativeMarginDays: DS['default'].attr('number'),

		billigerTelefonierenId: DS['default'].attr('number'),

		marketPrice: DS['default'].attr('number'),

		competitor: DS['default'].attr('string'),

		client: DS['default'].belongsTo('client')

	});

});
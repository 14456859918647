define('dashboard/templates/components/date-range-picker', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@2.0.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 7,
            "column": 0
          }
        },
        "moduleName": "dashboard/templates/components/date-range-picker.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("label");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","hide");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [4]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
        morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
        morphs[2] = dom.createMorphAt(element0,1,1);
        morphs[3] = dom.createMorphAt(element0,3,3);
        return morphs;
      },
      statements: [
        ["content","context.label",["loc",[null,[1,7],[1,24]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","context.rangeText",["loc",[null,[2,14],[2,31]]]]],[],[]],"class","daterangepicker-input form-control pull-right","placeholder",["subexpr","@mut",[["get","context.placeholder",["loc",[null,[2,98],[2,117]]]]],[],[]]],["loc",[null,[2,0],[2,119]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","context.start",["loc",[null,[4,16],[4,29]]]]],[],[]]],["loc",[null,[4,2],[4,31]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","context.end",["loc",[null,[5,16],[5,27]]]]],[],[]]],["loc",[null,[5,2],[5,29]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});
define('dashboard/routes/cdr', ['exports', 'ember', 'dashboard/config/environment'], function (exports, Ember, ENV) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend({
		queryParams: {
			carrierIn: {
				refreshModel: false,
				replace: false
			},
			carrierOut: {
				refreshModel: false,
				replace: false
			},
			from: {
				refreshModel: false,
				replace: false
			},
			to: {
				refreshModel: false,
				replace: false
			},
			sort: {
				refreshModel: false,
				replace: false
			},
			page: {
				refreshModel: true,
				replace: false
			},
			size: {
				refreshModel: true,
				replace: false
			}
		},
		model: function model(params) {
			this.parseQuery(params);

			return this.store.query('cdr', params);
		},

		parseQuery: function parseQuery(params) {
			var queries = this.get('controller.queries') || [];

			queries.forEach(function (query) {
				query.serialize(params);
			});

			// Spring pagination is Zero based
			if (params.page > 0) {
				params.page = params.page - 1;
			}

			// Save the lastQuery
			this.lastQuery = params;
		},

		actions: {
			reload: function reload() {
				this.refresh();
			},

			'export': function _export() {
				var params = Ember['default'].$.param(this.lastQuery),
				    host = ENV['default'].HOST + '/api/exports/cdr';

				window.location = host + '?' + params + '&id=-1';
			}
		}
	});

});
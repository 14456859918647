define('dashboard/routes/cbc/create', ['exports', 'ember', 'dashboard/utils'], function (exports, Ember, utils) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend({
		auth: Ember['default'].inject.service(),

		queryParams: {

			date: {
				refreshModel: false
			},

			operator: {
				refreshModel: false
			}

		},

		model: function model(params) {
			var date = Ember['default'].get(params, 'date');
			var client = Ember['default'].get(params, 'operator');

			if (Ember['default'].isPresent(date) && Ember['default'].isPresent(client)) {
				return this.findLatestPrices(client);
			} else {
				return Ember['default'].A();
			}
		},

		findLatestPrices: function findLatestPrices(client) {
			var _this = this;

			var token = this.get('auth.sessionToken');
			var url = utils.buildUrl('api', 'cbc', 'latest', client);

			return utils.ajax({
				url: url,
				beforeSend: function beforeSend(request) {
					request.setRequestHeader("Authorization", 'Bearer ' + token);
				}
			}).then(function (response) {
				var store = _this.store;
				var model = store.modelFor('cbc-price');
				var serializer = store.serializerFor('cbc-price');
				var prices = serializer.normalizeArrayResponse(store, model, response);
				return store.push(prices);
			});
		},

		resetController: function resetController(controller, isExiting, transition) {
			this._super(controller, isExiting, transition);

			if (isExiting) {
				controller.setProperties({
					date: null,
					operator: null
				});
			}
		},

		actions: {

			select: function select() {
				this.refresh();
			}

		}

	});

});
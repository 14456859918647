define('dashboard/templates/invoices/index', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@2.0.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 39,
                  "column": 12
                },
                "end": {
                  "line": 50,
                  "column": 12
                }
              },
              "moduleName": "dashboard/templates/invoices/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"href","#");
              dom.setAttribute(el1,"class","ui blue button small icon basic");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("i");
              dom.setAttribute(el2,"class","file pdf outline icon");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" Preview\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"href","#");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("i");
              dom.setAttribute(el2,"class","send outline icon");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" Send\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"href","#");
              dom.setAttribute(el1,"class","ui orange button small icon basic");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("i");
              dom.setAttribute(el2,"class","share icon");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" Skip\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [1]);
              var element5 = dom.childAt(fragment, [3]);
              var element6 = dom.childAt(fragment, [5]);
              var morphs = new Array(4);
              morphs[0] = dom.createElementMorph(element4);
              morphs[1] = dom.createAttrMorph(element5, 'class');
              morphs[2] = dom.createElementMorph(element5);
              morphs[3] = dom.createElementMorph(element6);
              return morphs;
            },
            statements: [
              ["element","action",["preview",["get","invoice",["loc",[null,[40,85],[40,92]]]]],[],["loc",[null,[40,66],[40,94]]]],
              ["attribute","class",["concat",["ui green button small icon basic ",["subexpr","if",[["get","invoice.sending",["loc",[null,[44,61],[44,76]]]],"loading"],[],["loc",[null,[44,56],[44,88]]]]]]],
              ["element","action",["send",["get","invoice",["loc",[null,[43,42],[43,49]]]]],[],["loc",[null,[43,26],[43,51]]]],
              ["element","action",["skip",["get","invoice",["loc",[null,[47,84],[47,91]]]]],[],["loc",[null,[47,68],[47,93]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@2.0.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 52,
                  "column": 12
                },
                "end": {
                  "line": 63,
                  "column": 12
                }
              },
              "moduleName": "dashboard/templates/invoices/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"href","#");
              dom.setAttribute(el1,"class","ui blue button small icon basic");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("i");
              dom.setAttribute(el2,"class","file pdf outline icon");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" Preview\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"href","#");
              dom.setAttribute(el1,"class","ui green button small icon basic");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("i");
              dom.setAttribute(el2,"class","reply icon");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" Revert\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"href","#");
              dom.setAttribute(el1,"class","ui red button small icon basic");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("i");
              dom.setAttribute(el2,"class","trash outline icon");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" Delete\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var element2 = dom.childAt(fragment, [3]);
              var element3 = dom.childAt(fragment, [5]);
              var morphs = new Array(3);
              morphs[0] = dom.createElementMorph(element1);
              morphs[1] = dom.createElementMorph(element2);
              morphs[2] = dom.createElementMorph(element3);
              return morphs;
            },
            statements: [
              ["element","action",["preview",["get","invoice",["loc",[null,[53,85],[53,92]]]]],[],["loc",[null,[53,66],[53,94]]]],
              ["element","action",["unskip",["get","invoice",["loc",[null,[56,85],[56,92]]]]],[],["loc",[null,[56,67],[56,94]]]],
              ["element","action",["delete",["get","invoice",["loc",[null,[59,83],[59,90]]]]],[],["loc",[null,[59,65],[59,92]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@2.0.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 65,
                  "column": 12
                },
                "end": {
                  "line": 74,
                  "column": 12
                }
              },
              "moduleName": "dashboard/templates/invoices/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","ui blue button small icon basic");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("i");
              dom.setAttribute(el2,"class","file pdf outline icon");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" Show Invoice\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element0, 'href');
              return morphs;
            },
            statements: [
              ["attribute","href",["concat",["invoices_pdf/invoice_",["get","invoice.serialNumber",["loc",[null,[66,46],[66,66]]]],".pdf"]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@2.0.2",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 6
              },
              "end": {
                "line": 77,
                "column": 6
              }
            },
            "moduleName": "dashboard/templates/invoices/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" /\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2,"width","10%");
            dom.setAttribute(el2,"class","text-right invoice-buttons");
            var el3 = dom.createTextNode("\n            \n\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element7 = dom.childAt(fragment, [1]);
            var element8 = dom.childAt(element7, [5]);
            var element9 = dom.childAt(element7, [9]);
            var morphs = new Array(9);
            morphs[0] = dom.createAttrMorph(element7, 'class');
            morphs[1] = dom.createMorphAt(dom.childAt(element7, [1]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element7, [3]),1,1);
            morphs[3] = dom.createMorphAt(element8,1,1);
            morphs[4] = dom.createMorphAt(element8,3,3);
            morphs[5] = dom.createMorphAt(dom.childAt(element7, [7]),1,1);
            morphs[6] = dom.createMorphAt(element9,1,1);
            morphs[7] = dom.createMorphAt(element9,3,3);
            morphs[8] = dom.createMorphAt(element9,5,5);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["top aligned ",["subexpr","if",[["get","invoice.isSaving",["loc",[null,[17,36],[17,52]]]],"loading"],[],["loc",[null,[17,31],[17,64]]]]]]],
            ["content","invoice.client.name",["loc",[null,[18,14],[18,37]]]],
            ["content","invoice.serialNumber",["loc",[null,[20,14],[20,38]]]],
            ["inline","moment-format",[["get","invoice.from",["loc",[null,[23,28],[23,40]]]],"YYYY-MM-DD"],[],["loc",[null,[23,12],[23,55]]]],
            ["inline","moment-format",[["get","invoice.to",["loc",[null,[24,28],[24,38]]]],"YYYY-MM-DD"],[],["loc",[null,[24,12],[24,53]]]],
            ["inline","content-editable",[],["type","text","value",["subexpr","@mut",[["get","invoice.comments",["loc",[null,[29,20],[29,36]]]]],[],[]],"class","invoice-comment-editable","placeholder","Enter some comment","update",["subexpr","action",["update",["get","invoice",["loc",[null,[32,38],[32,45]]]],"comments"],[],["loc",[null,[32,21],[32,57]]]],"focus-out",["subexpr","action",["saveInvoice",["get","invoice",["loc",[null,[33,46],[33,53]]]]],[],["loc",[null,[33,24],[33,54]]]]],["loc",[null,[27,12],[34,14]]]],
            ["block","if",[["subexpr","eq",[["get","invoice.status",["loc",[null,[39,22],[39,36]]]],"PENDING"],[],["loc",[null,[39,18],[39,47]]]]],[],0,null,["loc",[null,[39,12],[50,19]]]],
            ["block","if",[["subexpr","eq",[["get","invoice.status",["loc",[null,[52,22],[52,36]]]],"SKIPPED"],[],["loc",[null,[52,18],[52,47]]]]],[],1,null,["loc",[null,[52,12],[63,19]]]],
            ["block","if",[["subexpr","eq",[["get","invoice.status",["loc",[null,[65,22],[65,36]]]],"SENT"],[],["loc",[null,[65,18],[65,44]]]]],[],2,null,["loc",[null,[65,12],[74,19]]]]
          ],
          locals: ["invoice"],
          templates: [child0, child1, child2]
        };
      }());
      return {
        meta: {
          "revision": "Ember@2.0.2",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 0
            },
            "end": {
              "line": 80,
              "column": 0
            }
          },
          "moduleName": "dashboard/templates/invoices/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("table");
          dom.setAttribute(el1,"class","ui single line table invoice-table");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("thead");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("tr");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("th");
          dom.setAttribute(el4,"width","10%");
          var el5 = dom.createTextNode("Client");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("th");
          dom.setAttribute(el4,"width","10%");
          var el5 = dom.createTextNode("Serial number");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("th");
          dom.setAttribute(el4,"width","10%");
          var el5 = dom.createTextNode("Billing period");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("th");
          dom.setAttribute(el4,"width","40%");
          dom.setAttribute(el4,"colspan","2");
          var el5 = dom.createTextNode("Comments");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("th");
          dom.setAttribute(el4,"width","10%");
          var el5 = dom.createTextNode("Actions");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tbody");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]),1,1);
          return morphs;
        },
        statements: [
          ["block","each",[["get","model",["loc",[null,[16,14],[16,19]]]]],[],0,null,["loc",[null,[16,6],[77,15]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@2.0.2",
          "loc": {
            "source": null,
            "start": {
              "line": 80,
              "column": 0
            },
            "end": {
              "line": 88,
              "column": 0
            }
          },
          "moduleName": "dashboard/templates/invoices/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","ui one column grid");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","column center aligned");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","ui compact message warning");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("p");
          var el5 = dom.createTextNode("No invoices found");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@2.0.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 89,
            "column": 0
          }
        },
        "moduleName": "dashboard/templates/invoices/index.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("a");
        dom.setAttribute(el1,"href","#");
        dom.setAttribute(el1,"class","ui blue button small icon basic");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("i");
        dom.setAttribute(el2,"class","file pdf outline icon");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" Generate Invoice Number\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element10 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createElementMorph(element10);
        morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["element","action",["getNextInvoiceNumber",["get","model",["loc",[null,[1,84],[1,89]]]]],[],["loc",[null,[1,52],[1,91]]]],
        ["block","if",[["get","model",["loc",[null,[4,6],[4,11]]]]],[],0,1,["loc",[null,[4,0],[88,7]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});
define('dashboard/helpers/boolean-icon', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Helper.helper(function (params) {
		var value = params[0];
		var icon = !!value ? 'check' : 'remove';
		var color = !!value ? 'green' : 'red';

		var className = 'icon ' + color + ' ' + icon + ' circle';

		return Ember['default'].String.htmlSafe('<i class="' + className + '" />');
	});

});
define('dashboard/mixins/operator/selectable', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Mixin.create({
		store: Ember['default'].inject.service('store'),
		layoutName: 'operators/selectable',
		operator: null,
		search: true,
		multiple: true,
		query: null,

		values: Ember['default'].computed({
			get: function get() {
				var type = this.get('_operatorType');
				var store = this.get('store');
				var query = this.get('query');

				if (Ember['default'].isPresent(query)) {
					return store.query(type, query);
				} else {
					return store.findAll(type);
				}
			}
		}),

		didInsertElement: function didInsertElement() {
			var _this = this;

			this.get('values').then(function () {
				Ember['default'].run.scheduleOnce('afterRender', _this, 'setupDropdown');
			});
		},

		selected: Ember['default'].computed('operator', 'operator.[]', function () {
			var operator = this.get('operator'),
			    multiple = this.get('multiple');

			if (Ember['default'].isEmpty(operator)) {
				return;
			}

			if (multiple) {
				return operator.mapBy('key');
			} else {
				return Ember['default'].get(operator, 'key');
			}
		}),

		selectedDidChange: Ember['default'].observer('selected.[]', function () {
			var _this2 = this;

			var selected = this.get('selected'),
			    multiple = this.get('multiple');

			this.get('values').then(function (operators) {
				var operator;

				if (multiple) {
					operator = _this2._filterMultiple(operators, selected);
				} else {
					operator = _this2._filterSingle(operators, selected);
				}

				_this2.set('operator', operator);
				_this2.sendAction('action', operator);
			});
		}),

		_filterMultiple: function _filterMultiple(operators, selected) {

			return operators.filter(function (operator) {
				var key = Ember['default'].get(operator, 'key');

				return selected.contains(key);
			});
		},

		_filterSingle: function _filterSingle(operators, selected) {
			return operators.filter(function (operator) {
				var key = Ember['default'].get(operator, 'key');

				return selected === key;
			})[0];
		},

		actions: {
			clear: function clear() {
				this.$().dropdown('clear');
			}
		}
	});

});
define('dashboard/helpers/price-indication', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	var mappings = {
		UP: 'arrow-up',
		STABIL: 'arrow-right',
		DOWN: 'arrow-down'
	};

	exports['default'] = Ember['default'].Helper.helper(function (params) {
		var indication = params[0] || 'STABIL';
		var value = mappings[indication];

		Ember['default'].assert('Invalid indication value, you passed ' + indication, Ember['default'].isPresent(value));

		var className = 'fa fa-' + value;

		return Ember['default'].String.htmlSafe('<i class="' + className + '" />');
	});

});
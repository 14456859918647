define('dashboard/mixins/form-route', ['exports', 'ember', 'ember-cli-data-validation/error'], function (exports, Ember, ValidationError) {

	'use strict';

	exports['default'] = Ember['default'].Mixin.create({

		/**
	  * Display the Success notification flash message
	  * when a record has been created/updated
	  * 
	  * @param  {Model}   model
	  * @param  {Boolean} created
	  * @param  {String} created
	  */
		showSuccessMessage: function showSuccessMessage(model, created, message) {
			var className = Ember['default'].get(model, 'className');

			if (Ember['default'].isBlank(message)) {
				if (created) {
					message = 'New ' + className + ' was created.';
				} else {
					message = className + ' was updated';
				}
			}

			this.notify.success({
				html: '<p>' + message + '</p>'
			});
		},

		/**
	  * Display the Error notification flash message
	  * when a record has been rejected by the adapter.
	  * 
	  * @param  {Error} error
	  * @param  {Model} model
	  */
		showErrorMessage: function showErrorMessage(error /*, model */) {
			if (error instanceof ValidationError['default']) {
				this.notify.warning({
					html: '<h3>Validation Errors</h3><p>' + error.message + '</p>'
				});
			} else {
				this.notify.error({
					closeAfter: null,
					html: '<h3>Could not save record</h3><p>' + error.message + '</p>'
				});
			}
		},

		/**
	  * Hook invoked when the Model is saved.
	  * It would redirect to `routeName` property of Route using this Mixin.
	  */
		recordDidSave: function recordDidSave(model, created, message) {
			Ember['default'].run(this, 'showSuccessMessage', model, created, message);

			var name = this.get('routeName');
			this.transitionTo(name);
		},

		/**
	  * Hook invoked when the Model could not be saved.
	  */
		recordDidError: function recordDidError(error, model) {
			Ember['default'].Logger.warn('Could not save Record.', error.stack);

			Ember['default'].run(this, 'showErrorMessage', error, model);
		},

		actions: {

			/**
	   * Action triggered by the Operator Form template.
	   *
	   * Tries to save record.
	   * If the record has been successfully saved, `recordDidSave` hook is invoked.
	   * In case the promise is rejected, an `error` action is triggered.
	   *
	   * @param  {DS.Model} model
	   * @param  {String} message
	   */
			save: function save(model, message) {
				var _this = this;

				var isNew = Ember['default'].get(model, 'isNew');

				model.save().then(function () {
					return Ember['default'].run(_this, 'recordDidSave', model, isNew, message);
				})['catch'](function (error) {
					return Ember['default'].run(_this, 'recordDidError', error, model);
				});
			},

			/**
	   * Starts a transition to an `edit` route.
	   */
			edit: function edit(model) {
				var name = this.get('routeName');
				this.transitionTo(name + '.edit', model);
			},

			/**
	   * Opens the Delete modal to confirm the delete action.
	   */
			'delete': function _delete(model) {
				this.send('openModal', 'modal.delete', {
					model: model,
					controller: 'modal.delete'
				});
			},

			/**
	   * Action triggered by the operator form modal, when the Modal
	   * window is closed.
	   *
	   * Tedirects back to the top level resource.
	   */
			closedModal: function closedModal() {
				var name = this.get('routeName');
				this.transitionTo(name);
			}
		}
	});

});
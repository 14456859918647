define('dashboard/helpers/operator-color', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Helper.helper(function (params, opts) {
		var operator = params[0];

		if (Ember['default'].isBlank(operator)) {
			return;
		}

		var options = Ember['default'].merge({
			'class': '',
			tagName: 'span'
		}, opts);

		var color = undefined;

		if (Ember['default'].typeOf(operator) === 'string') {
			color = operator;
		} else {
			color = Ember['default'].get(operator, 'color');
		}

		var className = 'operator-color ' + options['class'];
		var style = 'background: ' + color + '; box-shadow: 0 0 2px ' + color;

		return Ember['default'].String.htmlSafe('<' + options.tagName + ' class="' + className + '" style="' + style + '" />');
	});

});
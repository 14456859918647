define('dashboard/components/prices-list', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Component.extend({
		actions: {
			/**
	   * Send `edit` action when the edit button is clicked
	   */
			edit: function edit(model) {
				this.sendAction('edit', model);
			},

			/**
	   * Send `delete` action when the edit button is clicked
	   */
			'delete': function _delete(model) {
				this.sendAction('delete', model);
			}
		}
	});

});
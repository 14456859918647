define('dashboard/mixins/reports/controller', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Mixin.create({
		/**
	  * Report Model key type for the store
	  * 
	  * @type {String}
	  */
		modelKey: null,

		/**
	  * List of Query params from the Route
	  * @type {Array}
	  */
		queryParams: ['carrierIn', 'carrierOut', 'from'],

		/**
	  * Date timestamp from when to fetch the records.
	  * 
	  * @type {Number}
	  */
		from: Date.now(),

		/**
	  * Date timestamp untill when to fetch the records.
	  * 
	  * @type {Number}
	  */
		to: Date.now(),

		/**
	  * Selected Carrier record
	  * 
	  * @type {Carrier}
	  */
		carrier: null,

		/**
	  * Selected Client record
	  * 
	  * @type {Client}
	  */
		client: null,

		selectedCarrier: null,
		selectedClient: null,

		carrierDidChange: Ember['default'].observer('selectedCarrier', function () {
			this.set('carrier', this.get('selectedCarrier.id') || null);
		}),

		clientDidChange: Ember['default'].observer('selectedClient', function () {
			this.set('client', this.get('selectedClient.id') || null);
		}),

		/**
	  * Get the Metadata object for the current Store request
	  *
	  * @type {Object}
	  */
		meta: Ember['default'].computed('model.[]', 'modelKey', {
			get: function get() {
				var modelKey = this.get('modelKey');

				Ember['default'].assert('You must set the `modelKey` property on this controller to fetch the Metadata.', Ember['default'].typeOf(modelKey) === 'string');

				return this.store._metadataFor(modelKey) || {};
			}
		})
	});

});
define('dashboard/routes/reports/carrier', ['exports', 'ember', 'dashboard/mixins/reports/route'], function (exports, Ember, ReportsRoute) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(ReportsRoute['default'], {
		modelKey: 'carrier-report',

		shouldFetch: function shouldFetch(params) {
			return Ember['default'].isPresent(params.carrier);
		}
	});

});
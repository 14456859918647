define('dashboard/models/cdr', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	exports['default'] = DS['default'].Model.extend({
		date: DS['default'].attr('date', {
			label: 'Date'
		}),
		callerid: DS['default'].attr('string', {
			label: 'Caller ID'
		}),
		pdd: DS['default'].attr('string', {
			label: 'PDD'
		}),
		pai: DS['default'].attr('string', {
			label: 'PAI'
		}),
		calledid: DS['default'].attr('string', {
			label: 'Called ID'
		}),
		ringing: DS['default'].attr('number', {
			label: 'Ringing'
		}),
		duration: DS['default'].attr('number', {
			label: 'Duration'
		}),
		importedAt: DS['default'].attr('date', {
			label: 'Imported date'
		}),
		ratedAt: DS['default'].attr('date', {
			label: 'Rating date'
		}),

		/* Not needed at the moment */
		/* 
	 clientId: DS.attr('string', {
	 	key: 'client_id',
	 	label: 'Client ID'
	 }),
	 carrierId: DS.attr('number', {
	 	key: 'carrier_id',
	 	label: 'Carrier ID'
	 	}),
	 sellingPriceId: DS.attr('number', {
	 	key: 'selling_price_id',
	 	label: 'Selling Price ID'
	 }),
	 purchasePriceId: DS.attr('number', {
	 	key: 'purchase_price_id',
	 	label: 'Purchase Price ID'
	 }), */

		sellingPrice: DS['default'].attr('string', {
			key: 'selling_price',
			label: 'Selling Price'
		}),
		purchasePrice: DS['default'].attr('string', {
			key: 'purchase_price',
			label: 'Purchase Price'
		}),
		purchaseDialcode: DS['default'].attr('string', {
			key: 'purchase_dialcode',
			label: 'Purchase dialcode'
		}),
		sellingDialcode: DS['default'].attr('string', {
			key: 'selling_dialcode',
			label: 'Selling dialcode'
		}),
		externalId: DS['default'].attr('number', {
			key: 'external_id',
			label: 'External ID'
		}),
		carrierIn: DS['default'].attr('string', {
			key: 'carrier_in',
			label: 'Carrier In'
		}),
		carrierOut: DS['default'].attr('string', {
			key: 'carrier_out',
			label: 'Carrier Out'
		}),
		sipCode: DS['default'].attr('number', {
			key: 'sip_code',
			label: 'SIP'
		}),
		ratingCount: DS['default'].attr('number', {
			key: 'rating_count',
			label: 'Rating Count'
		}),
		masterDestination: DS['default'].attr('string', {
			key: 'master_destination',
			label: 'Master Destination'
		})
	});

});
define('dashboard/adapters/alert-template', ['exports', 'dashboard/adapters/application'], function (exports, Adapter) {

	'use strict';

	exports['default'] = Adapter['default'].extend({
		pathForType: function pathForType() {
			return 'monitoring/alert-templates';
		}
	});

});
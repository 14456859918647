define('dashboard/components/fixed-table-header', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

	function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

	(function (_$) {
		var Chromatable = (function () {
			function Chromatable(element) {
				_classCallCheck(this, Chromatable);

				this.id = 'chromatable-' + Ember['default'].uuid();
				this.element = element;

				this.render();
				this.resize();

				this._listener = Ember['default'].run.bind(this, 'resize');
				_$(window).resize(this._listener);
			}

			_createClass(Chromatable, [{
				key: '$',
				value: function $(sel) {
					return sel ? _$(sel, this.element) : _$(this.element);
				}
			}, {
				key: 'render',
				value: function render() {
					this.header = this.cloneHeader();

					this.$().after(this.header);
					this.wrap();
				}
			}, {
				key: 'wrap',
				value: function wrap() {
					var wrapper = _$('<div>', {
						id: this.id,
						'class': 'chromatable-wrapper'
					});

					wrapper.css({
						position: 'relative',
						overflow: 'auto',
						height: '600px'
					});

					this.$().wrap(wrapper);
				}
			}, {
				key: 'cloneHeader',
				value: function cloneHeader() {
					var header = _$('<table>', {
						'class': 'fixed-header-table'
					});

					header.html(this.$('thead').clone());
					header.css({
						position: 'absolute',
						top: 0,
						left: 0
					});

					return header;
				}
			}, {
				key: 'resize',
				value: function resize() {
					var _this = this;

					var width = this.$().width();

					this.header.width(width);
					this.wrapper.width(width);

					this.$("thead:eq(0)>tr th").each(function (i, element) {
						var width = _$(element).width();
						_$("thead:eq(0)>tr th:eq(" + i + ")", _this.header).width(width);
					});
				}
			}, {
				key: 'destroy',
				value: function destroy() {
					_$(window).off('resize', this._listener);
				}
			}, {
				key: 'wrapper',
				get: function get() {
					return _$('#' + this.id);
				}
			}]);

			return Chromatable;
		})();

		_$.fn.chromatable = function (action) {

			return this.each(function () {
				var chromatable = _$(this).data('chromatable');

				if (Ember['default'].isNone(chromatable)) {
					chromatable = new Chromatable(this);
				}

				if (Ember['default'].isPresent(action) && Ember['default'].canInvoke(chromatable, action)) {
					Ember['default'].run(chromatable, action);
				}

				_$(this).data('chromatable', chromatable);
			});
		};
	})(Ember['default'].$);

	exports['default'] = Ember['default'].Component.extend({
		classNames: ['table-scroll-wrapper'],

		didInsertElement: function didInsertElement() {
			this.$('table').chromatable();
		},

		willDestroyElement: function willDestroyElement() {
			this.$('table').chromatable('destroy');
		}
	});

});
define('dashboard/controllers/cbc/create', ['exports', 'ember', 'moment', 'dashboard/utils'], function (exports, Ember, moment, utils) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend({
		auth: Ember['default'].inject.service(),

		/**
	  * Selected date for the Prices
	  * 
	  * @type {Number}
	  */
		date: null,

		/**
	  * Selected Client id
	  * 
	  * @type {Number}
	  * @default null
	  */
		operator: null,

		/**
	  * Todays date
	  *
	  * @type {Moment}
	  * @readOnly
	  */
		today: Ember['default'].computed({
			get: function get() {
				return moment['default']();
			}
		}).volatile(),

		/**
	  * Client operator record
	  * 
	  * @type {Client}
	  */
		client: Ember['default'].computed('model.[]', 'operator', {
			get: function get() {
				var id = this.get('operator');

				if (Ember['default'].isPresent(id)) {
					return this.store.recordForId('client', id);
				}

				return null;
			}
		}),

		/**
	  * Checks if the User can create new prices
	  * 
	  * @type {Boolean}
	  * @readOnly
	  */
		disabled: Ember['default'].computed('client', 'date', {
			get: function get() {
				var _getProperties = this.getProperties('client', 'date');

				var client = _getProperties.client;
				var date = _getProperties.date;

				return Ember['default'].isEmpty(client) || Ember['default'].isEmpty(date);
			}
		}),

		/**
	  * Returns a list containg all the dirty (modified) records of CBC Prices
	  * that should be created when save is triggered.
	  *
	  * @property dirtyRecords
	  * @returns {Array}
	  */
		findDirtyRecords: function findDirtyRecords() {
			var prices = this.get('model');

			return prices.filter(function (price) {
				return price.get('hasDirtyAttributes');
			}).map(function (price) {
				return price.serialize({
					includeId: true
				});
			});
		},

		/**
	  * Saves the List of updated/dirty price records and downloads
	  * the XLS export file.
	  * 
	  * @param  {Object} options
	  * @return {Promise}
	  */
		saveDirtyRecords: function saveDirtyRecords(options) {
			var _this = this;

			this.set('saving', true);

			return utils.ajax(options).then(function (response) {
				var count = Ember['default'].get(response, 'count');
				var validFrom = Ember['default'].get(response, 'validFrom');
				var client = Ember['default'].get(response, 'client');
				var name = Ember['default'].get(client, 'name');

				Ember['default'].Logger.info('Prices saved with result', response);

				_this.notify.success({
					html: '<h3>Import Success</h3><p>\n\t\t\t\t\tSuccessfully imported <b>' + count + '</b>\n\t\t\t\t\tCBC Price records for Operator <b>' + name + '</b></p>'
				});

				_this.setProperties({
					date: null,
					operator: null
				});

				_this.send('select');
				Ember['default'].run.later(_this, 'send', 'export', Ember['default'].get(client, 'id'), validFrom, 400);
			})['catch'](function (e) {
				_this.send('warning', e);
			})['finally'](function () {
				_this.set('saving', false);
			});
		},

		isValidDate: Ember['default'].computed('date', {
			get: function get() {
				var date = this.get('date');

				if (Ember['default'].isPresent(date)) {
					return moment['default'](parseInt(date, 10)).isAfter();
				}

				return false;
			}
		}),

		clientDidChange: Ember['default'].observer('client', function () {
			this.set('operator', this.get('client.id') || null);
		}),

		actions: {

			/**
	   * Update the CBC Price property value 
	   * 
	   * @param  {CBCPrice} price
	   * @param  {String}   key
	   * @param  {*}        value
	   */
			priceUpdated: function priceUpdated(price, key, value) {
				Ember['default'].set(price, key, parseFloat(value));
			},

			/**
	   * Handler for `save` action. Atempts to create new prices if some of them
	   * are updated.
	   */
			save: function save() {
				var _getProperties2 = this.getProperties('date', 'operator');

				var date = _getProperties2.date;
				var operator = _getProperties2.operator;

				var data = this.findDirtyRecords();

				if (Ember['default'].isEmpty(data)) {
					return this.send('warning', 'Could not import prices', new Ember['default'].Error('No changed prices detected to save.'));
				}

				var token = this.get('auth.sessionToken');
				var url = utils.buildUrl('api', 'cbc', operator, date);

				this.saveDirtyRecords({
					type: 'POST',
					url: url, data: data,
					beforeSend: function beforeSend(request) {
						request.setRequestHeader("Authorization", 'Bearer ' + token);
					}
				});
			},

			popup: function popup(destinationId) {
				window.open('https://www.billiger-telefonieren.de/festnetz/schnellrechner/ergebnis/?resultLimit=10&resultPage=1&mergeDefaults=false&destinationId=' + destinationId + '&phoneCallDuration=1&accessModeCallByCall=true&billingIncrement=60-60&onlyWithoutRegistration=true&onlyWithoutConnectionFee=true&calc_submit=Jetzt+vergleichen', 'BT window', 'width=700,height=300');
			}

		}

	});

});
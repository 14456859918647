define('dashboard/utils/uploadable', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	/* global File */
	function fileSizeSI(a, b, c, d, e) {
		return (b = Math, c = b.log, d = 1e3, e = c(a) / c(d) | 0, a / b.pow(d, e)).toFixed(2) + ' ' + (e ? 'kMGTPEZY'[--e] + 'B' : 'Bytes');
	}

	/**
	 * An Uploadable Object wrapper built around
	 * the `File` Object.
	 *
	 * @class Uploadable
	 * @extends {Ember.ObjectProxy}
	 */
	var Uploadable = Ember['default'].ObjectProxy.extend({

		/**
	  * File that should be uploaded
	  *
	  * @property file
	  * @type {File}
	  * @readOnly
	  */
		fileBinding: 'content',

		/**
	  * Name of the File that should be uploaded
	  *
	  * @property name
	  * @type {String}
	  * @readOnly
	  */
		nameBinding: 'file.name',

		/**
	  * Size of the File, presented as a human readable string
	  *
	  * @property size
	  * @type {String}
	  * @readOnly
	  */
		size: Ember['default'].computed('file.size', {
			get: function get() {
				var size = this.get('file.size');

				if (Ember['default'].isPresent(size)) {
					return fileSizeSI(size);
				}
			}
		}),

		destroy: function destroy() {
			// Clear the File
			this.set('content', null);
			this._super();
		}
	});

	Uploadable.reopenClass({

		/**
	  * Creates a new Uploadable from a File.
	  *
	  * @static
	  * @param  {File} file
	  * @return {Uploadable}
	  */
		fromFile: function fromFile(file) {
			Ember['default'].assert('File you passed must be an instance of File', file instanceof File);

			return Uploadable.create({
				content: file
			});
		},

		/**
	  * Create a List of Uploadable Items from the
	  * FileList or an Array of Files.
	  *
	  * @static
	  * @param  {FileList|Array} files
	  * @return {Array}
	  */
		fromFileList: function fromFileList(list) {
			var files = Ember['default'].A();

			for (var i = 0; i < list.length; i++) {
				files.addObject(Uploadable.fromFile(list[i]));
			}

			return files;
		}
	});

	exports['default'] = Uploadable;

});
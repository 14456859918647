define('dashboard/transforms/raw', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	exports['default'] = DS['default'].StringTransform.extend({
		serialize: function serialize(deserialized) {
			return deserialized;
		},

		deserialize: function deserialize(serialized) {
			return serialized;
		}
	});

});
define('dashboard/helpers/human-size', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	function fileSizeSI(a, b, c, d, e) {
		return (b = Math, c = b.log, d = 1e3, e = c(a) / c(d) | 0, a / b.pow(d, e)).toFixed(2) + ' ' + (e ? 'kMGTPEZY'[--e] + 'B' : 'Bytes');
	}

	exports['default'] = Ember['default'].Helper.helper(function (params) {
		var size = params[0];

		if (Ember['default'].isPresent(size)) {
			return fileSizeSI(size);
		}
	});

});
define('dashboard/tests/models/cdr.jshint', function () {

  'use strict';

  QUnit.module('JSHint - models/cdr.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(true, 'models/cdr.js should pass jshint.');
  });

});
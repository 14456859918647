define('dashboard/models/invoice', ['exports', 'ember', 'ember-data'], function (exports, Ember, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({

    serialNumber: DS['default'].attr('string'),

    status: DS['default'].attr('string'),

    comments: DS['default'].attr('string'),

    from: DS['default'].attr('local-date'),

    to: DS['default'].attr('local-date'),

    createdAt: DS['default'].attr('date'),

    updatedAt: DS['default'].attr('date'),

    client: DS['default'].belongsTo('client'),

    isPending: Ember['default'].computed.equal('status', 'PENDING'),

    isSkipped: Ember['default'].computed.equal('status', 'SKIPPED'),

    hasSerialNumber: Ember['default'].computed('serialNumber', {
      get: function get() {
        return !Ember['default'].isBlank(this.get('serialNumber'));
      }
    })
  });

});
define('dashboard/controllers/trading', ['exports', 'ember', 'ember-data', 'dashboard/utils'], function (exports, Ember, DS, utils) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend({
		queryParams: ['key', 'destination'],
		destination: null,

		auth: Ember['default'].inject.service(),

		priceListCount: Ember['default'].computed(function () {
			var list = [];

			for (var i = 0; i < 20; i++) {
				list.push(i + 1);
			}

			return list;
		}),

		prices: Ember['default'].computed('key', 'destination', function () {
			var client = this.get('key') || this.get('client.key');
			var destination = this.get('destination');

			var promise = undefined;

			if (Ember['default'].isPresent(client) && Ember['default'].isPresent(destination)) {
				promise = this.fetch('prices/' + client + '/' + destination);
			} else {
				promise = Ember['default'].RSVP.reject(new Ember['default'].Error('Please select a Client and a Destination'));
			}

			return DS['default'].PromiseArray.create({
				promise: promise
			});
		}),

		clientDidChange: Ember['default'].observer('selectedClient', function () {
			this.set('key', this.get('selectedClient.key'));
		}),

		fetch: function fetch(action, data) {
			var token = this.get('auth.sessionToken');

			return utils.ajax({
				url: 'api/trading/' + action,
				data: data,
				beforeSend: function beforeSend(request) {
					request.setRequestHeader("Authorization", 'Bearer ' + token);
				}
			});
		},

		actions: {
			quality: function quality(price, offer) {
				var key = Ember['default'].get(offer, 'carrier');
				var destination = Ember['default'].get(price, 'masterDestination');
				var dialcode = Ember['default'].get(price, 'dialcode');

				var model = DS['default'].PromiseArray.create({
					promise: this.fetch('quality', {
						key: key, destination: destination, dialcode: dialcode
					})
				});

				this.send('openModal', 'modal/quality', {
					model: model
				});
			}
		}

	});

});
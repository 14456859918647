define('dashboard/utils/file-lists', ['exports', 'ember', 'ember-data', 'ember-cli-data-validation/error'], function (exports, Ember, DS, ValidationError) {

	'use strict';

	function attr(options) {
		options = options || {};

		var meta = {
			isAttribute: true,
			options: options
		};

		return Ember['default'].computed({
			get: function get(key) {
				return Ember['default'].get(this._data, key);
			},
			set: function set(key, value) {
				var oldValue = Ember['default'].get(this._data, key);

				if (value !== oldValue) {
					this._data[key] = value;
					this.errors.remove(key);
				}

				return value;
			}
		}).meta(meta);
	}

	/**
	 * Model used to contain the Uploadable File to be sent to the server.
	 *
	 * It can be extended to support additional attributes.
	 * Serializes the attributes into a FormData instance.
	 *
	 * @class PriceList
	 */
	var PriceList = Ember['default'].Object.extend(Ember['default'].Evented, {
		init: function init() {
			this._super.apply(this, arguments);

			this._data = {};
			this.errors = new DS['default'].Errors();
		},

		data: Ember['default'].computed.readOnly('_data'),

		/**
	  * If this property is `true` the record is in the `loading` state.
	  *
	  * @property isLoading
	  * @type {Boolean}
	  */
		isLoading: false,

		/**
	  * If this property is `false` the record is in the `invalid` state.
	  *
	  * @property isValid
	  * @type {Boolean}
	  */
		isValid: Ember['default'].computed.oneWay('errors.isEmpty'),

		/**
	  * Current upload progress
	  *
	  * @property progress
	  * @type {Number}
	  */
		progress: null,

		/**
	  * Iterates through the attributes of the model, calling the passed
	  * function on each attribute.
	  *
	  * @method eachAttribute
	  * @param  {Function} callback
	  * @param  {Object}   binding
	  */
		eachAttribute: function eachAttribute(callback, binding) {
			this.constructor.eachAttribute(callback, binding);
		},

		uploadable: attr({
			message: 'No uploadable file selected.',
			serialize: function serialize(formData, value) {
				formData.append('file', Ember['default'].get(value, 'file'), Ember['default'].get(value, 'name'));
			}
		}),

		/**
	  * Serialize the File list into a FormData Object ready to be sent
	  * to the Server to be handled.
	  *
	  * @method serialize
	  */
		serialize: function serialize() {
			var _this = this;

			Ember['default'].run(this, 'validate');

			if (!this.get('isValid')) {
				throw new ValidationError['default']('Please correct the validation errors', this.get('errors'));
			}

			var formData = new FormData();

			this.eachAttribute(function (name, meta) {
				var value = Ember['default'].get(_this, name);

				if (Ember['default'].isNone(value)) {
					return;
				}

				if (Ember['default'].canInvoke(meta.options, 'serialize')) {
					Ember['default'].run(_this, meta.options.serialize, formData, value);
				} else {
					formData.append(name, value);
				}
			});

			return formData;
		},

		/**
	  * Validate the File list attributes
	  *
	  * @method validate
	  */
		validate: function validate() {
			var _this2 = this;

			var errors = this.get('errors');

			this.eachAttribute(function (name, meta) {
				var value = Ember['default'].get(_this2, name);

				if (Ember['default'].isNone(value)) {
					errors.add(name, meta.options.message);
				}
			});

			return Ember['default'].get(errors, 'isEmpty');
		}
	});

	PriceList.reopenClass({

		/**
	  * A map whose keys are the attributes of the model (properties
	  * described by attr) and whose values are the meta object for the
	  * property.
	  *
	  * @property attributes
	  * @type {Ember.Map}
	  * @readOnly
	  * @static
	  */
		attributes: Ember['default'].computed(function () {
			var map = Ember['default'].Map.create();

			this.eachComputedProperty(function (name, meta) {
				if (meta.isAttribute) {
					meta.name = name;
					map.set(name, meta);
				}
			});

			return map;
		}).readOnly(),

		/**
	  * Iterates through the attributes of the model, calling the passed
	  * function on each attribute.
	  *
	  * @method eachAttribute
	  * @param  {Function} callback
	  * @param  {Object}   binding
	  * @static
	  */
		eachAttribute: function eachAttribute(callback, binding) {
			Ember['default'].get(this, 'attributes').forEach(function (meta, name) {
				callback.call(binding, name, meta);
			});
		}
	});

	/**
	 * Price list for Carriers
	 *
	 * @class PurchasePriceList
	 * @extends {PriceList}
	 */
	var PurchasePriceList = PriceList.extend({
		carrier: attr({
			message: 'No Carrier selected.',
			serialize: function serialize(formData, value) {
				console.log('carrier', Ember['default'].get(value, 'id'));
				formData.append('carrier', Ember['default'].get(value, 'id'));
			}
		})
	});

	/**
	 * Price list for Clients
	 *
	 * @class SellingPriceList
	 * @extends {PriceList}
	 */
	var SellingPriceList = PriceList.extend({
		client: attr({
			message: 'No Client selected.',
			serialize: function serialize(formData, value) {
				console.log('client', Ember['default'].get(value, 'id'));
				formData.append('client', Ember['default'].get(value, 'id'));
			}
		})
	});

	exports.PriceList = PriceList;
	exports.PurchasePriceList = PurchasePriceList;
	exports.SellingPriceList = SellingPriceList;

});
define('dashboard/components/bs-modal', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Component.extend({
		/**
	  * Is the modal closable when clicking on the darkened overlay
	  *
	  * @property closable
	  * @type {Boolean}
	  * @default true
	  */
		closable: true,

		/**
	  * Should the modal be shown immedatelly
	  *
	  * @property show
	  * @type {Boolean}
	  * @default true
	  */
		show: false,

		modalElementId: Ember['default'].computed('elementId', function () {
			return this.get('elementId') + '-modal';
		}).readOnly(),

		modal: function modal() {
			var _Ember$$;

			var id = this.get('modalElementId');
			return (_Ember$$ = Ember['default'].$('#' + id)).modal.apply(_Ember$$, arguments);
		},

		didInsertElement: function didInsertElement() {
			// setup the modal id reference
			this.$('.ui.modal').attr('id', this.get('modalElementId'));
			Ember['default'].run.scheduleOnce('afterRender', this, 'setupModal');
		},

		setupModal: function setupModal() {
			var view = this;
			var show = this.get('show');

			this.modal({
				detachable: true,
				show: show,
				closable: this.get('closable'),

				/**
	    * Send `opened` action
	    */
				onVisible: function onVisible() {
					Ember['default'].run(view, 'sendAction', 'opened');
				},

				/**
	    * Send `closed` action
	    */
				onHidden: function onHidden() {
					Ember['default'].run(view, 'sendAction', 'closed');
					view.modal().remove();
				}
			});

			if (show) {
				this.modal('show');
			}
		},

		/**
	  * Remove the Modal when the element is about to be
	  * removed.
	  */
		willDestroyElement: function willDestroyElement() {
			this.modal('hide').remove();
		}
	});

});
define('dashboard/tests/unit/mixins/razorflow-dashboard-test', ['ember', 'dashboard/mixins/razorflow-dashboard', 'qunit'], function (Ember, RazorflowDashboardMixin, qunit) {

  'use strict';

  qunit.module('Unit | Mixin | razorflow dashboard');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var RazorflowDashboardObject = Ember['default'].Object.extend(RazorflowDashboardMixin['default']);
    var subject = RazorflowDashboardObject.create();
    assert.ok(subject);
  });

});
define('dashboard/auth/user', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Object.extend({
    username: null,
    name: null, // used when parsing the username form the /auth/me response
    status: 'active'
  });

});
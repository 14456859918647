define('dashboard/services/import', ['exports', 'ember', 'dashboard/utils', 'dashboard/config/environment'], function (exports, Ember, utils, ENV) {

	'use strict';

	exports['default'] = Ember['default'].Service.extend({

		/**
	  * Authentication Service
	  *
	  * @param auth
	  * @type {AuthenticationService}
	  */
		auth: Ember['default'].inject.service(),

		/**
	  * Ember Data Store
	  *
	  * @param store
	  * @type {DS.Store}
	  */
		store: Ember['default'].inject.service(),

		/**
	  * Build a Server Endpoint URL
	  *
	  * @param  {String} type
	  * @return {String}
	  */
		endpoint: function endpoint(type) {
			return ENV['default'].HOST + '/api/imports/' + type;
		},

		/**
	  * Upload the `.xls` Prices file to the server for
	  * the selected Operator.
	  *
	  * @param  {String}   endpoint
	  * @param  {FileList} list
	  * @return {Promise}
	  */
		'import': function _import(endpoint, list) {
			Ember['default'].assert('No uploadable file list selected.', Ember['default'].isPresent(list));
			Ember['default'].assert('You must define a server endpoint where to upload the file', Ember['default'].isPresent(endpoint));

			var token = this.get('auth.sessionToken');

			Ember['default'].assert('No session token present', Ember['default'].isPresent(token));

			var formData = list.serialize();

			Ember['default'].Logger.info('Importing File List', list, formData);

			Ember['default'].set(list, 'isLoading', true);

			return utils.ajax({
				type: "POST",
				beforeSend: function beforeSend(request) {
					request.setRequestHeader("Authorization", 'Bearer ' + token);
				},
				// xhr: function() {
				// 	var xhr = new window.XMLHttpRequest();

				// 	xhr.addEventListener("progress", function(evt) {
				// 		console.log(evt, list);

				// 		if (evt.lengthComputable) {
				// 			var progress = evt.loaded / evt.total;
				// 			Ember.set(list, 'progress', Math.round(progress * 100));
				// 		}
				// 	}, false);
				// 	return xhr;
				// },
				url: endpoint,
				data: formData,
				processData: false,
				contentType: false
			}).then(function (payload) {
				return payload;
			})['finally'](function () {
				Ember['default'].set(list, 'isLoading', false);
			});
		},

		/**
	  * Upload the `.xls` Prices file to the server for
	  * the selected Carrier.
	  *
	  * @param  {PurchasePriceList} list
	  * @return {Promise}
	  */
		importPurchasePriceList: function importPurchasePriceList(list) {
			return this['import'](this.endpoint('purchasePrices'), list);
		},

		/**
	  * Upload the `.xls` Prices file to the server for
	  * the selected Client.
	  *
	  * @param  {SellingPriceList} list
	  * @return {Promise}
	  */
		importSellingPriceList: function importSellingPriceList(list) {
			return this['import'](this.endpoint('sellingPrices'), list);
		}

	});

});
define('dashboard/components/query-parameter', ['exports', 'ember', 'ember-data', 'dashboard/config/environment', 'dashboard/utils'], function (exports, Ember, DS, ENV, utils) {

	'use strict';

	var QueryParameterComponent = Ember['default'].Component.extend({
		classNames: ['ui', 'form'],

		/**
	  * Query Parameter record
	  * 
	  * @type {QueryParameter}
	  */
		parameter: null,

		/**
	  * List of available Query Value Types
	  *
	  * @type {Promise}
	  */
		types: Ember['default'].computed(function () {
			return Ember['default'].get(this.constructor, 'types');
		}),

		/**
	  * Remove/Delete the Query Parameter from the Server.
	  *
	  * Sends a `remove` action when a parameter is remove,
	  * otherwise an `error` action is sent.
	  * 
	  * @param  {QueryParameter} parameter
	  * @return {Promise}
	  */
		removeParameter: function removeParameter(parameter) {
			var _this = this;

			parameter.destroyRecord().then(function () {
				_this.sendAction('remove', parameter);
			})['catch'](function (error) {
				console.log('Record destroyRecord;', error);
				_this.sendAction('error', error);
			});
		},

		actions: {
			'delete': function _delete(parameter) {
				Ember['default'].run(this, 'removeParameter', parameter);
			}
		}

	});

	QueryParameterComponent.reopenClass({

		/**
	  * Fetch a List of all available Query Value Types form the
	  * server.
	  *
	  * @type {Promise}
	  * @static
	  */
		types: Ember['default'].computed(function () {
			var promise = utils.ajax(ENV['default'].HOST + '/api/monitoring/jobs/query-value-types');

			return DS['default'].PromiseArray.create({
				promise: promise
			});
		})

	});

	exports['default'] = QueryParameterComponent;

});
define('dashboard/auth/error', ['exports'], function (exports) {

	'use strict';

	exports.UnauthorizedError = UnauthorizedError;
	exports.AccessDeniedError = AccessDeniedError;

	function UnauthorizedError(message, status) {
		Error.call(this, message);

		this.message = message;
		this.status = status || 401;

		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, UnauthorizedError);
		}
	}

	UnauthorizedError.prototype = Object.create(Error.prototype, {
		constructor: {
			value: UnauthorizedError
		},
		name: {
			value: 'UnauthorizedError'
		}
	});

	function AccessDeniedError(message, status) {
		Error.call(this, message);

		this.message = message;
		this.status = status || 403;

		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, AccessDeniedError);
		}
	}

	AccessDeniedError.prototype = Object.create(Error.prototype, {
		constructor: {
			value: AccessDeniedError
		},
		name: {
			value: 'AccessDeniedError'
		}
	});

	exports['default'] = UnauthorizedError;

});
define('dashboard/controllers/loggers', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend({
		page: 1,
		size: 100,
		name: null,

		levels: Ember['default'].A(['TRACE', 'DEBUG', 'INFO', 'WARN', 'ERROR']),

		loggers: Ember['default'].computed('model.[]', 'name', function () {
			var loggers = this.get('model'),
			    name = this.get('name');

			if (Ember['default'].isEmpty(name)) {
				return loggers;
			}

			return loggers.filter(function (logger) {
				return logger.get('name').indexOf(name) > -1;
			});
		}),

		meta: Ember['default'].computed(function () {
			return this.store._metadataFor('logger');
		})
	});

});
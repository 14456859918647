define('dashboard/mixins/price/model', ['exports', 'ember', 'ember-data'], function (exports, Ember, DS) {

	'use strict';

	exports['default'] = Ember['default'].Mixin.create({
		destination: DS['default'].attr('string', {
			validation: {
				required: true,
				max: 255
			}
		}),
		dialcode: DS['default'].attr('string', {
			validation: {
				required: true,
				max: 64
			}
		}),
		price: DS['default'].attr('number', {
			validation: {
				number: true,
				required: true
			}
		}),
		validFrom: DS['default'].attr('date', {
			validation: {
				required: true
			}
		}),
		validTo: DS['default'].attr('date'),
		imported: DS['default'].attr('date', {
			validation: {
				required: true
			}
		}),
		removed: DS['default'].attr('date')
	});

});
define('dashboard/controllers/reports/carrier-overview', ['exports', 'ember', 'dashboard/utils', 'dashboard/mixins/reports/controller'], function (exports, Ember, utils, ControllerMixin) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend(ControllerMixin['default'], {
		modelKey: 'carrier-overview-report',
		auth: Ember['default'].inject.service(),

		actions: {
			newWindow1: function newWindow1(carrierKey) {
				var _this = this;

				var token = this.get('auth.sessionToken');
				var url = utils.buildUrl('api', 'carriers', 'key', carrierKey);

				return utils.ajax({
					type: 'GET',
					url: url,
					beforeSend: function beforeSend(request) {
						request.setRequestHeader("Authorization", 'Bearer ' + token);
					}
				}).then(function (response) {
					var carrier_id = Ember['default'].get(response, 'id');
					window.open('https://voicetool.msc-services.net/reports/carrier?carrier=' + carrier_id + '&from=' + _this.get('from') + '&to=' + _this.get('to'));
				});
			}
		}

	});

});
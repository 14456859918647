define('dashboard/templates/modal/report', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.2",
            "loc": {
              "source": null,
              "start": {
                "line": 8,
                "column": 3
              },
              "end": {
                "line": 12,
                "column": 3
              }
            },
            "moduleName": "dashboard/templates/modal/report.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("				");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","segment");
            var el2 = dom.createTextNode("\n					");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","ui active centered large inline text loader");
            var el3 = dom.createTextNode("Loading reports");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n				");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.2",
            "loc": {
              "source": null,
              "start": {
                "line": 14,
                "column": 3
              },
              "end": {
                "line": 19,
                "column": 3
              }
            },
            "moduleName": "dashboard/templates/modal/report.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("				");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","ui center compact message danger");
            var el2 = dom.createTextNode("\n					");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            var el3 = dom.createTextNode("Could not fetch destination reports from the server");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n					");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n				");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]),0,0);
            return morphs;
          },
          statements: [
            ["inline","log",[["get","model",["loc",[null,[17,14],[17,19]]]]],[],["loc",[null,[17,8],[17,21]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@2.0.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 43,
                  "column": 6
                },
                "end": {
                  "line": 69,
                  "column": 6
                }
              },
              "moduleName": "dashboard/templates/modal/report.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("							");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              var el2 = dom.createTextNode("\n								");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n								");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n								");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n								");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n								");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n								");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n								");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("%");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n								");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n								");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n								");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n								");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n								");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n								");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createTextNode("\n									");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("b");
              var el4 = dom.createTextNode("\n										");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n									");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n								");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n								");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n								");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createTextNode("\n									");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("b");
              var el4 = dom.createTextNode("\n										");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n									");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n								");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n							");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var element2 = dom.childAt(element1, [25, 1]);
              var element3 = dom.childAt(element1, [29, 1]);
              var morphs = new Array(18);
              morphs[0] = dom.createElementMorph(element1);
              morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]),0,0);
              morphs[2] = dom.createMorphAt(dom.childAt(element1, [3]),0,0);
              morphs[3] = dom.createMorphAt(dom.childAt(element1, [5]),0,0);
              morphs[4] = dom.createMorphAt(dom.childAt(element1, [7]),0,0);
              morphs[5] = dom.createMorphAt(dom.childAt(element1, [9]),0,0);
              morphs[6] = dom.createMorphAt(dom.childAt(element1, [11]),0,0);
              morphs[7] = dom.createMorphAt(dom.childAt(element1, [13]),0,0);
              morphs[8] = dom.createMorphAt(dom.childAt(element1, [15]),0,0);
              morphs[9] = dom.createMorphAt(dom.childAt(element1, [17]),0,0);
              morphs[10] = dom.createMorphAt(dom.childAt(element1, [19]),0,0);
              morphs[11] = dom.createMorphAt(dom.childAt(element1, [21]),0,0);
              morphs[12] = dom.createMorphAt(dom.childAt(element1, [23]),0,0);
              morphs[13] = dom.createAttrMorph(element2, 'class');
              morphs[14] = dom.createMorphAt(element2,1,1);
              morphs[15] = dom.createMorphAt(dom.childAt(element1, [27]),0,0);
              morphs[16] = dom.createAttrMorph(element3, 'class');
              morphs[17] = dom.createMorphAt(element3,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["ner_details",["get","model",["loc",[null,[44,34],[44,39]]]],["get","report.operator",["loc",[null,[44,40],[44,55]]]],["get","modal",["loc",[null,[44,56],[44,61]]]]],[],["loc",[null,[44,11],[44,63]]]],
              ["content","report.operator",["loc",[null,[45,12],[45,31]]]],
              ["content","report.calls",["loc",[null,[46,12],[46,28]]]],
              ["content","report.successfullCalls",["loc",[null,[47,12],[47,39]]]],
              ["content","report.minutes",["loc",[null,[48,12],[48,30]]]],
              ["content","report.acd",["loc",[null,[49,12],[49,26]]]],
              ["content","report.asr",["loc",[null,[50,12],[50,26]]]],
              ["content","report.ner",["loc",[null,[51,12],[51,26]]]],
              ["content","report.pdd",["loc",[null,[52,12],[52,26]]]],
              ["content","report.sellingPrice",["loc",[null,[53,12],[53,35]]]],
              ["content","report.revenue",["loc",[null,[54,12],[54,30]]]],
              ["content","report.carrierCosts",["loc",[null,[55,12],[55,35]]]],
              ["inline","costpermin",[["get","report.carrierCosts",["loc",[null,[56,25],[56,44]]]],["get","report.minutes",["loc",[null,[56,45],[56,59]]]]],[],["loc",[null,[56,12],[56,61]]]],
              ["attribute","class",["concat",[["subexpr","if",[["get","report.marginLoss",["loc",[null,[58,24],[58,41]]]],"text-red","text-green"],[],["loc",[null,[58,19],[58,67]]]]]]],
              ["content","report.margin",["loc",[null,[59,10],[59,27]]]],
              ["content","report.marginMin",["loc",[null,[62,12],[62,32]]]],
              ["attribute","class",["concat",[["subexpr","if",[["get","report.marginLoss",["loc",[null,[64,24],[64,41]]]],"text-red","text-green"],[],["loc",[null,[64,19],[64,67]]]]]]],
              ["content","report.marginPercentage",["loc",[null,[65,10],[65,37]]]]
            ],
            locals: ["report"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@2.0.2",
            "loc": {
              "source": null,
              "start": {
                "line": 21,
                "column": 3
              },
              "end": {
                "line": 72,
                "column": 3
              }
            },
            "moduleName": "dashboard/templates/modal/report.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("				");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("table");
            dom.setAttribute(el1,"class","ui table celled striped red");
            var el2 = dom.createTextNode("\n					");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("thead");
            var el3 = dom.createTextNode("\n						");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("tr");
            var el4 = dom.createTextNode("\n							");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("Operator");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n							");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("Calls");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n							");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("Calls Success");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n							");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("Minutes");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n							");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("ACD");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n							");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("ASR");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n							");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("NER");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n							");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("PDD");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n							");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("Selling Price");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n							");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("Revenue");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n							");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("Costs");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n							");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("Cost/min.");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n							");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("Margin");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n							");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("Margin min.");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n							");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("Margin %");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n						");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n					");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n					");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("tbody");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("					");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n				");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","model",["loc",[null,[43,14],[43,19]]]]],[],0,null,["loc",[null,[43,6],[69,15]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child3 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@2.0.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 89,
                  "column": 6
                },
                "end": {
                  "line": 96,
                  "column": 6
                }
              },
              "moduleName": "dashboard/templates/modal/report.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("							");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              var el2 = dom.createTextNode("\n								");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n								");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n								");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n								");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("%");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n							");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]),0,0);
              morphs[2] = dom.createMorphAt(dom.childAt(element0, [5]),0,0);
              morphs[3] = dom.createMorphAt(dom.childAt(element0, [7]),0,0);
              return morphs;
            },
            statements: [
              ["content","report.sipCode",["loc",[null,[91,12],[91,30]]]],
              ["content","report.sipCodeDesc",["loc",[null,[92,12],[92,34]]]],
              ["content","report.attempts",["loc",[null,[93,12],[93,31]]]],
              ["content","report.attemptsPercent",["loc",[null,[94,12],[94,38]]]]
            ],
            locals: ["report"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@2.0.2",
            "loc": {
              "source": null,
              "start": {
                "line": 74,
                "column": 3
              },
              "end": {
                "line": 99,
                "column": 3
              }
            },
            "moduleName": "dashboard/templates/modal/report.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("				");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","ui header");
            var el2 = dom.createTextNode("\n					SIP Codes for ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n				");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n			\n				");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("table");
            dom.setAttribute(el1,"class","ui stacked celled table");
            var el2 = dom.createTextNode("\n					");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("thead");
            var el3 = dom.createTextNode("\n						");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("tr");
            var el4 = dom.createTextNode("\n							");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("SIP CODE");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n							");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("Description");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n							");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("Attempts");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n							");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("Attempts in %");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n						");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n					");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n					");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("tbody");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("					");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n				");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3, 3]),1,1);
            return morphs;
          },
          statements: [
            ["content","model.carrier",["loc",[null,[76,19],[76,36]]]],
            ["block","each",[["get","model.carrierModel",["loc",[null,[89,14],[89,32]]]]],[],0,null,["loc",[null,[89,6],[96,15]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.2",
            "loc": {
              "source": null,
              "start": {
                "line": 101,
                "column": 3
              },
              "end": {
                "line": 105,
                "column": 3
              }
            },
            "moduleName": "dashboard/templates/modal/report.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("				");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","ui active inverted dimmer");
            var el2 = dom.createTextNode("\n					");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","ui large text loader");
            var el3 = dom.createTextNode("Loading carrier details");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n				");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child5 = (function() {
        return {
          meta: {
            "revision": "Ember@2.0.2",
            "loc": {
              "source": null,
              "start": {
                "line": 107,
                "column": 3
              },
              "end": {
                "line": 111,
                "column": 3
              }
            },
            "moduleName": "dashboard/templates/modal/report.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("				");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","ui center compact message warning");
            var el2 = dom.createTextNode("\n					");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            var el3 = dom.createTextNode("Could not fetch carrier details from the server");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n				");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@2.0.2",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 122,
              "column": 0
            }
          },
          "moduleName": "dashboard/templates/modal/report.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","ui fullscreen modal long");
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2,"class","close icon");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","ui header");
          var el3 = dom.createTextNode("\n			Destination reports: ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","content");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","actions");
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","ui red basic button cancel");
          var el4 = dom.createTextNode("\n				");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("i");
          dom.setAttribute(el4,"class","remove icon");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" Close\n			");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n		\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var element5 = dom.childAt(element4, [5]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(dom.childAt(element4, [3]),1,1);
          morphs[1] = dom.createMorphAt(element5,1,1);
          morphs[2] = dom.createMorphAt(element5,3,3);
          morphs[3] = dom.createMorphAt(element5,5,5);
          morphs[4] = dom.createMorphAt(element5,7,7);
          morphs[5] = dom.createMorphAt(element5,9,9);
          morphs[6] = dom.createMorphAt(element5,11,11);
          return morphs;
        },
        statements: [
          ["content","model.destination",["loc",[null,[5,24],[5,45]]]],
          ["block","if",[["get","model.isPending",["loc",[null,[8,9],[8,24]]]]],[],0,null,["loc",[null,[8,3],[12,10]]]],
          ["block","if",[["get","model.isRejected",["loc",[null,[14,9],[14,25]]]]],[],1,null,["loc",[null,[14,3],[19,10]]]],
          ["block","if",[["get","model.isFulfilled",["loc",[null,[21,9],[21,26]]]]],[],2,null,["loc",[null,[21,3],[72,10]]]],
          ["block","if",[["get","model.carrierModel.isFulfilled",["loc",[null,[74,9],[74,39]]]]],[],3,null,["loc",[null,[74,3],[99,10]]]],
          ["block","if",[["get","model.carrierModel.isPending",["loc",[null,[101,9],[101,37]]]]],[],4,null,["loc",[null,[101,3],[105,10]]]],
          ["block","if",[["get","model.carrierModel.isRejected",["loc",[null,[107,9],[107,38]]]]],[],5,null,["loc",[null,[107,3],[111,10]]]]
        ],
        locals: ["modal"],
        templates: [child0, child1, child2, child3, child4, child5]
      };
    }());
    return {
      meta: {
        "revision": "Ember@2.0.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 123,
            "column": 0
          }
        },
        "moduleName": "dashboard/templates/modal/report.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","bs-modal",[],["show",true,"closable",false,"closed","closeModal"],0,null,["loc",[null,[1,0],[122,13]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});
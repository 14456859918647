define('dashboard/controllers/modal/invoice', ['exports', 'ember', 'dashboard/config/environment', 'dashboard/utils'], function (exports, Ember, ENV, utils) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    auth: Ember['default'].inject.service(),
    token: Ember['default'].computed.oneWay('auth.sessionToken'),
    disabled: Ember['default'].computed.not('model.hasSerialNumber'),

    generating: false,
    seed: '',

    sendInvoice: function sendInvoice(invoice) {
      var _this = this;

      Ember['default'].set(invoice, 'sending', true);

      return invoice.save().then(function (invoice) {
        var id = Ember['default'].get(invoice, 'id');
        return utils.ajax({
          url: ENV['default'].HOST + '/api/invoicing/send/' + id
        });
      }).then(function () {
        Ember['default'].set(invoice, 'status', 'SENT');
        return _this.send('notify', 'success', {
          html: '<p>Invoice has been sent to Client</p>'
        });
      }).then(function () {
        return _this.send('closeModal');
      })['catch'](function (e) {
        _this.send('error', e.message, 'Could not send Invoice');
      })['finally'](function () {
        return Ember['default'].set(invoice, 'sending', false);
      });
    },

    generate: function generate() {
      var _this2 = this;

      var seed = Ember['default'].get(this, 'seed');

      this.set('generating', true);

      utils.ajax({
        url: ENV['default'].HOST + '/api/invoices/serial-number',
        type: 'POST',
        data: {
          seed: seed
        },
        headers: {
          'Authorization': 'Bearer ' + this.get('token')
        }
      }).then(function (response) {
        var number = Ember['default'].get(response, 'content.serialNumber');
        if (Ember['default'].isBlank(number)) {
          throw new Ember['default'].Error('There was an error while generating your serial number, please try again or contact support.');
        }
        Ember['default'].set(_this2, 'model.serialNumber', number);
      })['catch'](function (error) {
        _this2.send('error', error.message, 'Could not generate a serial number');
      })['finally'](function () {
        return _this2.set('generating', false);
      });
    },

    actions: {
      generate: function generate() {
        this.generate();
      },
      send: function send() {
        this.sendInvoice(Ember['default'].get(this, 'model'));
      }
    }
  });

});
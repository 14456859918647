define('dashboard/helpers/raw-get', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Helper.helper(function (params) {
		var obj = params[0];
		var key = params[1];
		return obj[key];
	});

});
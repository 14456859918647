define('dashboard/controllers/modal/delete', ['exports', 'ember', 'ember-data'], function (exports, Ember, DS) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend({
		actions: {
			/**
	   * Confirm the Record delete action.
	   */
			confirm: function confirm() {
				var _this = this;

				var model = this.get('model');

				model.destroyRecord().then(function () {
					Ember['default'].Logger.info('Record was destroyed.');

					_this.notify.success({
						html: 'Record was deleted.'
					});

					_this.send('closeModal');
				})['catch'](function (error) {
					Ember['default'].Logger.error('An error occurred while destroying record.', error.stack);

					model.rollbackAttributes();

					_this.send('rejected', model, error);
				});
			},

			/**
	   * Handle rejected action
	   */
			rejected: function rejected(model, error) {
				var message;
				if (error instanceof DS['default'].AdapterError) {
					message = error.errors.message;
				} else {
					message = error.message;
				}

				this.notify.error({
					closeAfter: null,
					html: '<h3>Could not delete record</h3><p>' + message + '</p>'
				});
			}
		}
	});

});
define('dashboard/models/job', ['exports', 'ember', 'ember-data'], function (exports, Ember, DS) {

	'use strict';

	exports['default'] = DS['default'].Model.extend({

		name: DS['default'].attr('string', {
			validation: {
				required: true
			}
		}),

		description: DS['default'].attr('string', {
			validation: {
				required: true
			}
		}),

		condition: DS['default'].attr('string', {
			validation: {
				required: true
			}
		}),

		active: DS['default'].attr('boolean', {
			defaultValue: false,
			validation: {
				// acceptance: true
			}
		}),

		locked: DS['default'].attr('boolean', {
			defaultValue: false,
			validation: {
				// acceptance: true
			}
		}),

		interval: DS['default'].attr('duration', {
			validation: {
				required: true
			}
		}),

		lastRunAt: DS['default'].attr('date'),

		nextRunAt: DS['default'].attr('date'),

		failedAt: DS['default'].attr('date'),

		createdAt: DS['default'].attr('date'),

		updatedAt: DS['default'].attr('date'),

		template: DS['default'].belongsTo('alert-template'),

		query: DS['default'].belongsTo('query-template'),

		parameters: DS['default'].hasMany('query-parameter'),

		intervalHumanized: Ember['default'].computed('interval', function () {
			var interval = this.get('interval');
			return interval && interval.humanize();
		})

	});

});
define('dashboard/routes/carriers/create', ['exports', 'ember', 'dashboard/mixins/operator/form-route'], function (exports, Ember, OperatorFormRoute) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(OperatorFormRoute['default'], {
		model: function model() {
			return this.store.createRecord('carrier');
		}
	});

});
define('dashboard/routes/clients', ['exports', 'ember', 'dashboard/mixins/authenticated-route', 'dashboard/mixins/operator/route'], function (exports, Ember, AuthenticatedRoute, OperatorRoute) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRoute['default'], OperatorRoute['default'], {
		model: function model() {
			return this.store.findAll('client');
		},
		actions: {
			prices: function prices(client) {
				this.transitionTo('selling-prices.list', Ember['default'].get(client, 'id'));
			}
		}
	});

});
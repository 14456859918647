define('dashboard/router', ['exports', 'ember', 'dashboard/config/environment'], function (exports, Ember, config) {

  'use strict';

  var Router = Ember['default'].Router.extend({
    location: config['default'].locationType
  });

  Router.map(function () {
    this.route('clients', { resetNamespace: true }, function () {
      this.route('edit', { path: '/:id' });
      this.route('create');
    });

    this.route('carriers', { resetNamespace: true }, function () {
      this.route('edit', { path: '/:id' });
      this.route('create');
    });

    this.route('login');

    this.route('clientsgraph');

    this.route('not-found', { path: '/*path' });

    this.route('purchase-prices', { path: 'prices/purchase', resetNamespace: true }, function () {
      this.route('list', { path: '/:id' });
      this.route('import');
    });

    this.route('selling-prices', { path: 'prices/selling', resetNamespace: true }, function () {
      this.route('list', { path: '/:id' });
      this.route('import');
    });

    this.route('cbc', { path: 'prices/cbc', resetNamespace: true }, function () {
      this.route('create');
    });

    this.route('reports', function () {
      this.route('client');
      this.route('clients-overview');
      this.route('carrier-overview');
      this.route('carrier');
      this.route('cbc');
      this.route('cbc-latest');
      this.route('cbc-price-overview');
      this.route('cbc-performance');
    });

    this.route('cdr');
    this.route('trading');
    this.route('invoices', function () {});

    this.route('job', { path: 'monitoring/jobs' }, function () {
      this.route('edit', { path: '/:id' });
      this.route('create');
    });

    this.route('query-template', { path: 'monitoring/queries' }, function () {
      this.route('edit', { path: '/:id' });
      this.route('create');
    });

    this.route('alert-template', { path: 'monitoring/templates' }, function () {
      this.route('edit', { path: '/:id' });
      this.route('create');
    });

    this.route('loggers');
    this.route('status');
  });

  exports['default'] = Router;

});
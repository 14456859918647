define('dashboard/transforms/enum', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	exports['default'] = DS['default'].StringTransform.extend({
		serialize: function serialize(deserialized) {
			var serialized = this._super(deserialized);

			if (typeof serialized === 'string') {
				return serialized.toUpperCase();
			}

			return serialized;
		},

		deserialize: function deserialize(serialized) {
			var deserialized = this._super(serialized);

			if (typeof deserialized === 'string') {
				return deserialized.toUpperCase();
			}

			return deserialized;
		}
	});

});
define('dashboard/controllers/reports/cbc-performance', ['exports', 'ember', 'dashboard/mixins/reports/controller'], function (exports, Ember, ControllerMixin) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(ControllerMixin['default'], {
  	modelKey: 'cbc-performance',
  	/**
    * Date timestamp from when to fetch the records.
    * 
    * @type {Number}
    */
  	from: null,

  	/**
    * Date timestamp untill when to fetch the records.
    * 
    * @type {Number}
    */
  	to: null
  });

});
define('dashboard/routes/cbc', ['exports', 'ember', 'dashboard/utils'], function (exports, Ember, utils) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend({
		notify: Ember['default'].inject.service(),

		actions: {

			/**
	   *  publish CBC Prices to 3rd party portals.
	   * 
	   * @param  {Number} id
	   * @param  {Number} date
	   */
			publish: function publish(id, date) {
				Ember['default'].assert('Client ID must be set', Ember['default'].isPresent(id));
				Ember['default'].assert('Valid from date must be set', Ember['default'].isPresent(date));

				var location = utils.buildUrl('api', 'cbc', 'publish');
				var params = Ember['default'].$.param({
					'client.id': id, 'validFrom': date
				});

				Ember['default'].Logger.info('Exporting CBC Prices with params', params);

				window.location = location + '?' + encodeURI(params);
			},

			/**
	   * Tries to export newly created CBC Prices.
	   * 
	   * @param  {Number} id
	   * @param  {Number} date
	   */
			'export': function _export(id, date) {
				Ember['default'].assert('Client ID must be set', Ember['default'].isPresent(id));
				Ember['default'].assert('Valid from date must be set', Ember['default'].isPresent(date));

				var location = utils.buildUrl('api', 'exports', 'cbc');
				var params = Ember['default'].$.param({
					'client.id': id, 'validFrom': date
				});

				Ember['default'].Logger.info('Exporting CBC Prices with params', params);

				window.location = location + '?' + encodeURI(params);
			},

			/**
	   * Renders an error notification message to the Client
	   * 
	   * @param  {String} headline
	   * @param  {Error} error
	   */
			warning: function warning(headline, error) {
				if (arguments.length < 2) {
					error = headline;
					headline = 'Price import error';
				}

				Ember['default'].Logger.warn('Rendering Error notification for Error', error);

				this.notify.warning({
					closeAfter: null,
					html: '<h3>' + headline + '</h3><p>' + error.message + '</p>'
				});
			}
		}

	});

});
define('dashboard/controllers/reports/clients-overview', ['exports', 'ember', 'dashboard/utils', 'dashboard/mixins/reports/controller'], function (exports, Ember, utils, ControllerMixin) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend(ControllerMixin['default'], {
		modelKey: 'clients-overview-report',
		auth: Ember['default'].inject.service(),

		actions: {
			newWindow: function newWindow(clientKey) {
				var _this = this;

				var token = this.get('auth.sessionToken');
				var url = utils.buildUrl('api', 'clients', 'key', clientKey);

				return utils.ajax({
					type: 'GET',
					url: url,
					beforeSend: function beforeSend(request) {
						request.setRequestHeader("Authorization", 'Bearer ' + token);
					}
				}).then(function (response) {
					var client_id = Ember['default'].get(response, 'id');
					window.open('https://voicetool.msc-services.net/reports/client?client=' + client_id + '&from=' + _this.get('from') + '&to=' + _this.get('to'));
				});
			}
		}

	});

});
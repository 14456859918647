define('dashboard/routes/login', ['exports', 'ember', 'dashboard/auth/credentials'], function (exports, Ember, Credentials) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend({
		auth: Ember['default'].inject.service(),
		model: function model() {
			return Credentials['default'].create();
		},

		actions: {
			login: function login(credentials) {
				var route = this,
				    auth = this.get('auth');

				return auth.login(credentials).then(function (credentials) {
					var transition = auth.get('attemptedTransition');

					if (transition) {
						transition.retry();
					} else {
						if (credentials.username === "belco") {
							route.transitionTo('/reports/cbc-latest');
						} else if (credentials.username === "turkcell") {
							route.transitionTo('/reports/client');
						} else {
							route.transitionTo('index');
						}
					}

					auth.set('attemptedTransition', null);
				}, function (e) {
					Ember['default'].Logger.warn('Error while logging in', e);
				});
			}
		}
	});

});
define('dashboard/controllers/job/form', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend({

		queries: Ember['default'].computed(function () {
			return this.store.findAll('query-template');
		}),

		alerts: Ember['default'].computed(function () {
			return this.store.findAll('alert-template');
		}),

		/**
	  * Title of the Form modal
	  */
		title: Ember['default'].computed('model.isNew', {
			get: function get() {
				var model = this.get('model'),
				    isNew = this.get('model.isNew');

				if (isNew) {
					return 'Create new Scheduled Job';
				} else {
					return 'Edit Scheduled Job: <small>' + model.get('name') + '</small>';
				}
			}
		}),

		// activeLabel: Ember.computed('model.active', function() {
		// 	return this.get('model.active') ? 'Deactivate' : 'Activate';
		// }),

		// lockedLabel: Ember.computed('model.locked', function() {
		// 	return this.get('model.locked') ? 'Unlock' : 'Lock';
		// }),

		actions: {
			duration: function duration(_duration) {
				this.set('model.interval', _duration);
			},

			alert: function alert(_alert) {
				this.set('model.template', _alert);
			},

			query: function query(_query) {
				this.set('model.query', _query);
			}
		}
	});

});
define('dashboard/tests/unit/mixins/operator-model-test', ['ember', 'dashboard/mixins/operator-model', 'qunit'], function (Ember, OperatorModelMixin, qunit) {

  'use strict';

  qunit.module('Unit | Mixin | operator model');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var OperatorModelObject = Ember['default'].Object.extend(OperatorModelMixin['default']);
    var subject = OperatorModelObject.create();
    assert.ok(subject);
  });

});
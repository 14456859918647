define('dashboard/routes/status', ['exports', 'ember', 'dashboard/mixins/authenticated-route', 'dashboard/utils'], function (exports, Ember, AuthenticatedRoute, utils) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRoute['default'], {
		auth: Ember['default'].inject.service(),

		queryParams: {
			tab: {
				refreshModel: true,
				replace: true
			}
		},

		model: function model(params) {
			var token = this.get('auth.sessionToken');

			return utils.ajax({
				url: 'api/actuator/' + params.tab,
				beforeSend: function beforeSend(request) {
					request.setRequestHeader("Authorization", 'Bearer ' + token);
				}
			});
		}
	});

});
define('dashboard/routes/job', ['exports', 'ember', 'dashboard/mixins/authenticated-route', 'dashboard/mixins/form-route'], function (exports, Ember, AuthenticatedRoute, FormRoute) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRoute['default'], FormRoute['default'], {

		model: function model() {
			return this.store.findAll('job');
		}

	});

});
define('dashboard/utils', ['exports', 'ember', 'ember-data', 'dashboard/config/environment'], function (exports, Ember, DS, config) {

	'use strict';

	exports.uuid = uuid;
	exports.parseJsonError = parseJsonError;
	exports.parseJsonResponse = parseJsonResponse;
	exports.buildUrl = buildUrl;
	exports.isAbsoluteUrl = isAbsoluteUrl;
	exports.ajax = ajax;

	function uuid() {
		function p8(s) {
			var p = (Math.random().toString(16) + "000000000").substr(2, 8);
			return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
		}
		return p8() + p8(true) + p8(true) + p8();
	}

	/**
	 * Parse JSON Error response
	 * @param  {[type]} jqXHR [description]
	 * @return {[type]}       [description]
	 */

	function parseJsonError(jqXHR) {
		var json;

		try {
			json = jqXHR.responseJSON || Ember['default'].$.parseJSON(jqXHR.responseText);
		} catch (e) {
			Ember['default'].Logger.warn('Could not parse JSON response from server', e.stack);

			return e;
		}

		if (jqXHR && jqXHR.status === 422) {
			return new DS['default'].InvalidError(json.errors, json.message);
		} else {
			return json;
		}
	}

	/**
	 * Parses an AJAX JSON response into a Javascript Object
	 *
	 * @param  {XHRequest} jqXHR
	 * @return {Object}
	 */

	function parseJsonResponse(jqXHR) {
		try {
			return jqXHR.responseJSON || Ember['default'].$.parseJSON(jqXHR.responseText);
		} catch (e) {
			Ember['default'].Logger.warn('Could not parse JSON response from server', e.stack);
			return e;
		}
	}

	/**
	 * Constructs a URL from segments
	 * 
	 * @return {String}
	 */

	function buildUrl() {
		for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
			args[_key] = arguments[_key];
		}

		return args.reduce(function (url, part) {
			if (Ember['default'].isPresent(part)) {
				url += '/' + part;
			}

			return url;
		}, config['default'].HOST);
	}

	/**
	 * Check if the String is an Absolute URL.
	 *
	 * @param  {String}  url
	 * @return {Boolean}
	 */

	function isAbsoluteUrl(url) {
		var html = /^https?:\/\//i,
		    stream = /^rtsp?:\/\//i;

		return html.test(url) || stream.test(url);
	}

	/**
	 * Serialize payload for AJAX request.
	 *
	 * @param  {*} payload
	 * @return {String}
	 */

	function serializePayload(payload) {
		if (payload instanceof File) {
			return payload;
		}

		if (payload instanceof FormData) {
			return payload;
		}

		if (typeof payload === 'object') {
			return JSON.stringify(payload);
		}

		return payload;
	}

	/**
	 * Executes an AJAX requests which is wrapped inside a promise
	 *
	 * @method ajax
	 * @param  {Object|String} options
	 * @return {Ember.RSVP.Promise}
	 */

	function ajax(options) {
		if (Ember['default'].typeOf(options) === 'string') {
			options = {
				url: options

			};
		}

		var opts = Ember['default'].merge({
			type: 'GET',
			dataType: 'json'
		}, options);

		var isPost = opts.type.toLowerCase() === 'post';

		if (isPost && Ember['default'].isNone(opts.contentType)) {
			opts.contentType = 'application/json; charset=utf-8';
		}

		if (isPost && typeof opts.data === 'object') {
			opts.data = serializePayload(opts.data);
		}

		Ember['default'].assert('You must define a URL property for an AJAX request', !!opts.url);

		if (!isAbsoluteUrl(opts.url)) {
			opts.url = config['default'].HOST + '/' + opts.url;
		}

		return new Ember['default'].RSVP.Promise(function (resolve, reject) {
			Ember['default'].$.ajax(opts).then(resolve, function (jqXHR) {
				reject(parseJsonError(jqXHR));
			});
		});
	}

});
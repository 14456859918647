define('dashboard/components/select-clients', ['exports', 'ember', 'ember-cli-semantic-ui/mixins/semantic-settings', 'dashboard/mixins/operator/selectable', 'dashboard/mixins/drop-down'], function (exports, Ember, SemanticSettings, SelectableOperatorMixin, DropdownMixin) {

	'use strict';

	exports['default'] = Ember['default'].Component.extend(SemanticSettings['default'], DropdownMixin['default'], SelectableOperatorMixin['default'], {
		placeholder: 'Select Clients',
		_operatorType: 'client',
		operator: Ember['default'].computed.alias('client'),

		cbc: false,

		init: function init() {
			this._super.apply(this, arguments);

			var cbc = Ember['default'].get(this, 'cbc');
			var query = Ember['default'].getWithDefault(this, 'query', {});

			if (cbc === true) {
				this.query = Ember['default'].merge({ cbc: cbc }, query);
			}
		}
	});

});
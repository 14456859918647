define('dashboard/controllers/modal/invoice-number-generate', ['exports', 'ember', 'dashboard/config/environment', 'dashboard/utils'], function (exports, Ember, ENV, utils) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    auth: Ember['default'].inject.service(),
    token: Ember['default'].computed.oneWay('auth.sessionToken'),
    disabled: Ember['default'].computed.not('model.comments'),

    generating: false,

    generateInvoiceNumber: function generateInvoiceNumber(model) {
      var _this = this;

      var id = Ember['default'].get(model, 'nextInvoiceId');
      var comments = Ember['default'].get(model, 'comments');
      return utils.ajax({
        url: ENV['default'].HOST + '/api/invoices/create-manual/' + id + '/' + comments,
        headers: {
          'Authorization': 'Bearer ' + this.get('token')
        }
      }).then(function () {
        return _this.send('notify', 'success', {
          html: '<p>Manuael invoice has been created successfuly</p>'
        });
      }).then(function () {
        return _this.send('closeModal');
      })['catch'](function (e) {
        _this.send('error', e.message, 'Could not create manuel invoice');
      })['finally'](function () {
        return Ember['default'].set(model, 'sending', false);
      });
    },

    actions: {
      generateInvoiceNumber: function generateInvoiceNumber(model) {
        this.generateInvoiceNumber(model);
      }

    }
  });

});
define('dashboard/models/cbc-performance', ['exports', 'ember', 'ember-data'], function (exports, Ember, DS) {

  'use strict';


  exports['default'] = DS['default'].Model.extend({

  	cbcNumber: DS['default'].attr('string'),

  	/**
    * Reports destination.
    * 
    * @type {String}
    */
  	destination: DS['default'].attr('string'),

  	/**
    * Total number of calls
    * 
    * @type {Number}
    */
  	calls: DS['default'].attr('number'),

  	/**
    * Total number of successfull calls
    * 
    * @type {Number}
    */
  	successfullCalls: DS['default'].attr('number'),

  	/**
    * Total number of minutes of all calls
    * 
    * @type {Number}
    */
  	minutes60: DS['default'].attr('number'),

  	/**
    * Total number of minutes of all calls
    * 
    * @type {Number}
    */
  	minutes: DS['default'].attr('number'),

  	/**
    * Revenue made for this Destination
    * 
    * @type {Number}
    */
  	revenue: DS['default'].attr('number'),

  	/**
    * Termination costs for this Destination
    * 
    * @type {Number}
    */
  	terminationCosts: DS['default'].attr('number'),

  	/**
    * @type {Number}
    */
  	db1: DS['default'].attr('number'),

  	/**
    * @type {Number}
    */
  	zk: DS['default'].attr('string'),

  	/**
    * @type {Number}
    */
  	fi: DS['default'].attr('number'),

  	/**
    * Is there a margin loss for this destination.
    * 
    * @type {Boolean}
    */
  	marginLoss: Ember['default'].computed.lt('margin', 0.0)
  });

});
define('dashboard/controllers/status', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend({
		tab: 'health',
		tabs: [{
			label: 'Health',
			icon: 'heartbeat',
			value: 'health'
		}, {
			label: 'Info',
			icon: 'copyright',
			value: 'info'
		}, {
			label: 'Metrics',
			icon: 'dashboard',
			value: 'metrics'
		}, {
			label: 'Threads',
			icon: 'settings',
			value: 'dump'
		}]
	});

});
define('dashboard/components/invoice-cycle-field', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    classNames: ['field'],
    classNameBindings: ['errors.length:error'],

    cycles: [{
      value: 'WEEKLY',
      label: 'Weekly - Every week on Monday'
    }, {
      value: 'BIWEEKLY',
      label: 'BiWeekly - Every 1st and 16th in the month'
    }, {
      value: 'MONTHLY',
      label: 'Monthly - Every 1st in the month'
    }]
  });

});
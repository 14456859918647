define('dashboard/controllers/job/index', ['exports', 'ember', 'dashboard/utils'], function (exports, Ember, utils) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend({
		auth: Ember['default'].inject.service(),

		/**
	  * Session token extracted from the current Session
	  * 
	  * @type {String}
	  */
		token: Ember['default'].computed.oneWay('auth.sessionToken'),

		actions: {
			update: function update(job) {
				this.send('save', job);
			},

			run: function run(job) {
				var _this = this;

				var id = Ember['default'].get(job, 'id');
				var name = Ember['default'].get(job, 'name');

				return utils.ajax({
					url: 'api/monitoring/jobs/run/' + id,
					headers: {
						'Authorization': 'Bearer ' + this.get('token')
					}
				}).then(function (result) {
					if (!result.successfull && result.cause) {
						throw new Error(result.cause.message);
					}

					_this.notify.success({
						html: '<p>Job <b>' + name + '</b> was successfuly executed.</p>'
					});
				})['catch'](function (error) {
					_this.notify.error({
						html: '<p>Job <b>' + name + '</b> could not be executed.<br/>' + error.message + '</p>'
					});
				});
			}
		}
	});

});
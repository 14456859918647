define('dashboard/models/logger', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	exports['default'] = DS['default'].Model.extend({
		name: DS['default'].attr('string'),
		level: DS['default'].attr('string'),

		levelDidChange: (function () {
			return this.save();
		}).observes('level')
	});

});
define('dashboard/mixins/price/import', ['exports', 'ember', 'ember-cli-data-validation/error'], function (exports, Ember, ValidationError) {

	'use strict';

	exports['default'] = Ember['default'].Mixin.create({
		'import': Ember['default'].inject.service(),

		/**
	  * Override this method in the Route to handle
	  * the price list file import
	  */
		importList: function importList() {
			throw new Ember['default'].Error('Yuo must implement `importList` mehtod');
		},

		/**
	  * Set the controller name
	  */
		controllerName: 'prices/import',

		actions: {

			/**
	   * Action triggered by the File input field.
	   *
	   * @param  {Uploadable} uploadable
	   */
			file: function file(uploadable) {
				this.set('model.uploadable', uploadable);
			},

			/**
	   * Reset the controller properties
	   */
			willTransition: function willTransition() {
				this._super.apply(this, arguments);

				this.set('controller.uploadable', null);
				this.set('controller.operator', null);
			},

			'import': function _import(list) {
				var _this = this;

				var importService = this.get('import');

				this.importList(importService, list).then(function (prices) {
					_this.send('importSuccess', prices);
				}, function (error) {
					_this.send('importError', error);
				});
			},

			/**
	   * Handle import success
	   */
			importSuccess: function importSuccess(response) {
				var count = Ember['default'].get(response, 'count');
				var name = Ember['default'].get(response, 'operator.name');

				this.notify.success({
					closeAfter: null,
					html: '<h3>Import Success</h3><p>' + 'Successfully imported <b>' + count + '</b> ' + 'Price records for Operator <b>' + name + '</b></p>'
				});

				// Close the Import Modal
				this.send('closeModal');
			},

			/**
	   * Handle import errors
	   */
			importError: function importError(error) {
				if (error instanceof ValidationError['default']) {
					this.notify.warning({
						html: '<h3>Validation Errors</h3><p>' + error.message + '</p>'
					});
				} else {
					this.notify.error({
						closeAfter: null,
						html: '<h3>Import Error</h3><p>' + error.message + '</p>'
					});
				}
			}
		}
	});

});
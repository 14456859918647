define('dashboard/tests/helpers/currency-icon.jshint', function () {

  'use strict';

  QUnit.module('JSHint - helpers/currency-icon.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(true, 'helpers/currency-icon.js should pass jshint.');
  });

});
define('dashboard/controllers/operators/list', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend({
		name: null,
		keys: [],

		availableKeys: Ember['default'].computed('model.[]', {
			get: function get() {
				return this.get('model').mapBy('key');
			}
		}),

		operators: Ember['default'].computed('model.[]', 'name', 'keys.[]', {
			get: function get() {
				var name = this.get('name');
				var keys = this.get('keys');
				var operators = this.get('model');

				if (Ember['default'].isEmpty(name) && Ember['default'].isEmpty(keys)) {
					return operators;
				}

				return operators.filter(function (operator) {
					var key = operator.get('key');
					var filter = true;

					if (!Ember['default'].isEmpty(keys)) {
						filter = filter && keys.indexOf(key) > -1;
					}

					if (Ember['default'].isPresent(name)) {
						var operatorName = operator.get('name');
						filter = filter && operatorName && operatorName.indexOf(name) > -1;
					}

					return filter;
				});
			}
		})
	});

});
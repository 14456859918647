define('dashboard/mixins/reports/route', ['exports', 'ember', 'ember-data', 'dashboard/config/environment', 'dashboard/utils'], function (exports, Ember, DS, ENV, utils) {

	'use strict';

	function formatUrl(path, query) {
		var params = Ember['default'].$.param(query);
		return ENV['default'].HOST + '/api/' + path + '?' + params;
	}

	function fetch(url, token) {
		var promise = utils.ajax({
			url: url,
			headers: {
				'Authorization': 'Bearer ' + token
			}
		});

		return DS['default'].PromiseArray.create({
			promise: promise
		});
	}

	/**
	 * Mixins used on the main Resource Route for
	 * `Client` and `Customer` resources.
	 *
	 * @mixin
	 * @class OperatorRouteMixin
	 */
	exports['default'] = Ember['default'].Mixin.create({
		auth: Ember['default'].inject.service(),

		/**
	  * Report Model key type for the store
	  * 
	  * @type {String}
	  */
		modelKey: null,

		/**
	  * Session token extracted from the current Session
	  * 
	  * @type {String}
	  */
		token: Ember['default'].computed.oneWay('auth.sessionToken'),

		queryParams: {
			carrier: {
				refreshModel: false,
				replace: false
			},
			client: {
				refreshModel: false,
				replace: false
			},
			from: {
				refreshModel: false,
				replace: false
			},
			to: {
				refreshModel: false,
				replace: false
			}
		},

		model: function model(params) {
			var modelKey = this.get('modelKey');

			Ember['default'].assert('You must set the `modelKey` property on this route.', Ember['default'].typeOf(modelKey) === 'string');

			if (this.shouldFetch(params)) {
				this.lastQuery = params;
				return this.store.query(modelKey, params);
			} else {
				return Ember['default'].A();
			}
		},

		/**
	  * Should the Route fetch the records from the 
	  * backend server.
	  * 
	  * @param  {Object} params
	  * @return {Boolean}
	  */
		shouldFetch: function shouldFetch() /* params */{
			return true;
		},

		actions: {
			search: function search() {
				this.refresh();
			},

			/**
	   * Fetch detailed report for this destination.
	   *  
	   * @param  {String} destination
	   */
			details: function details(destination) {
				Ember['default'].Logger.info('Fetching detailed Reports for ' + destination);

				var modelKey = this.get('modelKey').replace('-report', '');
				var url = formatUrl('reports/' + modelKey + '/destination', Ember['default'].merge({
					destination: destination
				}, this.lastQuery));

				var model = fetch(url, this.get('token'));
				Ember['default'].set(model, 'destination', destination);

				this.send('openModal', 'modal/report', {
					model: model
				});
			},

			ner_details: function ner_details(model, carrier, modal) {
				Ember['default'].Logger.info('Fetching detailed SIP codes for ' + carrier);

				this.lastQuery = Ember['default'].merge(this.lastQuery || {}, {
					carrierOut: carrier,
					destination: Ember['default'].get(model, 'destination')
				});

				var url = formatUrl('reports/client/ner_stats', Ember['default'].merge({
					carrier: carrier
				}, this.lastQuery));

				var carrierModel = fetch(url, this.get('token'));

				Ember['default'].setProperties(model, {
					carrier: carrier,
					carrierModel: carrierModel
				});

				carrierModel['finally'](function () {
					return Ember['default'].run.scheduleOnce('afterRender', modal, 'modal', 'refresh');
				});
			},

			/**
	   * Export the Reports for the current query.
	   */
			'export': function _export() {
				if (Ember['default'].isEmpty(this.lastQuery)) {
					return;
				}

				var modelKey = this.get('modelKey');

				Ember['default'].assert('You must set the `modelKey` property on this route.', Ember['default'].typeOf(modelKey) === 'string');

				var location = formatUrl('exports/reports/' + modelKey, this.lastQuery);

				Ember['default'].Logger.info('Export Reports for ' + modelKey + ' with query', this.lastQuery);

				window.location = location;
			}
		}
	});

});
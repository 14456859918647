define('dashboard/components/select-destinations', ['exports', 'ember', 'ember-data', 'dashboard/mixins/drop-down', 'ember-cli-semantic-ui/mixins/semantic-settings', 'dashboard/utils'], function (exports, Ember, DS, DropdownMixin, SemanticSettings, utils) {

	'use strict';

	exports['default'] = Ember['default'].Component.extend(SemanticSettings['default'], DropdownMixin['default'], {
		auth: Ember['default'].inject.service(),
		notify: Ember['default'].inject.service(),

		placeholder: 'Select destination',
		type: null,
		multiple: false,

		url: Ember['default'].computed('type', 'operator.id', {
			get: function get() {
				var type = this.get('type');
				var operator = this.get('operator.id');

				if (type === 'parent') {
					return utils.buildUrl('api', 'trading', 'destinations');
				} else {
					return utils.buildUrl('api', type, 'destinations', operator);
				}
			}
		}),

		destinations: Ember['default'].computed('url', {
			get: function get() {
				var _this = this;

				var token = this.get('auth.sessionToken');
				var url = this.get('url');

				var promise = utils.ajax({
					url: url,
					beforeSend: function beforeSend(request) {
						request.setRequestHeader("Authorization", 'Bearer ' + token);
					}
				})['catch'](function (error) {
					Ember['default'].run(_this, '_handleError', error);
					return [];
				});

				return DS['default'].PromiseArray.create({
					promise: promise
				});
			}
		}),

		didInsertElement: function didInsertElement() {
			var _this2 = this;

			this.get('destinations').then(function () {
				Ember['default'].run.scheduleOnce('afterRender', _this2, 'setupDropdown');
			});
		},

		destinationsDidChange: Ember['default'].observer('destinations.[]', function () {
			var _this3 = this;

			this.get('destinations').then(function () {
				Ember['default'].run.scheduleOnce('afterRender', _this3, 'setupDropdown');
			});
		}),

		_handleError: function _handleError(error) {
			var notify = this.get('notify');

			notify.error({
				closeAfter: null,
				html: '<h3>Could not fetch Destinations</h3><p>' + error.message + '</p>'
			});

			this.sendAction('error', error);
		},

		actions: {
			clear: function clear() {
				this.$().dropdown('clear');
			}
		}
	});

});
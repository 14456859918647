define('dashboard/adapters/application', ['exports', 'ember', 'ember-data', 'dashboard/config/environment', 'dashboard/auth/error'], function (exports, Ember, DS, ENV, error) {

	'use strict';

	exports['default'] = DS['default'].RESTAdapter.extend({
		auth: Ember['default'].inject.service(),
		host: ENV['default'].HOST,
		namespace: 'api',

		token: Ember['default'].computed.oneWay('auth.sessionToken'),

		// Add the Authorization Token to each request
		headers: Ember['default'].computed('token', {
			get: function get() {
				return {
					'Authorization': 'Bearer ' + this.get('token')
				};
			}
		}),

		ajax: function ajax() {
			return this._super.apply(this, arguments).then(function (done) {
				return new Ember['default'].RSVP.Promise(function (resolve) {
					setTimeout(function () {
						return resolve(done);
					}, 600);
				});
			});
		},

		handleResponse: function handleResponse(status, headers, payload) {
			if (status === 401) {
				return new error.UnauthorizedError(payload.message, status);
			}

			if (status === 403) {
				return new error.AccessDeniedError(payload.message, status);
			}

			if (this.isSuccess(status, headers, payload)) {
				return payload;
			} else if (this.isInvalid(status, headers, payload)) {
				return new DS['default'].InvalidError([payload.errors]);
			}

			return this._super.apply(this, arguments);
		},

		/**
	  * Override the defaults, return the payload as it is.
	  */
		normalizeErrorResponse: function normalizeErrorResponse(status, headers, payload) {
			return payload;
		}
	});

});
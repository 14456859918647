define('dashboard/components/select-box', ['exports', 'ember', 'dashboard/mixins/drop-down', 'ember-cli-semantic-ui/mixins/semantic-settings'], function (exports, Ember, DropdownMixin, SemanticSettings) {

	'use strict';

	exports['default'] = Ember['default'].Component.extend(SemanticSettings['default'], DropdownMixin['default'], {
		didInsertElement: function didInsertElement() {
			var _this = this;

			var values = this.get('values');

			if (typeof values.then === 'function') {
				values.then(function () {
					Ember['default'].run.scheduleOnce('afterRender', _this, 'setupDropdown');
				});
			} else {
				Ember['default'].run.scheduleOnce('afterRender', this, 'setupDropdown');
			}
			this._super();
		}
	});

});
define('dashboard/routes/cbc/index', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend({
		queryParams: {

			date: {
				refreshModel: false
			},

			operator: {
				refreshModel: false
			}

		},

		model: function model(params) {
			var date = Ember['default'].get(params, 'date');
			var client = Ember['default'].get(params, 'operator');

			if (Ember['default'].isPresent(date) && Ember['default'].isPresent(client)) {
				return this.store.query('cbc-price', {
					'validFrom': date,
					'client.id': client
				});
			}
		},

		actions: {

			select: function select() {
				this.refresh();
			}

		}

	});

});
define('dashboard/routes/clientsgraph', ['exports', 'ember', 'ember-data', 'dashboard/mixins/authenticated-route', 'dashboard/utils'], function (exports, Ember, DS, AuthenticatedRoute, utils) {

	'use strict';

	function find(params, token, path) {
		var promise = utils.ajax({
			url: 'api/cdrs/graph/' + path,
			headers: {
				'Authorization': 'Bearer ' + token
			},
			data: params
		});

		return DS['default'].PromiseArray.create({
			promise: promise
		});
	}

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRoute['default'], {

		/**
	  * Authenitcation Service
	  *
	  * @type {AuthService}
	  */
		auth: Ember['default'].inject.service(),

		model: function model(params) {
			var token = this.get('auth.sessionToken');

			return Ember['default'].Object.create({
				client: find(params, token, 'clients')
			});
		}
	});

});
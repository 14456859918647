define('dashboard/controllers/operators/error', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend({
		/**
	  * Error message
	  */
		message: Ember['default'].computed.alias('model.error')
	});

});
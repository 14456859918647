define('dashboard/routes/reports/cbc-performance', ['exports', 'ember', 'ember-data', 'dashboard/mixins/reports/route', 'dashboard/utils'], function (exports, Ember, DS, ReportsRoute, utils) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(ReportsRoute['default'], {
		auth: Ember['default'].inject.service(),

		modelKey: 'cbc-performance',

		model: function model(params) {
			var modelKey = this.get('modelKey');

			Ember['default'].assert('You must set the `modelKey` property on this route.', Ember['default'].typeOf(modelKey) === 'string');

			if (Ember['default'].isPresent(params.to) && Ember['default'].isPresent(params.from)) {
				this.lastQuery = params;
				return this.fetchCbcPerformanceReport(params.from, params.to);
			} else {
				return Ember['default'].A();
			}
			//return this.fetchCbcPerformanceReport(params.from, params.to);
		},

		fetchCbcPerformanceReport: function fetchCbcPerformanceReport(from, to) {
			var token = this.get('auth.sessionToken');
			var url = utils.buildUrl('api', 'reports', 'cbc-performance');

			var promise = utils.ajax({
				url: url,
				type: "get",
				data: { "from": from, "to": to },
				beforeSend: function beforeSend(request) {
					request.setRequestHeader("Authorization", 'Bearer ' + token);
				}
			});

			return DS['default'].PromiseArray.create({
				promise: promise
			});
		}
	});

});
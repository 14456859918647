define('dashboard/routes/query-template/edit', ['exports', 'ember', 'dashboard/mixins/templates/form-route'], function (exports, Ember, TemplateFormRoute) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(TemplateFormRoute['default'], {
		model: function model(params) {
			return this.store.findRecord('query-template', params.id);
		}
	});

});
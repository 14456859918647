define('dashboard/components/clients-chart', ['exports', 'ember', 'dashboard/components/line-chart'], function (exports, Ember, Chart) {

	'use strict';

	function fillArray(length) {
		var array = new Array(length);

		for (var i = length - 1; i >= 0; i--) {
			array[i] = 0;
		}

		return array;
	}

	exports['default'] = Chart['default'].extend({

		/**
	  * Dashboard Caption label
	  *
	  * @type {String}
	  */
		caption: 'Client calls',

		/**
	  * Label used to define the Series Values in the
	  * dashboard.
	  *
	  * @type {String}
	  */
		seriesLabel: 'Number of calls',

		/**
	  * Define the Type of the ChartComponent
	  *
	  * @type {String}
	  */
		type: 'line',

		/**
	  * Format the Data for the Chart Component
	  *
	  * @param  {Array} data
	  * @return {Object}
	  */
		formatData: function formatData(data) {
			var _this = this;

			var keys = data.reduce(function (dates, value) {
				var formated = _this.formatDateEntry(value.date);
				var date = dates[formated];

				if (typeof date === 'undefined') {
					date = dates[formated] = [];
				}

				date.push(value);

				return dates;
			}, {});

			var dates = Object.keys(keys);
			var length = dates.length;

			var values = dates.reduce(function (totals, date, i) {
				var values = keys[date];

				values.forEach(function (value) {
					var total = totals[value.client];

					if (typeof total === 'undefined') {
						total = totals[value.client] = fillArray(length);
					}

					total[i] = value.total;
				});

				return totals;
			}, {});

			return {
				dates: dates, values: values
			};
		},

		renderChartData: function renderChartData(chart, dates, values) {
			var _this2 = this;

			chart.setLabels(dates);

			Object.keys(values).forEach(function (client) {
				chart.addSeries(Ember['default'].String.underscore(client), client, values[client], {
					seriesDisplayType: _this2.get('type')
				});
			});

			if (chart.isLocked()) {
				chart.unlock();
			}
		}
	});

});
define('dashboard/mixins/operator/route', ['exports', 'ember', 'ember-cli-data-validation/error'], function (exports, Ember, ValidationError) {

	'use strict';

	exports['default'] = Ember['default'].Mixin.create({

		/**
	  * Set the controller name
	  */
		controllerName: 'operators/list',

		/**
	  * Hook invoked when the Model is saved.
	  * It would redirect to `routeName` property of Route using this Mixin.
	  */
		recordDidSave: function recordDidSave(model, created) {
			var name = this.get('routeName');
			var className = Ember['default'].get(model, 'className');

			var message = undefined;

			if (created) {
				message = 'New ' + className + ' was created.';
			} else {
				message = className + ' was updated';
			}

			this.notify.success({
				html: '<p>' + message + '</p>'
			});

			this.transitionTo(name);
		},

		/**
	  * Hook invoked when the Model could not be saved.
	  */
		recordDidError: function recordDidError(error /*, model */) {
			Ember['default'].Logger.warn('Could not save Record.', error.stack);

			if (error instanceof ValidationError['default']) {
				this.notify.warning({
					html: '<h3>Validation Errors</h3><p>' + error.message + '</p>'
				});
			} else {
				this.notify.error({
					closeAfter: null,
					html: '<h3>Could not save record</h3><p>' + error.message + '</p>'
				});
			}
		},

		actions: {

			/**
	   * Action triggered by the Operator Form template.
	   *
	   * Tries to save record.
	   * If the record has been successfully saved, `recordDidSave` hook is invoked.
	   * In case the promise is rejected, an `error` action is triggered.
	   *
	   * @param  {[type]} model [description]
	   * @return {[type]}       [description]
	   */
			save: function save(model) {
				var _this = this;

				var isNew = Ember['default'].get(model, 'isNew');

				model.save().then(function () {
					return Ember['default'].run(_this, 'recordDidSave', model, isNew);
				})['catch'](function (error) {
					return Ember['default'].run(_this, 'recordDidError', error, model);
				});
			},

			/**
	   * Starts a transition to an `edit` route.
	   */
			edit: function edit(model) {
				var name = this.get('routeName');
				this.transitionTo(name + '.edit', model);
			},

			/**
	   * Opens the Delete modal to confirm the delete action.
	   */
			'delete': function _delete(model) {
				this.send('openModal', 'modal.delete', {
					model: model,
					controller: 'modal.delete'
				});
			},

			/**
	   * Action triggered by the operator form modal, when the Modal
	   * window is closed.
	   *
	   * Tedirects back to the top level resource.
	   */
			closedModal: function closedModal() {
				var name = this.get('routeName');
				this.transitionTo(name);
			}
		}
	});

});
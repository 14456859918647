define('dashboard/initializers/data', ['exports', 'ember', 'ember-data', 'ember-cli-data-validation/mixins/validator'], function (exports, Ember, DS, Validator) {

	'use strict';

	exports.initialize = initialize;

	function initialize() /* container, application */{
		DS['default'].Model.reopen(Validator['default'], {

			/**
	   * Human readable Model name.
	   *
	   * @property className
	   * @type String
	   * @readOnly
	   */
			className: Ember['default'].computed({
				get: function get() {
					return Ember['default'].get(this.constructor, 'className');
				}
			})
		});

		DS['default'].Model.reopenClass({
			/**
	   * Human readable Model name.
	   *
	   * @property className
	   * @type String
	   * @readOnly
	   * @static
	   */
			className: Ember['default'].computed({
				get: function get() {
					var type = this.modelName;

					if (Ember['default'].isPresent(type)) {
						return Ember['default'].String.capitalize(type).replace('-', ' ');
					}
				}
			})
		});
	}

	exports['default'] = {
		name: 'data',
		initialize: initialize
	};

});